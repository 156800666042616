// @ts-nocheck
import type { SearchClient } from 'algoliasearch'

interface SearchProviderArgs {
  indexName?: string
  queryID?: string
  indexes?: SearchProviderArgs[]
  key?: string
  id?: string
  sorts?: {
    indexName: string
    label: string
  }[]
  params?: Record<string, any>
}

export default class SearchProvider {
  constructor(client: SearchClient, args: SearchProviderArgs) {
    const { indexName, queryID, indexes, key, id, sorts, params = {} } = args

    const {
      attributes = [],
      facets = [],
      filters = {},
      location = null,
      page = 1,
      query = '',
      radius = null,
      refinements = {},
      resultsPerPage = 30,
      // Everything else that isn't known is interpreted as a passthrough for algolia params that we don't really need to make an equivalent for.
      ...custom
    } = params

    // The client that we use to search with.
    this.client = client

    // If indexes exists then we are handling multiple indexes from a single main instance of the SearchProvider
    // It creates its own internal SearchProvider instances for each index passed.
    // The idea is that you want all the same actions to happen to all the instances
    // EX: search each index for the query, 'tonneau'. would pass that 'tonneau' value down to each instance.
    if (indexes) {
      this.indexes = indexes.map((index) => new SearchProvider(client, { ...index, id }))
      return
    }

    let stateKey = id

    if (key) {
      this.key = key
      stateKey += `-${key}`
    }

    // Reactive state for the SearchProvider
    // This is the default state but if we pass a state into the SearchProvider it will completely overwrite this obj
    // because of the spread '...state' at the bottom of this obj.
    this.state = useState(stateKey, () => ({
      indexName,
      queryID,
      sorts,
      currentSort: sorts ? 0 : null,
      searchParameters: {
        attributes,
        facets,
        filters,
        location,
        page,
        query,
        radius,
        refinements,
        resultsPerPage,
        custom,
      },
      results: [],
      resultCount: null,
      totalPages: null,
      currentPage: null,
      refinements: {},
      activeRefinements: {},
      activeRefinementCount: 0,
    }))
  }

  /**
   * Static Methods
   * These are for use when iteracting with the SearchProvider instance.
   */

  static formatFilterOrRefinement(prop, val, expression?: string, connector?: string) {
    const valueArray = Array.isArray(val) ? val : [val]
    const formattedArray = valueArray.map((value) => ({
      value: value?.toString(),
      ...(expression && { expression }),
      ...(connector && { connector }),
    }))

    if (!prop) return formattedArray

    return {
      [prop]: formattedArray,
    }
  }

  /**
   * Public Methods
   * These are used to interact with the SearchProvider instance.
   */

  // Performs a search on the index with the current search params.
  async search() {
    // Keeps track of the current search count.
    if (this._searchCount === undefined) this._searchCount = 0

    // This is the count of the last time we set the state
    // We use this to make sure the search results that come back are always newer results than what was there.
    if (this._lastSetVersion === undefined) this._lastSetVersion = 0

    // Increment the current search counter.
    this._searchCount++

    // Make a local copy of the count so we can reference after the api hit comes back.
    const count = this._searchCount

    // Trigger the search and get back the data in the state format.
    const searchData = await this._search()

    // It is extremely unlikely, but can happen, where we get no searchData back.
    if (!searchData) return

    // If the page requested is greater than the amount of pages we have to show then we need to throw an error
    // so the user can send to the 404 page
    if (searchData.currentPage > 1 && searchData.currentPage > searchData.totalPages) {
      throw new Error(`Page is out of range, ${JSON.stringify(searchData.rawResponses)}`)
    }

    // Check if this search is the most current version.
    if (count >= this._lastSetVersion) {
      // Since we know this version is newer than the last one we set we need to update it to the new count.
      this._lastSetVersion = count

      // Set the state from the response.
      this._setState(searchData)
    }
  }

  addSortCallback(arg) {
    this.sortCallback = arg
  }

  addFilters(attribute, values, expression?: string, connector = 'OR') {
    const { [attribute]: currentAttribute = [] } = this.state.value.searchParameters.filters
    const formattedValues = SearchProvider.formatFilterOrRefinement(null, values, expression, connector)

    formattedValues.forEach((formattedValue) => {
      const index = currentAttribute.findIndex(({ value }) => value === formattedValue.value)
      if (index > -1) {
        currentAttribute.splice(index, 1, formattedValue)
      } else {
        currentAttribute.push(formattedValue)
      }
    })

    // Vue.set(this.state.value.searchParameters.filters, attribute, currentAttribute)
    this.state.value.searchParameters.filters[attribute] = currentAttribute
    return this
  }

  removeFilters(attribute, values, expression) {
    const { [attribute]: currentAttribute = [] } = this.state.value.searchParameters.filters

    const formattedValues = SearchProvider.formatFilterOrRefinement(null, values, expression)

    formattedValues.forEach((formattedValue) => {
      const index = currentAttribute.findIndex(({ value }) => value === formattedValue.value)
      if (index > -1) currentAttribute.splice(index, 1)
    })

    if (currentAttribute.length > 0) {
      // Vue.set(this.state.value.searchParameters.filters, attribute, currentAttribute)
      this.state.value.searchParameters.filters[attribute] = currentAttribute
    } else delete this.state.value.searchParameters.filters[attribute]

    return this
  }

  clearFilters(attribute) {
    if (attribute && this.state.value.searchParameters.filters[attribute])
      delete this.state.value.searchParameters.filters[attribute]
    else this.state.value.searchParameters.filters = {}

    this.setPage()

    return this
  }

  addRefinements(attribute, values, expression, connector = 'OR') {
    const { [attribute]: currentAttribute = [] } = this.state.value.searchParameters.refinements
    const formattedValues = SearchProvider.formatFilterOrRefinement(null, values, expression, connector)

    formattedValues.forEach((formattedValue) => {
      const index = currentAttribute.findIndex(({ value }) => value === formattedValue.value)
      if (index > -1) currentAttribute.splice(index, 1, formattedValue)
      else currentAttribute.push(formattedValue)
    })

    // Vue.set(this.state.value.searchParameters.refinements, attribute, currentAttribute)
    this.state.value.searchParameters.refinements[attribute] = currentAttribute

    this.setPage()

    return this
  }

  removeRefinements(attribute, values, expression) {
    const { [attribute]: currentAttribute = [] } = this.state.value.searchParameters.refinements
    const formattedValues = SearchProvider.formatFilterOrRefinement(null, values, expression)

    formattedValues.forEach((formattedValue) => {
      const index = currentAttribute.findIndex(({ value }) => value === formattedValue.value)
      if (index > -1) currentAttribute.splice(index, 1)
    })

    if (currentAttribute.length > 0) {
      // Vue.set(this.state.value.searchParameters.refinements, attribute, currentAttribute)
      this.state.value.searchParameters.refinements[attribute] = currentAttribute
    } else {
      delete this.state.value.searchParameters.refinements[attribute]
    }

    this.setPage()

    return this
  }

  // Clear all the active refinements and page.
  clearRefinements(attribute) {
    if (attribute) {
      delete this.state.value.searchParameters.refinements[attribute]
    } else this.state.value.searchParameters.refinements = {}

    this.setPage()

    return this
  }

  // Check if a refinement is currently refined, or if a value for a refinement is refined.
  isRefined(attribute, value) {
    if (value === undefined) return !!this.state.value.searchParameters.refinements[attribute]

    return (
      !!this.state.value.searchParameters.refinements[attribute] &&
      this.state.value.searchParameters.refinements[attribute].findIndex((refinement) => refinement.value === value) >
        -1
    )
  }

  // Increase the page by 1.
  nextPage() {
    if (this.state.value.currentPage === this.state.value.totalPages) return this
    return this.setPage(this.page + 1)
  }

  // Decrease the page by 1.
  prevPage() {
    if (this.state.value.currentPage === 1) return this
    return this.setPage(this.page - 1)
  }

  // Set the page to a specific number.
  setPage(page) {
    const pageNumber = page || 1 // To reset, we call the function without a param so we need to fallback to 1 if page is undefined.

    // if the page number is the same as it is now then we can early out.
    if (pageNumber === this.state.value.searchParameters.page) return this

    // The pageNumber is 1 based, not 0 based... So this means the lowest page we can display is 1.
    if (pageNumber < 1) throw new Error('Page number must be >= 1')

    // Set our state to the passed page number
    this.state.value.searchParameters.page = pageNumber

    return this
  }

  // Set the amount of results per page.
  setResultsPerPage(resultsPerPage) {
    this.state.value.searchParameters.resultsPerPage = resultsPerPage

    this.setPage()

    return this
  }

  // Set the value for the search query
  setQuery(query) {
    // If we have multiple indexes then the query should be set at every instance
    if (this.indexes) {
      this.indexes.forEach((i) => i.setQuery(query))

      return this
    }

    // If the value is the same as it already is then we shouldn't reset anything because we are on the same query
    if (this.state.value.searchParameters.query === query) return this

    // if query doesn't exist then fallback to empty string
    this.state.value.searchParameters.query = query || ''

    // Reset to the first page everytime the query is updated.
    this.setPage()

    return this
  }

  setSort(index) {
    if (!this.state.value.sorts) return this

    const sortIndex = parseInt(index) || 0

    const sort = this.state.value.sorts[sortIndex]

    if (sort) {
      this.state.value.indexName = sort.indexName
      this.state.value.currentSort = sortIndex
    }

    this.setPage()

    return this
  }

  setLocation(lat, lng) {
    // If we have multiple indexes then the query should be set at every instance
    if (this.indexes) {
      this.indexes.forEach((i) => i.setLocation(lat, lng))

      return this
    }

    this.state.value.searchParameters.location = `${lat}, ${lng}`

    return this
  }

  setRadius(radius) {
    // If we have multiple indexes then the query should be set at every instance
    if (this.indexes) {
      this.indexes.forEach((i) => i.setRadius(radius))

      return this
    }

    this.state.value.searchParameters.radius = radius

    return this
  }

  createRouteQuery() {
    const query = {}

    Object.entries(this.state.value.searchParameters.refinements).forEach(([key, valueArray]) => {
      valueArray.forEach(({ value }) => {
        if (!query[key]) query[key] = []
        query[key].push(value)
      })
    })

    if (this.state.value.searchParameters.page > 1) query.page = this.state.value.searchParameters.page

    if (this.state.value.searchParameters.query.length > 0) query.q = this.state.value.searchParameters.query

    if (this.state.value.sorts && this.state.value.currentSort > 0) query.sort = this.state.value.currentSort

    return query
  }

  // This will modify our search params with the info that we store in the url
  syncFromRoute(route) {
    const { page, q, sort, ...refinements } = route.query

    this.clearRefinements()

    Object.entries(refinements).forEach(([attribute, value]) => {
      if (!this.state.value.searchParameters.facets.includes(attribute)) return
      const valueArray = Array.isArray(value) ? value : [value]
      this.addRefinements(attribute, valueArray)
    })

    this.setQuery(q)
    this.setSort(sort)
    this.setPage(page)

    return this
  }

  /**
   * Private Methods
   * Only to be used within the SearchProvider Class
   */
  // This actually hits algolia and takes the results from the hit and passes them into our formatter function.
  async _search() {
    // If we are multi index then we need to get all the queries that we need to preform.
    if (this.indexes) {
      const queries = this.indexes.map((index) => index._createSearchQueries())

      // We need to have a flat array of queries to pass, so reduce over the queries and flatten them into a single array.
      const { results } = await this.client.multipleQueries(queries.reduce((acc, val) => acc.concat(val), []))

      // Bug Fix: It is somehow possible for multipleQueries to return no results
      if (results?.length === 0) return

      // Unlike a single index, we need to know what hits went to what.
      // Since we have to pass a single array of values ot the query request above,
      // we need to pass the querys(not flattened) into the formatter so it knows how to split the results back
      // into each search instance.
      return this._formatQueryResponse(results, queries)
    }
    const queries = this._createSearchQueries()
    const { results } = await this.client.multipleQueries(queries)
    return this._formatQueryResponse(results)
  }

  // Create the array of search queries for the SearchProvider.
  // The first query is the primary hit that holds all the actual data for the request (hits, amt of results, etc..)
  // If we have refinements, the additional queries are used to validate values and get the facet data back so we have all the values for that facet.
  _createSearchQueries() {
    return [this._createQuery(), ...this._createRefinementQueries()]
  }

  // Formats searchParameters into the proper format that algolia cares about.
  _createQuery(params) {
    // Take our current params and merge them with the overrides that are passed to this function.
    const mergedSearchParameters = {
      ...this.state.value.searchParameters,
      ...params,
    }

    // Get all the variables from the mergedSearchParameters
    const { attributes, facets, filters, location, page, query, radius, resultsPerPage, refinements, custom } =
      mergedSearchParameters

    // Construct the basic query obj
    const searchQuery = {
      indexName: this.state.value.indexName,
      query,
      params: {
        facets,
        page: page - 1,
        hitsPerPage: resultsPerPage,
        ...(!query && { analytics: false }),
        ...(attributes.length > 0 && { attributesToRetrieve: attributes }),
        ...(location &&
          radius && {
            aroundLatLng: location,
            aroundRadius: Math.ceil(radius * 1609.344),
          }),
        ...custom,
      },
    }

    // Iterate over the filters and refinements to construct the filter string needed to search for values.
    searchQuery.params.filters = [...Object.entries(filters), ...Object.entries(refinements)]
      .map(([key, valueArray]) => {
        // We need to group by AND / OR first
        const groupByConnector = valueArray.reduce((acc, result) => {
          if (!acc[result.connector]) acc[result.connector] = []

          acc[result.connector].push(result)

          return acc
        }, {})

        // Now construct our strings of (AND/OR) AND (AND/OR) filters
        return Object.entries(groupByConnector)
          .map(([connector, values]) => {
            const output = values
              .map(({ value, expression = ':' }) => {
                // Make sure we escape single quotes so it doesn't break the expression.
                const formattedValue = value.replace(/'/g, "\\'")
                // Look for any character that is not a word character or whitespace. If we find one then we need to wrap it in single quotes
                // The reason we don't wrap the string in quotes all the time is because we need to pass numbers and boolean values to algolia and those can't be wrapped in quotes.
                const formattedString = formattedValue.search(/[\s\W]/g) > -1 ? `'${formattedValue}'` : formattedValue

                return `${key}${expression}${formattedString}`
              })
              .join(` ${connector} `)

            return `( ${output} )`
          })
          .join(' AND ')
      })
      .join(' AND ')

    return searchQuery
  }

  // This will construct an array of queries based upon what refinements we have currently applied.
  _createRefinementQueries() {
    const { refinements } = this.state.value.searchParameters

    // Get the keys of the current refined facets. Ex: brand, material, etc..
    const refinementKeys = Object.keys(refinements)

    // If we don't have any then we can just early out
    if (refinementKeys.length === 0) return []

    // Creates the query used to validate if the passed values are actual facet values.
    // Refer to the _formatQueryResponse to see how this query is used.
    const refinementValidatorQuery = this._createQuery({
      page: 1,
      resultsPerPage: 1,
      refinements: {},
    })

    // For each refinement, we need to know the updated values based upon what other refinements are selected.
    // Ex: If we have 'brand' and 'priceRange' refinements applied, we only want to get the brand(s) that are valid for the priceRange(s)
    // and the priceRange(s) for the brand(s)
    const refinementQueries = refinementKeys.map((refinementKey) => {
      // This pulls the current refinement out of the list so we have the remaining refinements to apply to the query.

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [refinementKey]: selectedFacet, ...refinementsToApply } = refinements

      return this._createQuery({
        facets: [refinementKey],
        page: 1,
        resultsPerPage: 1,
        refinements: refinementsToApply,
      })
    })

    // Return all of the querys needed for the refinements.
    return [refinementValidatorQuery, ...refinementQueries]
  }

  // Once we get the response back from algolia we need to format the values and apply them to our search state.
  _formatQueryResponse(responses, queries) {
    // If we have multi indexes then we need to iterate through them so we can pull out the queries for that specific index.
    // The queries are generated in the same order, and come back in the same order. Because of this we can match queries
    // back to the index they came from.
    if (this.indexes) {
      return this.indexes.map((index, arrIndex) => {
        const responseCount = queries[arrIndex].length
        return index._formatQueryResponse(responses.splice(0, responseCount))
      })
    }

    // The first value in the responses is always the primary response...IE: the one that has everything applied so we get all the result info from that one.
    // The remaining response objects are all of the queries for the refinement values.
    const [primaryResponse, ...facetResponses] = responses

    // Out of the refinement value queries, the first one is the validation query that we will use to validate our refinement values are actually valid.
    // The remaining facetResponses are the queries for each individual facet.
    const [facetValidationQuery, ...facetValueResponses] = facetResponses

    // The values from the primary query response.
    const { hits, nbHits, nbPages, page, facets = {}, queryID } = primaryResponse

    // We need to combine all of the facet values together so we have a unified obj with the proper results.
    /**
         * Example format we are dealing with.
            {
                brand: {
                    BAK: 4664,
                    UnderCover: 3810,
                    'Pace Edwards': 3256,
                    ...
                },
                material: {
                    Aluminum: 10708,
                    Vinyl: 9087,
                    Fiberglass: 7006,
                    ...
                },
                ...
            }
        */
    const combinedFacets = Object.assign(
      facets,
      ...facetValueResponses.map((facetValueResponse) => facetValueResponse.facets)
    )

    // If we don't get any facet values back from algolia, we still need to make sure we show you the values you have
    // selected so you can remove them. This just formats what you currently have selected back into the format we get from algolia.
    if (Object.keys(combinedFacets).length === 0) {
      Object.entries(this.state.value.searchParameters.refinements).forEach(([facet, values]) => {
        combinedFacets[facet] = values.reduce((acc, { value }) => {
          acc[value] = true
          return acc
        }, {})
      })
    }

    // This is used so we can compare the facet values and sort them based upon many different usecases.
    // Text, numeric, alphanumeric, special characters, etc..
    // Checkout the sort function below where we use the collator's compare function to properly sort the refinement values.
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

    // This is where we transform the data back into the format for our state.
    const results = hits
    const resultCount = nbHits
    const currentPage = page + 1
    const totalPages = nbPages
    const activeRefinements = {}
    let activeRefinementCount = 0

    // the facets are a special case where we need to go through all the combinedFacets values to transform them into our local format.
    const refinements = Object.entries(combinedFacets).reduce((acc, [facet, facetValues]) => {
      // Based upon the comment above for the combinedFacets format the vars being destructured in the array would be:
      // - facet = 'brand'
      // - facetValues = { BAK: 4664, UnderCover: 3810, 'Pace Edwards': 3256, ... }

      // This pulls the keys from the facetValues obj to give us an array of facet values to display.
      const values = Object.keys(facetValues)

      // We need to get our current refinement to we if we have one for that in our state.
      const currentFacet = this.state.value.searchParameters.refinements[facet]

      // We have some instance were you drill down so specific that another value you selected might not return from algolia because it doesn't meet the search crit.
      // So to cover all cases, we need to make sure our selected values are present.
      if (currentFacet) {
        // Make sure our current selections are in the facet values, if they aren't then we add it
        currentFacet.forEach(({ value }) => {
          if (values.includes(value)) return
          values.push(value)
        })
      }

      // This is where we construct an obj structure for the facet value so we know if its currently selected or not.
      acc[facet] = values
        .map((value) => {
          // We need to check if the value is valid based upon what algolia tells us.
          // The second part of that expression is what stops something like &brand=foobar being put in the url and then 'foobar' showing up in the brand refinement list.
          const isValid = facetValidationQuery
            ? !!(facetValidationQuery.facets[facet] && facetValidationQuery.facets[facet][value])
            : true

          const isRefined = this.isRefined(facet, value)

          // If a facet contains '|' then we want to display everything after that value
          // I believe right now the only time we actually need this is when we are using a category as a facet 'categories.lvl1.text'
          const display = value.substring(value.lastIndexOf('|') + 1)

          // This is the structure of a refinement.
          const refinementObj = {
            value,
            display,
            isRefined,
            isValid,
          }

          // If the value is refined then we need to add it to the activeRefinements for the current search.
          // Doing it this way will make sure we don't have object or array bleed which is why
          // i'm not pulling them directly from the searchParams directly.
          if (isRefined) {
            if (!activeRefinements[facet]) activeRefinements[facet] = []
            activeRefinements[facet].push(refinementObj)
            activeRefinementCount++
          }

          return refinementObj
        })
        // we need to sort the values so they are always in ascending order.
        // Right now the only thing that threw off the sort was double quotes so when we sort we remove them.
        // We might need to switch those to regexes in the future if we need to start caring about other values.
        // Jake: 2-19-21 - We had an issue with more values so we updated the replace to a regex to remove more problematic characters while sorting.
        .sort((a, b) => {
          return collator.compare(a.display.replace(/[" /]+/g, '-'), b.display.replace(/[" /]+/g, '-'))
        })

      return acc
    }, {})

    // construct and return an object that can be applied to our state.
    return {
      queryID,
      results,
      resultCount,
      currentPage,
      totalPages,
      refinements,
      activeRefinements,
      activeRefinementCount,
      rawResponses: responses,
    }
  }

  // Use this to set the state from the search responses to the proper state fields
  _setState(searchData) {
    if (this.indexes) {
      return this.indexes.forEach((index, arrIndex) => {
        index._setState(searchData[arrIndex])
      })
    }

    // console.log(searchData)

    const {
      queryID,
      results,
      resultCount,
      currentPage,
      totalPages,
      refinements,
      activeRefinements,
      activeRefinementCount,
    } = searchData

    if (this.sortCallback) results.sort(this.sortCallback)

    // The results from the search
    this.state.value.queryID = queryID

    // The results from the search
    this.state.value.results = results

    // the result count from the search
    this.state.value.resultCount = resultCount

    // Set the currentPage to our 1 based page values
    this.state.value.currentPage = currentPage

    // Set the total amount of pages from the algolia result
    this.state.value.totalPages = totalPages

    // The values that are used to refine the search
    this.state.value.refinements = refinements

    // The dict of refinements that are currently applied to the search
    this.state.value.activeRefinements = activeRefinements

    // The number of refinements that are applied to the search
    this.state.value.activeRefinementCount = activeRefinementCount
  }
}
