export default function () {
  let callbacks: (() => void)[] = []

  /**
   * Adds a callback that gets called after load() is ran
   * @param callback The function to call
   */
  function addCallback(callback: () => void) {
    callbacks.push(callback)
  }

  /**
   * Runs the callback code
   */
  function load() {
    callbacks.forEach((callback) => callback())
    callbacks = []
  }

  return { load, addCallback }
}
