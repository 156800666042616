<template lang="pug">
FormKit(
  v-bind='$attrs'
  data-role='none'
  type='email'
  :aria-label='label'
  :classes='classes'
  :errors='errors'
  :label='label'
  :maxlength='maxLength'
  :name='name'
  :placeholder='placeholder'
  :validation='extendedValidation'
  :validation-label='validationLabel'
  :validation-messages='extendedValidationMessages'
  @blur='onBlur'
  @focus='onFocus'
)
</template>

<script setup lang="ts">
const emit = defineEmits(['blur', 'focus'])
/*
  ===
  FormKit Properties
  ===
  classes: Object
    The classes prop is similar to the section-key class prop except it allows setting classes on all
    sections at the same time.
    See: https://formkit.com/essentials/styling#classes-prop#classes-prop
  ---
  errors: String[]
    Array of strings to show as error messages on this field.
    See: https://formkit.com/essentials/inputs#explicit-errors
  ---
  validation: String
    The validation rules to be applied to the input.
    See: https://formkit.com/essentials/validation
  ---
  validation-label: String
    Determines what label to use in validation error messages, by default it uses the label prop if
    available, otherwise it uses the name prop.
    See: https://formkit.com/essentials/validation#custom-messages
*/

interface Props {
  classes?: {}
  errors?: string[]
  label?: string
  maxLength?: number
  name?: string
  placeholder?: string
  validation?: string
  validationLabel?: string
  validationMessages?: {}
}

const props = withDefaults(defineProps<Props>(), {
  classes: () => {
    return {}
  },
  errors: () => [],
  label: '',
  maxLength: 50,
  name: '',
  placeholder: '',
  validation: '',
  validationLabel: '',
  validationMessages: () => {
    return {}
  },
})

const extendedValidation = computed(() => {
  return `${props.validation}|email`
})

const extendedValidationMessages = computed(() => {
  return { ...props.validationMessages, email: 'Please enter a valid email.' }
})

function onBlur() {
  emit('blur')
}

function onFocus() {
  emit('focus')
}
</script>
