export default function wireFitmentListeners() {
  const segment = useSegmentHelper()
  const { getFitment } = useSegmentProductsHelper()
  const { $uiEvents } = useNuxtApp()
  // Note: Emit when a USER changes fitment (ignore auto-fill)
  $uiEvents.$on('fitmentChanged', emitFitmentChanged)
  $uiEvents.$on('fitmentChanged', () => segment.emitIdentifyFitment())

  function emitFitmentChanged({ sourceType, newValue, layoutPosition }: fitmentChange) {
    // Emit was type was selected
    segment.track(`Fitment: Selected: ${sourceType}`, {
      [sourceType]: newValue,
      ...(layoutPosition && { layoutPosition }),
    })

    // Emit Primary Fitment Change event if they have just changed their Model
    if (sourceType === 'model') {
      const primaryFitment = getFitment()
      primaryFitment['model'] = newValue
      segment.track('Fitment: Selected: Primary YMM', {
        ...primaryFitment,
        ...(layoutPosition && { layoutPosition }),
      })
    }
  }
}

interface fitmentChange {
  sourceType: FitmentTextKey
  newValue: string
  layoutPosition: string
}
