export default function wireNewsletterListeners() {
  const segment = useSegmentHelper()
  const { $uiEvents, $emailTracker } = useNuxtApp()

  $uiEvents.$on('newsletterFormSubmitted', emitFormSubmitted)
  $uiEvents.$on('newsletterSubscribed', emitEmailSubscribed)
  $uiEvents.$on('newsletterSubscribedSms', emitSmsSubscribed)
  $uiEvents.$on('newsletterUnSubscribed', emitEmailUnSubscribed)

  function emitFormSubmitted(data: any) {
    // one event, form submitted - prop the name
    segment.track('Form Submitted', data)
  }

  function emitEmailSubscribed(data: any) {
    // As the user has opted in we should store the Email
    $emailTracker.setEmail(data.email)
    // Identify the user as normal
    segment.emitIdentify(data)
    segment.track('Email Subscribed', data)
  }

  function emitSmsSubscribed(data: any) {
    $emailTracker.setEmail(data.email)
    // Identify the user as normal
    segment.emitIdentify(data)
    segment.track('Sms Subscribed', data)
  }

  function emitEmailUnSubscribed(data: any) {
    // Identify the user as normal
    segment.emitIdentify(data)
    segment.track('Email UnSubscribed', {})
  }
}
