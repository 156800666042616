<template lang="pug">
button(
  v-if='!fitmentStore.fitmentFormatted'
  class='flex items-center justify-center py-2 px-4 capitalize font-bold cursor-pointer focus:outline outline-2 outline-action outline-offset-2'
  data-testid='fitmentButton'
  @click='open'
)
  span(class='w-12 h-9 mr-3 flex items-center justify-center')
    img(:src='iconSrc' class='w-full')
  span(class='text-sm' data-testid='vehicleSelection') Select Your Vehicle

button(
  v-else
  class='flex items-center justify-center py-2 px-4 capitalize font-bold cursor-pointer focus:outline outline-2 outline-action outline-offset-2'
  data-testid='fitmentButton'
  @click='open'
)
  span(class='flex flex-col leading-tight text-left')
    span(class='text-sm' data-testid='fitmentSelection') {{ fitmentStore.fitmentFormatted }}
    span(class='text-xs') {{ fitmentStore.fitmentFormattedSecondary }}
  span(class='border-l border-solid border-current leading-tight py-1 ml-auto pl-3 lg:ml-3 text-xs font-normal') Change
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const fitmentStore = useFitmentStore()
const { showFitmentModal } = useFitmentDisplayStore()
const props = defineProps<{ icon: Object }>()

const iconSrc = computed(() => {
  return $storyblok.formatSrc(props.icon?.filename)
})

function open() {
  if ($storyblok.isEditing.value && $storyblok.contentType.value === 'navbar') return
  showFitmentModal()
}
</script>
