export default function wireNavigationListeners() {
  const segment = useSegmentHelper()
  const { getFullFitment } = useSegmentProductsHelper()
  const { $uiEvents } = useNuxtApp()
  $uiEvents.$on('navigationClicked', (data) => emitNavigationClicked(data))

  function emitNavigationClicked(data: any) {
    if (!data.link && !data.text) return

    // == Get the full fitment data to add to the event
    const fitment = getFullFitment()
    let targetUrl = ''
    // We will either have a link or text
    if (data.link) {
      // == Clean the URL: We do not want vehicle information in the url
      const url = new URL(data.link)
      // We use the href because it has query params, but we don't want the origin
      targetUrl = url.href.replace(url.origin, '')
      // Remove Vehicle from the URL if it exists
      if (url.pathname.indexOf('/v/') > 0) {
        // Pathname does not have query params so we can remove everything after /v/ and append the query (search) back on
        targetUrl = url.pathname.substring(0, url.pathname.indexOf('/v/') + 1) + url.search
      }
    } else if (data.text) {
      // Format the text of the button
      targetUrl = '#' + data.text.toLowerCase().replace(' ', '-')
    }

    // == Log this event!
    // Note: Why data.level > 0 ??
    // Because: Mobile textLink is shared by tree-menu and link-menu so it could report level:0
    // we want to ignore that.
    segment.track('Navigation Clicked', {
      targetUrl,
      ...(fitment && { fitment }),
      ...(data.level > 0 && { level: data.level }),
      ...(data.rootText && { parentLabel: data.rootText }),
    })
  }
}
