export default defineNuxtPlugin(() => {
  const { $uiEvents } = useNuxtApp()
  const { isProduction } = useUtils()

  const customerEmail = useCookie('customerEmail', {
    maxAge: 315360000, // 10 years
    secure: isProduction(),
  })

  function getEmail() {
    const email = customerEmail.value

    if (email) setEmail(email)
    return email || undefined
  }

  function setEmail(email: string) {
    const currentEmail = customerEmail.value
    // Update the Cookie Value
    customerEmail.value = email
    // If the email has changed, notify segment
    if (email !== currentEmail) {
      $uiEvents.$emit('emailSet')
    }
  }

  return {
    provide: {
      emailTracker: {
        getEmail,
        setEmail,
      },
    },
  }
})
