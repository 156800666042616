<template lang="pug">
//- We need to specifically check for hard breaks here to avoid hydration issues in Nuxt 3.
//- Because the way that Vue optimizes component rendering v-if templates leave behind a comment in the html
//- which causes the <br> tag to be wrapped around the comment (<br> <!-- --> </br>) that causes the hydration issue.
br(v-if='data.type === "hard_break"')
component(:is='elementOptions.is' v-else v-bind='elementOptions.attrs')
  template(v-for='(ele, index) in elementOptions.data' :key='index')
    CmsWidgetTextElement(:data='ele' :link-color='linkColor')
</template>

<script setup lang="ts">
import { CmsWidgetTextContent } from '#components'

interface ElementData {
  attrs?: {
    level?: string
  }
  content?: ElementData[]
  type: string
}

const { data, linkColor = '' } = defineProps<{
  data: ElementData
  linkColor?: string
}>()

interface DisplayOptions {
  is: string | typeof CmsWidgetTextContent
  data: ElementData[]
  attrs?: any
}

const elementOptions = computed(() => {
  const options: DisplayOptions = {
    is: 'div',
    data: data.content || [],
    attrs: {},
  }

  switch (data.type) {
    case 'bullet_list':
      options.is = 'ul'
      break

    case 'ordered_list':
      options.is = 'ol'
      break

    case 'list_item':
      options.is = 'li'
      break

    case 'heading':
      options.is = data.attrs?.level ? `h${data.attrs.level}` : 'h2'
      break

    case 'text':
      options.is = CmsWidgetTextContent
      options.attrs.data = data
      options.attrs.linkColor = linkColor
      break

    case 'paragraph':
      options.is = 'p'
      break

    default:
      options.is = 'div'
      break
  }

  return options
})
</script>
