export default function () {
  const fitmentStore = useFitmentStore()

  function getFitmentAttributes() {
    if (!fitmentStore.makeSlug) return

    const fitmentString = [
      fitmentStore.makeSlug,
      fitmentStore.modelSlug,
      fitmentStore.year,
      fitmentStore.bedSlug,
      fitmentStore.bodySlug,
      fitmentStore.engineSlug,
    ]
      .map((slug) => {
        // If the slug is "n/a" then convert it to "none"
        // otherwise return the slug and fallback to "none" if the value is undefined or empty string
        return slug === 'n-a' ? 'none' : slug || 'none'
      })
      .join('*')

    const value = [fitmentString, 'none*none*none*none*none*none']

    return {
      name: 'make*model*year*bed*body*engine',
      value,
    }
  }

  return {
    getFitmentAttributes,
  }
}
