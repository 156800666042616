export default defineNuxtPlugin(() => {
  const { isDev } = useUtils()
  const appConfig = useAppConfig()
  const router = useRouter()
  const log = useLogger('APP_VERSION')

  // We don't want to run this if we are in development mode.
  if (isDev()) return

  // How many hours we want to throttle the app version check.
  const hoursToThrottle = 24

  const isAppUpToDate = ref(true)

  setInterval(async () => {
    try {
      const { version } = await $fetch('/api/version')

      // If the appConfig build id is the same as the version of the build we get from the api,
      // then the users app version is the most current.
      isAppUpToDate.value = appConfig.buildId === version
    } catch (error) {
      log.warning('Unable to get app version', { error })
    }
  }, 1000 * 60 * 60 * hoursToThrottle)

  router.beforeEach(async (to, from, next) => {
    // If the app is not on the newest version then we need to reload the app to the place they were trying to go.
    // when the user navigates to a new page. The to and from paths will be equal if they are opening a modal or filtering.
    // so we only want to reload the app if the paths are not equal.
    if (!isAppUpToDate.value && to.path !== from.path) {
      // Info log so we can see in bugsnag how often this happens, out of curiosity.
      // In theory, we don't need to send the version since bugsnag will already know it but it doesn't hurt to send it just in case.
      log.info('app is not current', {
        version: appConfig.buildId,
      })

      // Set the location to the passed url so we can reload the app to a specific page.
      window.location = to.fullPath as unknown as Location
    }

    next()
  })
})
