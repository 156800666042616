<template lang="pug">
div(id='app')
  NuxtLayout
    NuxtPage

  //- Popup MiniCart
  Overlay(unique-key='mc')
    template(#noPadding)
      LazyCartMini(show-continue-shopping)

  LazyPhotoswipeLightbox(
    v-if='lightboxShow'
    :index='lightboxIndex'
    :items='lightboxItems'
    @closed='lightboxShow = false'
  )

  Teleport(to='#teleports')
    Notifications
    ClientOnly
      LazyInternationalSitePopup

  div(v-if='$storyblok.isEditing.value' class='fixed inset-0 z-[60]')
</template>

<script setup lang="ts">
const { $storyblok, $sitewideConfig } = useNuxtApp()
const { isDev } = useUtils()
const { lightboxIndex, lightboxItems, lightboxShow } = usePhotoswipeLightbox().appVars

if ($sitewideConfig.styles) {
  let favicon = $sitewideConfig.styles.content.icon.filename
  if (!isDev()) favicon = favicon.replace('a.storyblok.com', $sitewideConfig.domain)

  const defaultTags = createFavIconTags([16, 32, 96, 128, 192, 196, 384], favicon)
  const appleTags = createFavIconTags([57, 60, 72, 76, 114, 120, 144, 152], favicon, 'apple-touch-icon-precomposed')

  useServerHead({ link: [...defaultTags, ...appleTags] })
}

function createFavIconTags(sizes: number[], filename: string, rel = 'icon') {
  const icons = sizes || []

  return icons.map((size) => {
    const tag: any = {
      rel,
      href: `${filename}/m/${size}x${size}`, // Use Storyblok image service to resize to correct dimensions
    }

    tag.sizes = `${size}x${size}`
    if (rel === 'icon') tag.type = `image/${filename.slice(-3)}` // Only supports 3 char extensions (assumes no .jpeg)

    return tag
  })
}
</script>
