// TODOLATER: Consider https://unhead.unjs.io/usage/composables/use-script
export default function () {
  const srcs: string[] = []
  let callbacks: (() => void)[] = []
  let errorCallbacks: (() => void)[] = []
  let hasError = false
  let hasLoaded = false
  let loadCount = 0
  let isLoading = false

  /**
   * Adds a script source to the loader
   * @param src The full URL of the script to add
   */
  function addSrc(src: string) {
    srcs.push(src)
  }

  /**
   * Adds a callback to run after all script sources are loaded
   * @param callback The function to run
   */
  function addCallback(callback: () => void) {
    callbacks.push(callback)
  }

  /**
   * Adds a callback to run if a script cannot be loaded
   * @param callback The function to run
   */
  function addErrorCallback(callback: () => void) {
    errorCallbacks.push(callback)
  }

  /**
   * Adds the script tags to the DOM and executes callbacks when they are all done
   */
  function load() {
    // if this was loaded on page X, page Y could also need it
    if (hasLoaded) {
      _invokeCallbacks()
      return
    }

    if (isLoading) return

    isLoading = true

    srcs.forEach((src) => {
      const script = _getScriptTag(src)
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }

  /**
   * This should only be used if your callbacks are dependant on an external event like Google Places calling it's loading callback after it's initialized.
   * @summary Forces the calling of callbacks.
   */
  function forceRunCallbacks() {
    hasLoaded = true // run the callbacks direct after we know the external callback trigger has already triggered
    _invokeCallbacks()
  }

  function _getScriptTag(src: string) {
    const script = document.createElement('script')

    script.src = src
    script.async = true
    script.onload = () => {
      loadCount++
      if (srcs.length === loadCount && !hasError) {
        hasLoaded = true
        _invokeCallbacks()
      }
    }

    script.onerror = () => {
      hasError = true
      _invokeErrorCallbacks()
    }

    return script
  }

  function _invokeCallbacks() {
    callbacks.forEach((callback) => callback())
    callbacks = []
    // if we run callbacks then we were successfully loaded, so we do not need the error callbacks
    errorCallbacks = []
  }

  function _invokeErrorCallbacks() {
    errorCallbacks.forEach((callback) => callback())
    errorCallbacks = []
  }

  return {
    load,
    addSrc,
    addCallback,
    addErrorCallback,
    forceRunCallbacks,
  }
}
