<template lang="pug">
div(:class='{ "min-h-[447px] md:min-h-[491px]": reserveSpace }')
  div(v-if='recommendationsData' class='py-6')
    div(v-if='recommendationsData.title' class='text-gray-darkest text-2xl font-bold leading-5 pb-8 lg:text-3xl') {{ recommendationsData.title }}
    Carousel(container-class='gap-x-3')
      div(
        v-for='(item, index) in recommendationsData.results'
        :key='`carousel-product-${item.productLineName}-${index}`'
        class='w-[275px]'
      )
        ProductListing(:listing='item' :is-carousel='true' :is-schema-on='false' class='h-full pb-1 lg:pb-0 lg:mb-5')
</template>

<script setup lang="ts">
const recommendationsStore = useRecommendationsStore()

const { productType = 'similar', reserveSpace = false } = defineProps<{
  productType: string
  reserveSpace?: boolean
}>()

const recommendationsData = computed(() => {
  return recommendationsStore.state[productType as keyof RecommendationState]
})
</script>
