<template lang="pug">
div(data-pp-message :data-pp-offer='type' :data-pp-style-text-align='alignment')
</template>

<script setup lang="ts">
const { $paypal } = useNuxtApp()
const log = useLogger('PAYPAL_MARKETING')

const { type, alignment = 'center' } = defineProps<{
  type: 'PAY_LATER_SHORT_TERM' | 'PAY_LATER_LONG_TERM'
  alignment: 'left' | 'center' | 'right'
}>()

onMounted(() => {
  renderMessage()
})

async function renderMessage() {
  if (!window.paypal) await $paypal.display.load()

  if (!window.paypal.Messages) {
    log.error('Paypal messaging display error')
    return
  }

  window.paypal.Messages.render()
}
</script>
