export default function (shippingData) {
  if (!shippingData.estimatedDeliveryDate) return

  const { min, max } = shippingData.estimatedDeliveryDate
  // const min = '2021-01-02T13:00:00.000Z'
  // const max = '2021-01-02T13:00:00.000Z'

  if (min === max) {
    const d = getDateObject(min)
    return `${d.day}, ${d.monthStr} ${d.date}${d.dateSuffix}`
  } else {
    const minD = getDateObject(min)
    const maxD = getDateObject(max)
    const maxMonthString = minD.month !== maxD.month ? maxD.monthStr + ' ' : ''
    return `${minD.monthStr} ${minD.date}${minD.dateSuffix} - ${maxMonthString}${maxD.date}${maxD.dateSuffix}`
  }
}

function getDateObject(date) {
  const d = new Date(date)
  return {
    month: d.getUTCMonth(),
    monthStr: getFullNameMonth(d.getUTCMonth()),
    day: getFullNameDay(d.getUTCDay()),
    date: d.getUTCDate(),
    dateSuffix: getOrdinal(d.getUTCDate()),
  }
}

function getFullNameMonth(month) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return months[month] || ''
}
