<template lang="pug">
div
  FitmentSelect(
    v-for='select in modalRequiredSelectTypes'
    :key='select.type'
    :select='select'
    :current-value='select.currentValue'
    class='mb-3'
    @changed='selectChanged'
  )

  div(v-if='showOptionals && modalOptionalSelectTypes.length > 0' class='mt-6')
    span(class='text-gray') Optional Fields
    FitmentSelect(
      v-for='select in modalOptionalSelectTypes'
      :key='select.type'
      :select='select'
      disable-highlight
      :current-value='select.currentValue'
      class='mb-3'
      @changed='selectChangedOptional'
    )
</template>

<script setup lang="ts">
// fitment / form
const emit = defineEmits(['isFitmentValid'])
const { layoutPosition, allDoneSelecting, selectChanged, selectChangedOptional } = useFitmentDisplay()
const { modalRequiredSelectTypes, modalOptionalSelectTypes } = useFitmentDisplayStore()
const { showOptionals = true, location = '' } = defineProps<{
  showOptionals?: boolean
  location?: string
}>()

onMounted(() => {
  if (location) layoutPosition.value = location
  emit('isFitmentValid', allDoneSelecting.value)
})

watch(allDoneSelecting, (newVal) => {
  emit('isFitmentValid', newVal)
})
</script>
