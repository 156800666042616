// This was in the old /helpers/shipping.js file (sameDayShippingDisplayMixin).. I don't see any reason this really needs to be computed

export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const shippingMode = $sitewideConfig.config.sameDayShippingMode

  const sameDayShippingBackgroundColor = shippingMode === 'RAPIDSHIP' ? 'bg-success' : 'bg-info'
  const sameDayShippingBorderColor = shippingMode === 'RAPIDSHIP' ? 'border-success' : 'border-info'
  const sameDayShippingBorderTopColor = shippingMode === 'RAPIDSHIP' ? 'border-t-success' : 'border-t-info'
  const sameDayShippingTextColor = shippingMode === 'RAPIDSHIP' ? 'text-success' : 'text-info'
  const sameDayShippingText = shippingMode === 'RAPIDSHIP' ? 'RapidShip' : 'QuickShip'

  return {
    sameDayShippingBackgroundColor,
    sameDayShippingBorderColor,
    sameDayShippingBorderTopColor,
    sameDayShippingTextColor,
    sameDayShippingText,
  }
}
