<template lang="pug">
ProtexWrapper(:force-show='$storyblok.isEditing.value' :should-track='!$storyblok.isPreview.value')
  template(v-for='widget in data.widgets')
    slot(:data='widget' :space-left='spaceLeft')
</template>

<script>
export default {
  name: 'ProtexWidget',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    spaceLeft: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
