<template lang="pug">
component(:is='parentTag' ref='bgImageElement' class='bg-no-repeat bg-center bg-cover' :style='bgImageStyle')
  slot
</template>

<script setup lang="ts">
const { $device, $storyblok } = useNuxtApp()
const { observe } = useRunOnVisible()

const shouldLazyLoad = $storyblok.shouldLazyLoad(false)

const isLazyLoaded = ref(!shouldLazyLoad)

// $el from vue2 is now done with ref(null).
// The variable and the ref string must be the same.
const bgImageElement = ref(null)

const props = defineProps<{
  tag?: string
  mobileFilename?: string
  tabletFilename?: string
  desktopFilename?: string
  spaceLeft: SpaceLeft
}>()

const parentTag = computed(() => props.tag || 'div')

// Must be reactive based on $device
const bgImageSrc = computed(() => {
  // TODOLATER: Verify we need this backwards compatibility
  // If we do it would no longer be here but where we pass in the filename props
  // if (props.data.backgroundImage?.filename) return formatSrc(props.data.backgroundImage.filename)

  let backgroundImage: string = ''
  let maxWidth = null

  // Determine maxWidth based on spaceLeft
  if ($device.value.isMobile) maxWidth = props.spaceLeft.mobile
  else if ($device.value.isDesktop) maxWidth = props.spaceLeft.desktop
  else maxWidth = props.spaceLeft.tablet

  // Pick a filename based on Device
  if (props.mobileFilename) {
    backgroundImage = props.mobileFilename
  }

  if (props.tabletFilename && !$device.value.isMobile) {
    backgroundImage = props.tabletFilename
  }

  if (props.desktopFilename && $device.value.isDesktop) {
    backgroundImage = props.desktopFilename
  }
  // Src will be given to us formatted already
  const { src } = $storyblok.getNormalizedAsset(backgroundImage, maxWidth)
  return src
})

const bgImageStyle = computed(() => {
  const styles: Record<string, string> = {}

  if (bgImageSrc.value && isLazyLoaded.value) {
    styles.backgroundImage = `url('${bgImageSrc.value}')`
  }

  return styles
})

// really just using it as a flag
const firstBgImageUrl = $storyblok.getFirstBgImageUrl()

if (import.meta.server && !shouldLazyLoad && bgImageSrc.value && !firstBgImageUrl) {
  $storyblok.setFirstBgImageUrl(bgImageSrc.value)
  useServerHead({ link: [{ rel: 'preload', as: 'image', href: bgImageSrc.value }] })
}

onMounted(() => {
  if (shouldLazyLoad && bgImageElement.value)
    observe(bgImageElement.value, () => {
      isLazyLoaded.value = true
    })
})
</script>
