export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled, isProduction } = useUtils()
  const { $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration stripe')
  if (!isIntegrationEnabled('stripe')) return

  // TODOLATER: Move to Storyblok
  const apiKey = isProduction() ? 'pk_live_XotCD0jxWE7pAhaCLmt5PC5l' : 'pk_test_7GYj8s6P8yatGMSQE00wTzMG'
  const script = runScriptOnDemand()

  let stripe: any

  script.addSrc(`https://js.stripe.com/v3/`)
  script.addCallback(() => {
    stripe = window.Stripe(apiKey)
  })

  function load() {
    return new Promise((resolve) => {
      script.addCallback(() => {
        resolve(stripe)
      })

      script.addErrorCallback(() => {
        resolve(null)
      })

      script.load()
    })
  }

  return {
    provide: {
      stripe: {
        load,
      },
    },
  }
})
