import { generateClasses } from '@formkit/themes'
import { check, circle, down } from '@formkit/icons'
import { defineFormKitConfig } from '@formkit/vue'
import { createProPlugin, mask, autocomplete } from '@formkit/pro'
import formkitTheme from './formkit-theme.js'

export default defineFormKitConfig(() => {
  const proPlugin = createProPlugin('fk-700dce0115', { mask, autocomplete })

  return {
    icons: {
      checkboxDecorator: check,
      radioDecorator: circle,
      select: down,
    },
    config: {
      classes: generateClasses(formkitTheme),
    },
    plugins: [proPlugin],
  }
})
