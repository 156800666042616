<template lang="pug">
div(class='absolute top-full')
  div(data-testid='treeMenu' class='flex flex-nowrap')
    template(v-if='data.noSideMenu')
      template(v-for='item in data.items')
        template(v-if='item.items')
          template(v-if='$storyblok.isVisible(item)')
            HeaderNavbarDesktopTreeItem(:key='item._uid' :data='item')
    template(v-else)
      //- Panel 1 - Side Bar Menu
      ul(class='bg-gray-darkest rounded-bl min-w-[240px]')
        template(v-for='(item, index) in data.items')
          template(v-if='$storyblok.isVisible(item)')
            //- Relative is required for triangle to work.
            li(
              :key='item._uid'
              data-testid='treeMenuRootItem'
              class='relative'
              v-bind='$storyblok.bindStoryblokBridgeData(item)'
              @touchstart.passive='showMenu(index)'
              @mouseenter='showMenu(index)'
              @mouseleave='clearTimer'
            )
              div(
                class='pl-3 pr-10 py-4 font-bold text-light triangle triangle-thick cursor-default hover:bg-white hover:text-black'
              ) {{ item.text }}

      //- Panel 2 - Revealed subpanels
      template(v-for='(item, index) in data.items')
        template(v-if='item.items')
          template(v-if='$storyblok.isVisible(item)')
            HeaderNavbarDesktopTreeItem(
              v-show='activeMenuIndex === index'
              :key='item._uid'
              :is-active='activeMenuIndex === index'
              :data='item'
            )
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

const props = defineProps<{
  data: TreeMenu
  isActive: boolean
}>()

const activeMenuIndex = ref(-1)
watch(
  () => props.isActive,
  (newVal) => {
    activeMenuIndex.value = newVal ? 0 : -1
  }
)

let navHoverTimer: any
function clearTimer() {
  // If they mouseleave before the showMenu timer is done, clear it so we don't show it
  clearTimeout(navHoverTimer)
}

function showMenu(menuIndex: number) {
  clearTimeout(navHoverTimer)

  navHoverTimer = setTimeout(() => {
    activeMenuIndex.value = menuIndex
  }, 175)
}
</script>
