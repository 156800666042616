export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute()

  const enableTiming = route.query.enableTiming

  const { ssrContext } = useNuxtApp()

  interface Timing {
    start: number
    end?: number
  }

  const timings: Record<string, Timing> = {}

  // This hook is only called on the server when SSR is done
  nuxtApp.hook('app:rendered', () => {
    Array.from(Object.entries(timings)).forEach(([key, value]) => {
      // If the timing is not ended, we don't want to add it to the header
      if (value.end === undefined) return

      // append the timing value to the header
      ssrContext?.event.node.res.appendHeader('Server-Timing', `${key};dur=${value.end - value.start}`)
    })
  })

  function start(name: string) {
    if (import.meta.client || !enableTiming) return

    timings[name] = {
      start: performance.now(),
    }
  }

  function end(name: string) {
    if (import.meta.client || !enableTiming || !timings[name]) return

    timings[name].end = performance.now()
  }

  return {
    provide: {
      timing: {
        start,
        end,
      },
    },
  }
})
