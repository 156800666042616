export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const { isProduction, isDev } = useUtils()
  // TODOLATER: It doesn't make sense this returns a domain in dev, but no domain in prod.  It should be consistent.
  // k - key, f - filename, h - height, w - width, m - method (s - scale/c - crop / or a color), q - quality
  function getImageUrl(
    key: string,
    filename: string,
    width?: number,
    height?: number,
    quality = 70,
    method = 'c',
    forceAssetBase = false
  ) {
    const assetBase = 'https://assets.thiecommerce.com'
    const base = forceAssetBase || isDev() ? assetBase : ''
    const mode = isProduction() ? 'production' : 'staging'

    // bad data
    if (!key || !filename) return undefined

    // return original
    if (!width || !height) return `${base}/${mode}/${key}/${filename}`

    // return thumbed
    return `${base}/${mode}/${key}/r/${width}x${height}/${method}/${quality}/${filename}`
  }

  function getApiUrl(key: ApiUrlKey) {
    const channelId = $sitewideConfig.channelId
    const domain = $sitewideConfig.domain
    const apis = getApiUrls(domain, channelId, isProduction())

    return apis[key]
  }

  return {
    getImageUrl,
    getApiUrl,
  }
}

function getApiUrls(domain: string, channelId: string, isProduction: boolean) {
  const apiSuffix = isProduction ? 'prod' : 'staging'
  const apiSuffixWithDash = isProduction ? '' : '-staging'

  return {
    brand: `/api/${domain}/brand/`,
    builds: `https://${apiSuffix}.uwp.thiecommerce.com/builds/`,
    cart: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/carts/`,
    catalog: `/api/${domain}/catalog/`,
    category: `/api/${domain}/category/`,
    checkout: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/checkouts/`,
    cms: `/api/${domain}/cms/`,
    compare: `/api/${domain}/compare/`,
    globalId: `https://uwp.thiecommerce.com/globalId-v1${apiSuffixWithDash}/globalId/`,
    installation: `https://${apiSuffix}.uwp.thiecommerce.com/installation/`,
    internationalSites: `/api/${domain}/international-sites/`,
    mmy: `/api/${domain}/fitment/`,
    orders: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/channels/${channelId}/orders/`,
    orderControls: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/channels/${channelId}/ordercontrols/`,
    orderLookup: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/orderlookup/`,
    pay: isProduction
      ? 'https://8qgj53pog3.execute-api.us-east-1.amazonaws.com/create-payment-intent'
      : 'https://wfelh1sqn3.execute-api.us-east-1.amazonaws.com/create-payment-intent',
    product: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/channels/${channelId}/productlines/`,
    receipt: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/receipts/`,
    recommendations: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/channels/${channelId}/recs/`,
    subscriptions: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/subscriptions/`,
    tracking: `https://uwp.thiecommerce.com/uwp-v3${apiSuffixWithDash}/channels/${channelId}/tracking/`,
    // This was used in Nuxt 2 as a way to update older apps. That was removed in Nuxt 3.
    version: `https://thiecommerce.com/${apiSuffix}/frontend/version/`,
  }
}

declare global {
  type ApiUrlKey = keyof ReturnType<typeof getApiUrls>
}
