<template lang="pug">
div(:class='[spacingClasses, { relative: $storyblok.isEditing.value }]')
  Subscribe(
    :form-name='data.formName'
    :placeholder-text='data.placeholderText'
    :button-color='data.buttonColor'
    :button-class='[fontColorClasses, { "md:px-12": data.buttonText }]'
  )
    template(v-if='data.buttonText') {{ data.buttonText }}

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
interface SubscribeInlineWidget extends Widget {
  buttonColor: ButtonColor
  buttonFontColor: string
  buttonText: string
  formName: string
  placeholderText: string
  mobileSpacing: GapStrings
  tabletSpacing: GapStrings
  desktopSpacing: GapStrings
}

const { data } = defineProps<{
  data: SubscribeInlineWidget
}>()

const spacingClasses = computed(() => {
  return generateGapClasses(data.mobileSpacing, data.tabletSpacing, data.desktopSpacing)
})

const fontColorClasses = computed(() => {
  return generateFontColorClass(data.buttonFontColor)
})
</script>
