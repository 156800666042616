type NotificationType = 'DEFAULT' | 'SUCCESS' | 'ERROR'

export interface Notification {
  id: number
  title: string
  message: string
  type: NotificationType
}

export default function () {
  const notifications = useState<Notification[]>('notifications', () => [])

  function addNotification(title: string, message: string, type: NotificationType) {
    // Try to find the new notification in the current notifications.
    const notificationIndex = notifications.value.findIndex(
      (notification) => title === notification.title && message === notification.message
    )

    // If notification already exists, don't add it again.
    if (notificationIndex > -1) return

    // Add new notification.
    notifications.value.push({
      id: Math.random(),
      title,
      message,
      type,
    })
  }

  function addInfo(title: string, message: string) {
    addNotification(title, message, 'DEFAULT')
  }

  function addSuccess(title: string, message: string) {
    addNotification(title, message, 'SUCCESS')
  }

  function addError(title: string, message: string) {
    addNotification(title, message, 'ERROR')
  }

  function removeNotification(id: number) {
    const index = notifications.value.findIndex((notification) => notification.id === id)
    // If id does not exist just return
    if (index === -1) return
    notifications.value.splice(index, 1)
  }

  return {
    notifications,
    addInfo,
    addSuccess,
    addError,
    removeNotification,
  }
}
