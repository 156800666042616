export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('wisepops')) return

  const { $sitewideConfig, $uiEvents, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration wisepops')
  const { onRouteChanged } = useRunOnRouteChanged()

  onRouteChanged(() => {
    if (typeof window.wisepops === 'function') window.wisepops('pageview')
  })

  runOnNuxtReady(() => {
    runAfterDelay(() => {
      /* eslint-disable */
      // prettier-ignore
      // @ts-ignore
      (function(W,i,s,e,P,o,p){W['WisePopsObject']=P;W[P]=W[P]||function(){ (W[P].q=W[P].q||[]).push(arguments)},W[P].l=1*new Date();o=i.createElement(s), p=i.getElementsByTagName(s)[0];o.async=1;o.src=e;p.parentNode.insertBefore(o,p) })(window,document,'script',`//loader.wisepops.com/get-loader.js?v=1&site=${$sitewideConfig.config.wisepopsSiteId}`,'wisepops');

      window.wisepops('listen', 'after-form-submit', function (event: any) {
        const email = event.target.elements['email']?.value || null
        const isValidEmail = /^.+\@.+\..+$/.test(email)

        if (!email || !isValidEmail) return

        // TODOLater: Chris Wall would like to Add a cognito call here to create their user id segment.generateUserId(identity.email)

        // Default to wisepops to catch if any are missing formName
        const formName = event.target.elements['formName']?.value || 'wisepops'
        $uiEvents.$emit('newsletterSubscribed', { formName, email })
      })
    }, 10000)
  })
})

declare global {
  interface Window {
    wisepops: any
  }
}
