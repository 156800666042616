<template lang="pug">
div(ref='amplitudeTest' :class='!$storyblok.isEditing.value ? "h-0" : ""')
  div(v-if='$storyblok.isEditing.value' class='bg-info p-2 text-white rounded') AB Test: {{ data.experimentKey }}
</template>

<script setup lang="ts">
const { $storyblok, $amplitude } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const { observe } = useRunOnVisible({ threshold: 0.8 })
const amplitudeTest = ref()

interface AbTestWidget extends Widget {
  experimentKey: string
}

const { data } = defineProps<{
  data: AbTestWidget
}>()

onMounted(async () => {
  if (!isIntegrationEnabled('amplitude')) return

  const variant = await $amplitude.getVariant(data.experimentKey)
  const actions = variant?.payload?.data
  if (actions) {
    actions.forEach((action: any) => {
      const devices = action.devices || []
      if (action.show) {
        $storyblok.show(action.show, ...devices)
      }
      if (action.hide) {
        $storyblok.hide(action.hide, ...devices)
      }
    })
  }

  if (!amplitudeTest.value) return
  observe(amplitudeTest.value, fireEvent, () => {})
})

function fireEvent() {
  if (!isIntegrationEnabled('amplitude')) return
  $amplitude.track(data.experimentKey)
}
</script>
