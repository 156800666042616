export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration paypal')
  const log = useLogger('PAYPAL')
  const { getApiUrl } = useUrls()

  const clientId = $sitewideConfig.config.paypalClientId

  const braintreeScript = runScriptOnDemand()
  braintreeScript.addSrc('https://js.braintreegateway.com/web/3.68.0/js/client.min.js')
  braintreeScript.addSrc('https://js.braintreegateway.com/web/3.68.0/js/paypal-checkout.min.js')

  const displayScript = runScriptOnDemand()
  displayScript.addSrc(
    `https://www.paypal.com/sdk/js?client-id=${clientId}&components=buttons,messages&disable-funding=card`
  )

  async function loadBraintree() {
    return new Promise<void>((resolve, reject) => {
      braintreeScript.addCallback(() => {
        resolve()
      })

      braintreeScript.addErrorCallback(() => {
        reject()
      })

      braintreeScript.load()
    })
  }

  async function loadDisplay() {
    return new Promise<void>((resolve, reject) => {
      displayScript.addCallback(() => {
        let count = 0
        const limit = 100

        // Check if paypal exists for 10 seconds
        const interval = setInterval(() => {
          count++
          if (window.paypal) {
            clearInterval(interval)
            resolve()
          } else if (count > limit) {
            clearInterval(interval)
            log.error('Paypal loading error')
            reject()
          }
        }, 100)
      })

      displayScript.addErrorCallback(() => {
        reject()
      })

      displayScript.load()
    })
  }

  async function initBraintree() {
    const braintreeToken = await getBraintreeToken()
    if (!window.braintree) await loadBraintree()
    const braintreeClient = await window.braintree.client.create({ authorization: braintreeToken })
    return await window.braintree.paypalCheckout.create({ client: braintreeClient })
  }

  interface BraintreeTokenClient {
    clientToken: string
  }
  async function getBraintreeToken() {
    // This token is only good for 24 hours so we need a fresh one every time.
    const url = getApiUrl('checkout') + 'braintreeclient'
    const { clientToken } = await $fetch<BraintreeTokenClient>(url, {
      method: 'GET',
      headers: {
        'x-site': $sitewideConfig.sitePrefix,
      },
    })

    return clientToken
  }

  return {
    provide: {
      paypal: {
        braintree: {
          init: initBraintree,
        },
        display: {
          load: loadDisplay,
        },
      },
    },
  }
})
