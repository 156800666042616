export default function () {
  const { $sitewideConfig } = useNuxtApp()

  const header = ref<HTMLElement | null>(null)
  const headerNav = ref<HTMLElement | null>(null)

  onMounted(() => {
    header.value = document.getElementById('stickyHeader')
    headerNav.value = document.getElementById('stickyNavbar')
  })

  const headerOffset = computed(() => {
    if (!$sitewideConfig.header?.stickyEnabled) return 0

    if (header.value && headerNav.value && !$sitewideConfig.navbar.value?.content?.hideOnSticky)
      return header.value.clientHeight + headerNav.value.clientHeight

    return header.value ? header.value.clientHeight : 0
  })

  return {
    headerOffset,
  }
}
