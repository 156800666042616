export default function wireDealerLocatorListeners() {
  const segment = useSegmentHelper()
  const { $uiEvents } = useNuxtApp()
  const { formatProductData } = useSegmentProductsHelper()
  $uiEvents.$on('dealerLocatorProductDealerClicked', emitDealerProductDealerLinkEvent)
  $uiEvents.$on('dealerLocatorProductLinkClicked', emitDealerProductLinkEvent)
  $uiEvents.$on('dealerLocatorProductViewed', emitDealerProductViewedEvent)

  $uiEvents.$on('dealerLocatorDealerViewed', emitDealerViewedEvent)
  $uiEvents.$on('dealerLocatorPhoneClicked', emitDealerPhoneEvent)
  $uiEvents.$on('dealerLocatorWebsiteClicked', emitDealerWebsiteEvent)
  $uiEvents.$on('dealerLocatorDirectionsClicked', emitDealerDirectionsEvent)

  function emitDealerProductDealerLinkEvent(event: any) {
    const { product, dealer, linkText, clickUrl } = event

    const { name: dealerName, id, zipcode, country, isInternational, isRealPro, isPreferred, isDoubleWarranty } = dealer

    segment.track('DEALER_LOCATOR_PDP_DEALER_CLICK', {
      clickType: 'link',
      clickUrl,
      event: 'clicked',
      eventText: 'Clicked',
      linkText,
      dealerName,
      id,
      zipcode,
      country,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
      ...formatProductData(product),
    })
  }

  function emitDealerProductLinkEvent(event: any) {
    const { product, linkText, clickUrl } = event

    segment.track('DEALER_LOCATOR_CLICK', {
      clickType: 'link',
      clickUrl,
      event: 'clicked',
      eventText: 'Clicked',
      linkText,
      ...formatProductData(product),
    })
  }

  function emitDealerProductViewedEvent(event: any) {
    const { product } = event
    if (!product) return

    segment.track('DEALER_LOCATOR_PDP_VIEWED', {
      ...formatProductData(product),
    })
  }

  function emitDealerViewedEvent(event: any) {
    // elementType is 'map' or 'button'
    const { dealer, elementType } = event
    if (!dealer || !elementType) return

    const { name: dealerName, id, zipcode, country, isInternational, isRealPro, isPreferred, isDoubleWarranty } = dealer

    segment.track('DEALER_LISTING_VIEW', {
      dealerName,
      id,
      zipcode,
      country,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
      elementType,
    })
  }

  function emitDealerPhoneEvent(event: any) {
    const { dealer } = event
    if (!dealer) return

    const {
      name: dealerName,
      id,
      zipcode,
      country,
      phone,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
    } = dealer

    segment.track('DEALER_PHONE_CALL', {
      dealerName,
      id,
      zipcode,
      country,
      phone,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
    })
  }

  function emitDealerWebsiteEvent(event: any) {
    const { dealer } = event
    if (!dealer) return

    const {
      name: dealerName,
      id,
      zipcode,
      country,
      website: websiteUrl,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
    } = dealer

    segment.track('DEALER_WEBSITE_VISIT', {
      dealerName,
      id,
      zipcode,
      country,
      websiteUrl,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
    })
  }

  function emitDealerDirectionsEvent(event: any) {
    const { dealer } = event
    if (!dealer) return

    const {
      name: dealerName,
      id,
      zipcode,
      country,
      phone,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
    } = dealer

    segment.track('DEALER_DIRECTIONS_CLICK', {
      dealerName,
      id,
      zipcode,
      country,
      phone,
      isInternational,
      isRealPro,
      isPreferred,
      isDoubleWarranty,
    })
  }
}
