<template lang="pug">
FormKit(type='form' :actions='actions' :incomplete-message='false' :submit-label='submitLabel' @submit='submitHandler')
  template(#default='slotProps')
    slot(v-bind='slotProps')
</template>

<script setup lang="ts">
const emit = defineEmits(['submit'])

/*
  ===
  FormKit Properties
  ===
  actions: Boolean
    Whether or not to include the actions bar at the bottom of the form (ex. you want to remove
    the submit button and use your own, set this to false).
  ---
  submit-label: String
    The label to use on the built-in submit button.
  ---
  incompelete-message: Boolean/String
    The message that is shown to near the submit button when a user attempts to submit a form,
    but not all inputs are valid.
  ---
*/

interface Props {
  actions?: boolean
  submitLabel?: string
}

withDefaults(defineProps<Props>(), {
  submitLabel: '',
})

function submitHandler(formData: any, form: any) {
  emit('submit', formData, form)
}
</script>
