<template lang="pug">
footer(v-if='footerData')
  //- Tool bar for the storyblok editor
  div(v-if='$storyblok.isEditing.value && isFooterStory' class='border-b mt-4 mb-4 space-x-2 uppercase font-bold relative z-[61]')
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": !isSimpleToggle, "bg-white": isSimpleToggle }'
      @click='setIsSimple(false)'
    ) Standard
    div(
      class='inline-block py-2 px-4 border border-b-0 rounded-t cursor-pointer'
      :class='{ "text-white bg-info": isSimpleToggle, "bg-white": !isSimpleToggle }'
      @click='setIsSimple(true)'
    ) Simple

  CmsStoryblok(v-if='isSimpleMode' :sections='footerData.footerSimple')
  CmsStoryblok(v-else :sections='footerData.footerStandard')
</template>

<script setup lang="ts">
const { isSimple = false } = defineProps<{ isSimple?: boolean }>()
const { $sitewideConfig, $storyblok } = useNuxtApp()
const isSimpleToggle = ref(false)

const { data: previewData } = await useAsyncData('footer-data', async () => {
  if (!$storyblok.isPreview.value || $storyblok.contentType.value !== 'footer') return null

  const preview = await $storyblok.getPreviewStory<FooterStory>()
  return preview?.story.content ?? null
})

const footerData = computed(() => {
  return previewData.value || $sitewideConfig.footer
})

const setIsSimple = (bool: boolean) => {
  isSimpleToggle.value = bool
}

const isSimpleMode = computed(() => {
  if ($storyblok.isEditing.value) return isSimpleToggle.value
  return isSimple
})

const isFooterStory = computed(() => {
  return $storyblok.contentType.value === 'footer'
})

onMounted(async () => {
  // Add a listener to update the content when in editor mode
  $storyblok.addListener((story) => {
    if ($storyblok.isFooterStory(story)) previewData.value = story.story.content
  })
})
</script>
