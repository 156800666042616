import type { RouteLocationNormalized } from '#vue-router'

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const route = useRoute()
  const { $sitewideConfig } = useNuxtApp()
  const recommendationsStore = useRecommendationsStore()

  onNuxtReady(() => {
    recommendationsStore.getRecommendations(getOptions(route))
  })

  // TODOLATER: This is refeshed on every page nav, even where it's not even shown (like CMS / dealer-locator / etc..)
  router.afterEach((to, from) => {
    if (from.name && to.path !== from.path) recommendationsStore.getRecommendations(getOptions(to))
  })

  function getOptions(route: RouteLocationNormalized) {
    /**
     * Valid Recommendation types
     * recent
     * similar
     * related
     * recommended
     */
    // 'recent' should be on every rec call.
    const options = {
      types: ['recent'],
      productLineSlug: '',
    }
    // 'recommended' should be on every rec call if its enabled.
    if ($sitewideConfig.config.recommendedProductsEnabled) options.types.push('recommended')

    if (['product-mmy', 'product', 'sku'].includes(route.name as string)) {
      options.types.push('similar', 'related')
      options.productLineSlug = route.params.productLine as string
    }

    return options
  }
})
