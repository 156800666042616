<template lang="pug">
div(data-testid='treeItem' class='flex' :class='level === 1 ? "bg-gray-lighter" : "bg-gray-light"')
  div(class='p-5 text-gray-darkest leading-tight')
    UniversalLink(:link='data.link.url' :data-level='level' class='block mb-5 font-bold text-black whitespace-nowrap') {{ data.text }}

    div(class='flex')
      template(v-for='linkGroup in linkGroups' :key='linkGroup._uid')
        div(class='ml-5 pl-5 border-l-2 border-gray-light first:ml-0 first:pl-0 first:border-0')
          template(v-for='item in linkGroup')
            template(v-if='$storyblok.isVisible(item)')
              //- Relative is required for triangle to work.
              div(:key='item._uid' v-bind='$storyblok.bindStoryblokBridgeData(item)' class='relative w-48')
                UniversalLink(
                  :link='item.link.url'
                  class='block mb-3 pr-5 text-sm text-black'
                  :class='{ "triangle triangle-small": item.items }'
                  :data-level='level'
                  @touchstart.passive='showMenu(item._uid)'
                  @mouseenter='showMenu(item._uid)'
                  @mouseleave='clearTimer'
                ) {{ item.text }}

  //- Recursively build further child panels
  template(v-if='data.items')
    template(v-for='item in data.items')
      template(v-if='item.items')
        template(v-if='$storyblok.isVisible(item)')
          TreeItem(v-show='activeMenuId === item._uid' :key='item._uid' :data='item' :level='level + 1')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
// interface TreeItem extends NavbarItem {
//   items: TreeItem[]
// }
const props = withDefaults(
  defineProps<{
    data: TreeMenuItem
    level?: number
    isActive?: boolean
  }>(),
  {
    level: 1,
    isActive: false,
  }
)

const activeMenuId = ref()
watch(
  () => props.isActive,
  (newVal) => {
    if (!newVal) activeMenuId.value = null
  }
)

const linkGroups = computed(() => {
  if (!props.data.items) return []

  const groups = []
  const chunkSize = 14
  for (let i = 0; i < props.data.items.length; i += chunkSize) {
    const chunk = props.data.items.slice(i, i + chunkSize)
    groups.push(chunk)
  }

  return groups
})

let navHoverTimer: any
function clearTimer() {
  // If they mouseleave before the showMenu timer is done, clear it so we don't show it
  clearTimeout(navHoverTimer)
}

function showMenu(menuIndex: string) {
  clearTimeout(navHoverTimer)

  navHoverTimer = setTimeout(() => {
    activeMenuId.value = menuIndex
  }, 175)
}
</script>
