<template lang="pug">
div(class='flex' :class='alignmentClasses')
  UniversalLink(:link='data.linkUrl' :open-new-window='data.linkTarget === "NEW_WINDOW"')
    PictureWrapper(v-bind='pictureBindings')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

interface SvgWidget extends Widget, WidgetMaxWidth, WidgetAlignment {
  component: 'svg-widget'
  alt: string
  linkUrl: string
  linkTarget: string
  mobileImage: StoryblokAsset
  tabletImage: StoryblokAsset
  desktopImage: StoryblokAsset
  isInLightbox: 'false' | 'true'

  // Legacy Props
  mobileSvgSize?: {
    width: number
    height: number
  }
  tabletSvgSize?: {
    width: number
    height: number
  }
  desktopSvgSize?: {
    width: number
    height: number
  }
}

const props = defineProps<{
  data: SvgWidget
  spaceLeft: SpaceLeft
  forceLazyLoad?: boolean
  isImportant?: boolean
}>()

const isLazy = $storyblok.shouldLazyLoad(props.forceLazyLoad)
const pictureBindings = computed(() => {
  // getSvgData() is Deprecated but remains for backward compatibility
  const imageData = getSvgData()

  return {
    isLazy,
    isImportant: props.isImportant,
    ...imageData,
  }
})

const maxWidth = computed(() => {
  return getMaxWidth(props.data.mobileMaxWidth, props.data.tabletMaxWidth, props.data.desktopMaxWidth)
})

const alignmentClasses = computed(() => {
  return generateFlexAlignmentClasses(
    props.data.mobileAlignment,
    props.data.tabletAlignment,
    props.data.desktopAlignment
  )
})

interface svgData {
  alt: string
  mobileSrc: string
  mobileWidth?: number
  mobileHeight?: number
  tabletSrc: string
  tabletWidth?: number
  tabletHeight?: number
  desktopSrc: string
  desktopWidth?: number
  desktopHeight?: number
}

function getSvgData(): svgData | {} {
  const svgs: svgData = {
    alt: '',
    mobileSrc: '',
    mobileWidth: 0,
    mobileHeight: 0,
    tabletSrc: '',
    tabletWidth: 0,
    tabletHeight: 0,
    desktopSrc: '',
    desktopWidth: 0,
    desktopHeight: 0,
  }

  // set the source/dimensions property for each image size
  if (props.data.mobileImage.filename) {
    const src = $storyblok.formatSrc(props.data.mobileImage.filename)
    const { width, height } = getAssetDimensionsFromUrl(props.data.mobileImage.filename)

    svgs.alt = props.data.mobileImage.alt
    svgs.mobileSrc = src
    svgs.mobileWidth = width
    svgs.mobileHeight = height

    svgs.tabletSrc = src
    svgs.tabletWidth = width
    svgs.tabletHeight = height

    svgs.desktopSrc = src
    svgs.desktopWidth = width
    svgs.desktopHeight = height
  }

  if (props.data.tabletImage.filename) {
    const src = $storyblok.formatSrc(props.data.tabletImage.filename)
    const { width, height } = getAssetDimensionsFromUrl(props.data.tabletImage.filename)

    svgs.alt = svgs.alt || props.data.tabletImage.alt
    svgs.tabletSrc = src
    svgs.tabletWidth = width
    svgs.tabletHeight = height

    svgs.desktopSrc = src
    svgs.desktopWidth = width
    svgs.desktopHeight = height
  }

  if (props.data.desktopImage.filename) {
    const src = $storyblok.formatSrc(props.data.desktopImage.filename)
    const { width, height } = getAssetDimensionsFromUrl(props.data.desktopImage.filename)

    svgs.alt = svgs.alt || props.data.desktopImage.alt
    svgs.desktopSrc = src
    svgs.desktopWidth = width
    svgs.desktopHeight = height
  }

  // handle legacy svgSize, for those the h/w at this point would likely be null
  if (props.data.mobileSvgSize?.width) {
    const { width, height } = props.data.mobileSvgSize

    svgs.mobileWidth = width
    svgs.mobileHeight = height

    svgs.tabletWidth = width
    svgs.tabletHeight = height

    svgs.desktopWidth = width
    svgs.desktopHeight = height
  }

  if (props.data.tabletSvgSize?.width) {
    const { width, height } = props.data.tabletSvgSize

    svgs.tabletWidth = width
    svgs.tabletHeight = height

    svgs.desktopWidth = width
    svgs.desktopHeight = height
  }

  if (props.data.desktopSvgSize?.width) {
    const { width, height } = props.data.desktopSvgSize

    svgs.desktopWidth = width
    svgs.desktopHeight = height
  }

  // TODOLATER: Fix typing issues or re-code this in a more typescript friendly way...
  // apply any max widths, with svgs maxwidth sets the dimensions so it's not really max width like the image widget
  type deviceNames = 'mobile' | 'tablet' | 'desktop'
  for (const device in maxWidth.value) {
    const svgWidth = (svgs[`${device}Width` as keyof svgData] as number) || 0
    const svgHeight = (svgs[`${device}Height` as keyof svgData] as number) || 0
    const max = maxWidth.value[device as deviceNames]
    if (!svgWidth || !svgHeight) break

    const { width, height } = getResizedDimensions(svgWidth, svgHeight, max)
    if (width) (svgs[`${device}Width` as keyof svgData] as number) = width
    if (height) (svgs[`${device}Height` as keyof svgData] as number) = height
  }

  return svgs
}

function getResizedDimensions(width: number, height: number, maxWidth: number) {
  if (maxWidth === null) return { width, height }

  const ratio = maxWidth / width
  return {
    width: Math.floor(width * ratio),
    height: Math.floor(height * ratio),
  }
}
</script>
