<template lang="pug">
div(v-bind='sectionAttributes' class='relative' tag='section')
  div(:class='contentClasses')
    template(v-if='data.widgets')
      template(v-for='widget in data.widgets')
        slot(:data='widget' :space-left='spaceLeft')

  div(v-if='props.data.backgroundColor' :class='backgroundColorClasses' class='z-[-1] absolute inset-0 w-full h-full')

  CmsBackgroundImage(
    v-if='hasBackgroundImage'
    :mobile-filename='data.mobileBackgroundImage?.filename'
    :tablet-filename='data.tabletBackgroundImage?.filename'
    :desktop-filename='data.desktopBackgroundImage?.filename'
    :space-left='spaceLeft'
    class='z-[-1] absolute inset-0 w-full h-full'
    :class='backgroundClasses'
  )

  div(v-if='backgroundVideo.filename' class='z-[-1] absolute inset-0 w-full h-full')
    VideoLazyPlayer(
      :is-lazy='$storyblok.shouldLazyLoad(false)'
      :video-url='backgroundVideo.filename'
      :poster-image-url='backgroundVideo.poster'
      class='absolute inset-0 object-cover h-full'
      :class='backgroundClasses'
      width='100%'
      height='100%'
    )

  div(v-if='data.shadowOpacity > 0' class='z-[-1] absolute inset-0 bg-black' :class='backgroundClasses' :style='shadowStyles')
</template>

<script setup lang="ts">
const { $device, $storyblok } = useNuxtApp()

const { showLightbox } = usePhotoswipeLightbox()
const lightboxImages: PhotoswipeItem[] = []

provide($storyblok.sectionLightboxProvide, {
  add: (src, width, height, caption) => {
    return lightboxImages.push({ src, width, height, caption }) - 1
  },
  open: (index: number) => {
    if ($storyblok.isEditing.value) return
    showLightbox(lightboxImages, index)
  },
})

interface SectionWidget extends Widgets, WidgetMargin, WidgetMinHeight {
  isFullWidth: StringBoolean
  backgroundIsFullWidth: boolean
  backgroundColorIsFullWidth: StringBoolean
  backgroundColor: BackgroundColor
  textColor: string
  verticalAlignment: VerticalStrings

  shadowOpacity: number
  shadowTopPosition: number
  shadowBottomPosition: number

  mobileBackgroundImage: { filename: string }
  tabletBackgroundImage: { filename: string }
  desktopBackgroundImage: { filename: string }

  mobileBackgroundVideo: { filename: string }
  tabletBackgroundVideo: { filename: string }
  desktopBackgroundVideo: { filename: string }

  mobileBackgroundPoster: { filename: string }
  tabletBackgroundPoster: { filename: string }
  desktopBackgroundPoster: { filename: string }
}

const props = defineProps<{
  data: SectionWidget
}>()

const hasBackgroundImage = computed(() => {
  return !!(
    props.data.mobileBackgroundImage.filename ||
    props.data.tabletBackgroundImage.filename ||
    props.data.desktopBackgroundImage.filename
  )
})

const spaceLeft = computed(() => {
  return {
    mobile: 768,
    tablet: 1024,
    desktop: props.data.isFullWidth === 'true' ? 1920 : 1280,
  }
})

const backgroundColorClasses = computed(() => {
  const classes = []
  // Explicitly checking for backgroundIsFullWidth = false here to support backwards compatibility
  // where this will be undefined
  if (
    props.data.isFullWidth !== 'true' &&
    (props.data.backgroundIsFullWidth === false || props.data.backgroundColorIsFullWidth === 'false')
  )
    classes.push('lg:container')

  classes.push(generateBackgroundColorClass(props.data.backgroundColor))

  return classes
})

const contentClasses = computed(() => {
  const classes: Record<string, any> = [
    // Padding Classes
    generatePaddingClasses(props.data.mobilePadding, props.data.tabletPadding, props.data.desktopPadding),
    {
      'lg:container': props.data.isFullWidth !== 'true', // Is the content fullwidth?
    },
  ]

  return classes
})

const sectionAttributes = computed(() => {
  const attributes: Record<string, any> = {
    class: sectionClasses.value,
    style: {
      minHeight: sectionMinHeight.value,
    },
  }
  return attributes
})

const sectionClasses = computed(() => {
  const classes = [
    // Margin Classes
    generateMarginClasses(props.data.mobileMargin, props.data.tabletMargin, props.data.desktopMargin),
    // Font color class to apply to the whole section.
    generateFontColorClass(props.data.textColor),
  ]

  if (props.data.verticalAlignment) {
    classes.push('flex flex-col')

    switch (props.data.verticalAlignment) {
      case 'TOP':
        classes.push('justify-start')
        break

      case 'MIDDLE':
        classes.push('justify-center')
        break

      case 'BOTTOM':
        classes.push('justify-end')
        break
    }
  }

  return classes
})

const sectionMinHeight = computed(() => {
  const minHeight = getMinHeight(
    props.data.mobileMinHeight,
    props.data.tabletMinHeight,
    props.data.desktopMinHeight,
    $device
  )

  switch (minHeight) {
    case 'SMALL':
      return $device.value.isMobile ? '250px' : '350px'
    case 'MEDIUM':
      return $device.value.isMobile ? '350px' : '450px'
    case 'LARGE':
      return $device.value.isMobile ? '450px' : '550px'
    default:
      return null
  }
})

const backgroundClasses = computed(() => {
  // Explicitly checking for backgroundIsFullWidth = false here to support backwards compatibility
  // where this will be undefined
  if (props.data.isFullWidth !== 'true' && props.data.backgroundIsFullWidth === false) return 'lg:container'
})

const backgroundVideo = computed(() => {
  const backgroundVideo = {
    filename: '',
    poster: '',
  }

  if (props.data.mobileBackgroundVideo?.filename) {
    backgroundVideo.filename = props.data.mobileBackgroundVideo.filename
    if (props.data.mobileBackgroundPoster?.filename) backgroundVideo.poster = props.data.mobileBackgroundPoster.filename
  }

  if (props.data.tabletBackgroundVideo?.filename && !$device.value.isMobile) {
    backgroundVideo.filename = props.data.tabletBackgroundVideo.filename
    if (props.data.tabletBackgroundPoster?.filename) backgroundVideo.poster = props.data.tabletBackgroundPoster.filename
  }

  if (props.data.desktopBackgroundVideo?.filename && $device.value.isDesktop) {
    backgroundVideo.filename = props.data.desktopBackgroundVideo.filename
    if (props.data.desktopBackgroundPoster?.filename)
      backgroundVideo.poster = props.data.desktopBackgroundPoster.filename
  }

  // Format to use the CDN
  backgroundVideo.filename = $storyblok.formatSrc(backgroundVideo.filename)

  // Reduce poster image quality to 50% to improve LCP
  if (backgroundVideo.poster)
    backgroundVideo.poster = $storyblok.formatSrc(backgroundVideo.poster) + '/m/filters:quality(50)'

  return backgroundVideo
})

const shadowStyles = computed(() => {
  const classes = []
  const opacity = props.data.shadowOpacity
  const topPos = props.data.shadowTopPosition
  const bottomPos = props.data.shadowBottomPosition

  // Storyblok can clamp values now so we do not have to do it in code anymore

  if (opacity) {
    classes.push(`opacity: ${opacity * 0.01}`)
  }

  if (topPos) {
    classes.push(`top: ${topPos}%`)
  }

  if (bottomPos) {
    classes.push(`bottom: ${100 - bottomPos}%`)
  }

  return classes.join(';')
})
</script>
