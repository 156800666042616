<template lang="pug">
div(
  v-bind='rootOptions'
  @click='captureLinkClick'
  @touchstart.passive='showMenu'
  @mouseenter='showMenu'
  @mouseleave='closeMainMenu'
)
  //- Category
  slot(:is-menu-open='isMenuOpen')

  //- Tree or text
  client-only
    template(v-if='componentData.component')
      transition(name='fade' @after-leave='isMenuActive = false')
        //- isMenuOpen Controls Show/Hide of the total menu
        //- isMenuActive Delays the hiding of children until after Fade Animation
        component(:is='componentData.component' v-show='isMenuOpen' :data='data' :is-active='isMenuActive')
</template>

<script setup lang="ts">
import { HeaderNavbarDesktopLinkMenu, HeaderNavbarDesktopTreeMenu, HeaderNavbarDesktopCmsMenu } from '#components'

const { $storyblok, $uiEvents } = useNuxtApp()
const getUniversalLinkTarget: any = inject('getUniversalLinkTarget')

interface NavItem extends Widget {
  text: string
}

const props = defineProps<{
  data: NavItem
}>()

const isMenuActive = ref(false)
const isMenuOpen = ref(false)

const rootOptions = computed(() => {
  return $storyblok.bindStoryblokBridgeData(props.data)
})

const componentData = computed(() => {
  const widgetOptions: Record<string, any> = {
    component: null,
  }

  switch (props.data.component) {
    case 'link-menu':
      widgetOptions.component = HeaderNavbarDesktopLinkMenu
      break
    case 'tree-menu':
      widgetOptions.component = HeaderNavbarDesktopTreeMenu
      break
    case 'cms-menu':
      widgetOptions.component = HeaderNavbarDesktopCmsMenu
      break
    default:
      break
  }

  return widgetOptions
})

function captureLinkClick(event: any) {
  if ($storyblok.isEditing.value && $storyblok.contentType.value === 'navbar') return

  // Make sure this event is a Universal Link
  const target = getUniversalLinkTarget(event)
  if (!target) return

  // We know its a universal link, but it may not have an href
  // Ex: ACCOUNT triggers
  const link = target.href ? { link: target.href } : { text: target.innerText }

  // Get the level from the dataset
  const { level } = target.dataset

  // Emit the event
  $uiEvents.$emit('navigationClicked', {
    ...link,
    ...(level && { level }),
    ...(level && { level }),
    ...(props.data.text && { rootText: props.data.text }),
  })

  // CLOSE THE MENU
  closeMainMenu()
}

let navHoverTimer: any
function closeMainMenu() {
  clearTimeout(navHoverTimer)

  isMenuOpen.value = false
}

function showMenu() {
  clearTimeout(navHoverTimer)

  navHoverTimer = setTimeout(() => {
    isMenuActive.value = true
    isMenuOpen.value = true
  }, 175)
}
</script>
