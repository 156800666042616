export default function wireCheckoutListeners() {
  const segment = useSegmentHelper()
  const { getCartProducts } = useSegmentProductsHelper()
  const { $uiEvents, $geolocation } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()
  const checkoutStore = useCheckoutStore()
  const cartStore = useCartStore()
  const rootStore = useRootStore()

  // Iterable Cookie Data
  const iterableTemplateId = useCookie('iterableTemplateId').value
  const iterableEmailCampaignId = useCookie('iterableEmailCampaignId').value
  const iterableMessageId = useCookie('iterableMessageId').value
  const iterableEndUserId = useCookie('iterableEndUserId').value

  $uiEvents.$on('checkoutStarted', emitCheckoutStarted)
  $uiEvents.$on('productCheckoutStarted', emitProductCheckoutStarted)
  $uiEvents.$on('orderPlaced', emitOrderPlacedIdentify)
  $uiEvents.$on('orderPlaced', emitOrderCompleted)
  $uiEvents.$on('orderPlaced', emitAttribution)

  // THIPAY - Phone Payment Placed
  $uiEvents.$on('phonePaymentPlaced', emitPhonePaymentPlaced)

  // Warranty Shipping Protection
  $uiEvents.$on('addShippingProtection', (isAutoAdded) =>
    segment.track('Add Shipping Protection', { cart_id: rootStore.permId, is_auto_added: isAutoAdded })
  )
  $uiEvents.$on('removeShippingProtection', () =>
    segment.track('Remove Shipping Protection', { cart_id: rootStore.permId })
  )

  // Gift Cards
  $uiEvents.$on('giftCardAdded', () => segment.track('Gift Card Added', {}))
  $uiEvents.$on('giftCardDenied', () => segment.track('Gift Card Denied', {}))
  $uiEvents.$on('giftCardRemoved', () => segment.track('Gift Card Removed', {}))

  // Promo Codes
  $uiEvents.$on('promoCodeEntered', (code) =>
    segment.track('Coupon Entered', {
      cart_id: rootStore.permId,
      coupon_id: code,
    })
  )
  $uiEvents.$on('promoCodeApplied', (code) =>
    segment.track('Coupon Applied', {
      cart_id: rootStore.permId,
      coupon_id: code,
    })
  )
  $uiEvents.$on('promoCodeDenied', (code) =>
    segment.track('Coupon Denied', {
      cart_id: rootStore.permId,
      coupon_id: code,
    })
  )
  $uiEvents.$on('promoCodeRemoved', (code) =>
    segment.track('Coupon Removed', {
      cart_id: rootStore.permId,
      coupon_id: code,
    })
  )

  // CART Viewed
  $uiEvents.$on('cartViewed', () =>
    segment.track('Cart Viewed', {
      cart_id: rootStore.permId,
      ship_protection: cartStore.cart.hasShippingProtection,
      products: getCartProducts(cartStore.cart.items),
    })
  )

  // CHECKOUT STEPS - Realtruck Flow
  $uiEvents.$on('otherAddressFormViewed', () => emitCheckoutStepOneViewed('Other'))
  $uiEvents.$on('otherAddressFormCompleted', () => emitCheckoutStepOneCompleted('Other'))
  $uiEvents.$on('otherPaymentFormViewed', () => emitCheckoutStepTwoViewed('Other'))
  $uiEvents.$on('otherPaymentFormCompleted', () => emitCheckoutStepTwoCompleted('Other'))

  // CHECKOUT STEPS - Stripe
  // Existing event for old cart and checkout stores
  $uiEvents.$on('stripePaymentInfoEntered', () => emitPaymentInfoEnteredOld('Stripe', 2))

  // CHECKOUT STEPS - Affirm
  $uiEvents.$on('affirmAddressFormViewed', () => emitCheckoutStepOneViewed('Affirm'))
  $uiEvents.$on('affirmAddressFormCompleted', () => emitCheckoutStepOneCompleted('Affirm'))
  $uiEvents.$on('affirmPaymentModalViewed', () => emitCheckoutStepTwoViewed('Affirm'))
  $uiEvents.$on('affirmPaymentModalCompleted', () => emitCheckoutStepTwoCompleted('Affirm'))
  $uiEvents.$on('affirmPaymentInfoEntered', () => emitPaymentInfoEnteredOld('Affirm', 2))

  // CHECKOUT STEPS - Paylpal
  $uiEvents.$on('paypalPaymentModalViewed', () => emitCheckoutStepOneViewed('Paypal'))
  $uiEvents.$on('paypalPaymentModalCompleted', () => emitCheckoutStepOneCompleted('Paypal'))
  $uiEvents.$on('paypalPaymentInfoEntered', () => emitPaymentInfoEnteredOld('Paypal', 1))

  // New events for the new cart composable
  $uiEvents.$on('checkoutStepViewed', emitCheckoutStepViewed)
  $uiEvents.$on('checkoutStepCompleted', emitCheckoutStepCompleted)
  $uiEvents.$on('paymentInfoEntered', emitPaymentInfoEntered)

  interface CheckoutStepEvent {
    type: string
    step: number
    cart: CartInstance
  }

  function emitCheckoutStep(event: string, data: CheckoutStepEvent) {
    segment.track(event, {
      checkout_id: rootStore.permId, // Note: This is the same as the cart_id aka the perm Id
      step: data.step,
      payment_method: data.type,
      products: getCartProducts(data.cart.state.value.items),
      shipping_tier: 'default',
    })
  }

  function emitCheckoutStepViewed(data: CheckoutStepEvent) {
    emitCheckoutStep('Checkout Step Viewed', data)
  }

  function emitCheckoutStepCompleted(data: CheckoutStepEvent) {
    emitCheckoutStep('Checkout Step Completed', data)
  }

  function emitPaymentInfoEntered(data: CheckoutStepEvent) {
    emitCheckoutStep('Payment Info Entered', data)
  }

  // STEPS
  // Paypal -> New Page -> Order Placed
  // Affirm -> Address -> Modal -> Order Placed
  const getStepsObj = (paymentMethod: any, step: number) => {
    return {
      checkout_id: checkoutStore.checkout.id, // Note: This is the same as the cart_id aka the perm Id
      step,
      payment_method: paymentMethod,
      products: getCartProducts(cartStore.cart.items),
      shipping_tier: 'default',
    }
  }

  function emitCheckoutStepOneViewed(paymentMethod: any) {
    segment.track('Checkout Step Viewed', getStepsObj(paymentMethod, 1))
  }

  function emitCheckoutStepOneCompleted(paymentMethod: any) {
    segment.track('Checkout Step Completed', getStepsObj(paymentMethod, 1))
  }

  function emitCheckoutStepTwoViewed(paymentMethod: any) {
    segment.track('Checkout Step Viewed', getStepsObj(paymentMethod, 2))
  }

  function emitCheckoutStepTwoCompleted(paymentMethod: any) {
    segment.track('Checkout Step Completed', getStepsObj(paymentMethod, 2))
  }

  function emitPaymentInfoEnteredOld(paymentMethod: any, step: number) {
    segment.track('Payment Info Entered', getStepsObj(paymentMethod, step))
  }

  function emitPhonePaymentPlaced(data: any) {
    // We do not know their email currently but segment can match by Phone
    const identity = {
      phone: data.shippingAddress?.phone,
      address: data.shippingAddress,
    }
    segment.emitIdentify(identity)

    segment.track('Phone Order Completed', {
      sales_channel_name: data.salesChannelName,
      sales_rep_first_name: data.salesRepFirstName,
      order_number: data.orderNumber,
      total: data.orderTotal / 100,
      subtotal: data.orderSubtotal / 100,
      amount_paid: data.amountPaid / 100,
      amount_remaining: data.amountRemaining / 100,
      shipping: data.orderShippingCost / 100,
      tax: data.orderTax / 100,
      shippingAddress: data.shippingAddress,
      payment_complete: data.paymentComplete,
      currency: 'USD',
    })
  }

  // We need to support the old way and the new cart. Will eventually update this
  function emitCheckoutStarted(data: string | { type: string; cart: CartInstance }) {
    const id = rootStore.permId
    let items = cartStore.cart.items
    let summary = checkoutStore.checkout.summary
    let type = ''

    if (typeof data === 'string') {
      type = data
    } else {
      items = data.cart.state.value.items
      summary = data.cart.state.value.summary
      type = data.type
    }

    segment.track('Payment Type Selected', {
      checkout_id: id,
      payment_method: type,
    })

    segment.track('Checkout Started', {
      value: summary.grandTotal / 100, // Revenue ($) with discounts and coupons added in.
      revenue: summary.cartSubtotal / 100, // Revenue ($) associated with the transaction (excluding shipping and tax)
      shipping: summary.shippingSubtotal,
      tax: summary.taxSubtotal,
      // discount: 2.5,
      // coupon: 'hasbros',
      currency: 'USD',
      products: getCartProducts(items),
    })
  }

  interface OrderPlacedEvent {
    type: string
    receipt: Receipt
  }

  // Update user Identity when they place the order
  async function emitOrderPlacedIdentify({ receipt }: OrderPlacedEvent) {
    if (!receipt) return

    // segment.emitIdentify based on Shipping Address
    const shippingAddress = receipt.shippingContact
    const identity = {
      userId: undefined,
      email: shippingAddress.email,
      name: shippingAddress.fullName,
      phone: shippingAddress.phone,
      address: shippingAddress,
    }

    // =========================
    // Unique to this flow, if we don't have a user id
    // we want to try to create one by secretly making the user
    // an account. We do not want to sign them in, just create the account
    // and snag the userId generated so we can Identify them.
    let userId = await segment.getUserId()
    if (!userId) userId = await segment.generateUserId(identity.email)

    // If we have a user id,  add it to the identity object
    if (userId) identity.userId = userId
    // =========================

    segment.emitIdentify(identity)
    const subscribeCookie: any =
      typeof useCookie('checkoutSubscribe').value === 'undefined' ? false : useCookie('checkoutSubscribe')

    // subscribe them via segment event
    if (subscribeCookie.value) {
      if (subscribeCookie.value.subscribeEmail) {
        $uiEvents.$emit('newsletterSubscribed', {
          formName: 'order-placed-subscribe',
          email: shippingAddress.email,
        })
      }
      if (subscribeCookie.value.subscribeSms) {
        $uiEvents.$emit('newsletterSubscribedSms', {
          formName: 'order-placed-subscribe',
          email: shippingAddress.email,
          phone: shippingAddress.phone,
        })
      }

      // Clear the cookie
      subscribeCookie.value = null
    }
  }

  // Track that an order has been completed
  async function emitOrderCompleted({ type, receipt }: OrderPlacedEvent) {
    if (!receipt) return

    segment.track('Order Completed', {
      type,
      order_id: receipt.orderId,
      cart_id: rootStore.permId,
      ship_protection: cartStore.cart.hasShippingProtection,
      ...formatReceiptData(receipt),
      products: getCartProducts(receipt.items),
      insideSale: receipt.insideSale,
      ...getIterableCookies(),
    })
  }

  // Track Attribution data a completed order
  function emitAttribution({ receipt }: OrderPlacedEvent) {
    if (!receipt) return

    const shippingAddress = receipt.shippingContact

    // Try to get new names
    let firstName = shippingAddress.firstName
    let lastName = shippingAddress.lastName

    // If they don't exist then try to get them the old way
    if (!firstName && shippingAddress.fullName) [firstName, lastName] = shippingAddress.fullName.split(' ')

    const fbp = useCookie('_fbp').value
    const fbc = useCookie('_fbc').value
    const geoPostalCode = getGeoPostalCode()

    segment.track('Attribution Order Revenue', {
      orderId: receipt.orderId,
      email: shippingAddress.email,
      phone: shippingAddress.phone,
      firstName,
      lastName,
      address: shippingAddress.address1,
      city: shippingAddress.city,
      state: shippingAddress.stateId,
      zip: shippingAddress.zipcode,
      ...formatReceiptData(receipt),
      products: getCartProducts(receipt.items),
      insideSale: receipt.insideSale,
      ...(geoPostalCode && { geoPostalCode }),
      ...(fbp && { fbp }),
      ...(fbc && { fbc }),
      ...getIterableCookies(),
    })
  }

  function emitProductCheckoutStarted(items: CartItem[]) {
    segment.track('Product Checkout Started', {
      cart_id: rootStore.permId,
      products: getCartProducts(items),
    })
  }

  const formatReceiptData = (receiptObj: Receipt) => {
    return {
      total: receiptObj.summary.grandTotal / 100, // Revenue ($) with discounts and coupons added in.
      subtotal: receiptObj.summary.cartSubtotal / 100, // Order total after discounts but before taxes and shipping
      revenue: receiptObj.summary.cartSubtotal / 100, // Revenue ($) associated with the transaction (excluding shipping and tax)
      shipping: receiptObj.summary.shippingSubtotal / 100,
      tax: receiptObj.summary.taxSubtotal / 100,
      currency: 'USD',
    }
  }

  const getGeoPostalCode = () => {
    if (!isIntegrationEnabled('geolocation')) return null
    return $geolocation.zipcode
  }

  function getIterableCookies() {
    return {
      iterableTemplateId,
      iterableEmailCampaignId,
      iterableMessageId,
      iterableEndUserId,
    }
  }
}
