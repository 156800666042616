<template lang="pug">
div(:class='{ relative: $storyblok.isEditing.value }')
  CarouselProduct(v-if='!$storyblok.isEditing.value && type' :product-type='type')
  div(v-else-if='$storyblok.isEditing.value' class='flex justify-center items-center h-64 bg-gray-light')
    p(class='text-center capitalize')
      | {{ type }} Products Carousel

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

interface RecommendationsWidget extends Widget {
  type: string
}

const { data } = defineProps<{
  data: RecommendationsWidget
}>()

const type = computed(() => {
  return data.type?.toLowerCase() || null
})
</script>
