export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  const { $constructor, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration constructor 99')

  if (
    !isIntegrationEnabled('constructor') ||
    !isIntegrationEnabled('constructorTest') ||
    !isIntegrationEnabled('speedcurve')
  )
    return

  window.LUX.addData('isConstructor', $constructor.test.showConstructor ? 'Yes' : 'No')
})
