export default function wireSearchListeners() {
  const segment = useSegmentHelper()
  const { $uiEvents } = useNuxtApp()

  $uiEvents.$on('searched', ({ query }) => {
    segment.track('Products Searched', {
      query: query.toLowerCase(),
    })
  })

  $uiEvents.$on('noSearchResults', (query) => {
    segment.track('Search - No Results', {
      emptySearchTerm: query.toLowerCase(),
    })
  })
}
