// EX: const log = useLogger('PREFIX')
export default function (prefix?: string) {
  const { $bugsnag } = useNuxtApp()

  function info(message: string, metaData?: Record<string, any>) {
    if (prefix) message = `${prefix}: ${message}`
    console.log(message, metaData)
    $bugsnag.notify(message, 'info', metaData)
  }
  function warning(message: string, metaData?: Record<string, any>) {
    if (prefix) message = `${prefix}: ${message}`
    console.warn(message, metaData)
    $bugsnag.notify(message, 'warning', metaData)
  }
  function error(message: string, metaData?: Record<string, any>) {
    if (prefix) message = `${prefix}: ${message}`
    console.error(message, metaData)
    $bugsnag.notify(message, 'error', metaData)
  }

  return {
    info,
    warning,
    error,
  }
}
