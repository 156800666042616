import algoliasearch from 'algoliasearch'
import SearchProvider from '../helpers/searchProvider'

export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration algolia')
  const { isProduction } = useUtils()
  const client = algoliasearch($sitewideConfig.config.algoliaAppId, $sitewideConfig.config.algoliaApiKey)

  const indexPrefix = isProduction() ? 'prod' : 'staging'

  const indexes = {
    content: `${indexPrefix}_content`,
    brands: `${indexPrefix}_brands`,
    builds: `${indexPrefix}_builds`,
    categories: `${indexPrefix}_categories`,
    dealers: `${indexPrefix}_dealers`,
    products: `${indexPrefix}_products`,
    vehicles: `${indexPrefix}_vehicles`,
    redirects: `${indexPrefix}_redirects`,
    salesReps: `${indexPrefix}_sales_reps`,
  }

  // Once runtime v6 gets merged we'll need to only create contexts for features that are enabled.
  const searchContexts: Record<string, SearchProvider> = {
    // Add a new searchBarIndexes for Redirect here
    searchRedirect: new SearchProvider(client, {
      indexName: indexes.redirects,
      id: 'searchRedirect',
      params: {
        resultsPerPage: 1,
        advancedSyntax: true,
      },
    }),
    content: new SearchProvider(client, {
      indexName: indexes.content,
      id: 'content',
      params: {
        resultsPerPage: 18,
      },
    }),
    brands: new SearchProvider(client, {
      indexName: indexes.brands,
      id: 'brands',
      params: {
        facets: ['group'],
      },
    }),
    buildsDefault: new SearchProvider(client, {
      indexName: indexes.builds,
      id: 'buildsDefault',
      sorts: [
        { indexName: indexes.builds, label: 'Newest' },
        { indexName: `${indexes.builds}_by_popularity`, label: 'Most Popular' },
      ],
    }),
    builds: new SearchProvider(client, {
      indexName: indexes.builds,
      id: 'builds',
      sorts: [
        { indexName: indexes.builds, label: 'Newest' },
        { indexName: `${indexes.builds}_by_popularity`, label: 'Most Popular' },
      ],
      params: {
        facets: ['usedFor', 'products.brand', 'make', 'model'],
      },
    }),
    catalog: new SearchProvider(client, {
      indexName: indexes.products,
      id: 'catalog',
      sorts: [
        { indexName: indexes.products, label: 'Best Selling' },
        {
          indexName: `${indexes.products}_by_price_asc`,
          label: 'Price Low To High',
        },
        {
          indexName: `${indexes.products}_by_price_desc`,
          label: 'Price High To Low',
        },
      ],
      params: {
        resultsPerPage: 30,
        attributes: [
          'productLineName',
          'productLineSlug',
          'image',
          'badgeText',
          'hasDiscount',
          'isPreOrder',
          'isNew',
          'sameDayShipping',
          'salePrice',
          'originalPrice',
          'averageRating',
          'totalReviews',
          'productId',
          'brand', // for Schema markup :(
          'brandSlug', // for GA :(
          'skuSlug', // for GA :(
          'categories', // for GA :(
        ],
        ignorePlurals: ['en'],
        scanDistinctHitsUntilPaginationLimitedTo: true,
        clickAnalytics: true,
      },
    }),
    // This is a misc store for us to get random data about product lines
    // used in things like the cms price widget and the helper to get prices on homepage tables.
    products: new SearchProvider(client, {
      indexName: indexes.products,
      id: 'products',
      params: {
        resultsPerPage: 100,
        attributes: ['productLineName', 'productLineSlug', 'brand', 'minimumPrice', 'originalPrice', 'salePrice'],
      },
    }),
    productsObjectId: new SearchProvider(client, {
      indexName: indexes.products,
      id: 'productsObjectId',
      params: {
        resultsPerPage: 1,
        attributes: ['ObjectID'],
      },
    }),
    productsDropdown: new SearchProvider(client, {
      indexName: indexes.products,
      id: 'productsDropdown',
      params: {
        resultsPerPage: 1000,
        attributes: ['productLineName', 'productLineSlug', 'skuSlug'],
      },
    }),
    fitment: new SearchProvider(client, {
      indexName: indexes.vehicles,
      id: 'fitment',
      params: {
        facets: [
          'year',
          'make',
          'makeSlug',
          'model',
          'modelSlug',
          'bed',
          'bedSlug',
          'body',
          'bodySlug',
          'engine',
          'engineSlug',
        ],
      },
    }),
    searchBar: new SearchProvider(client, {
      id: 'searchBar',
      indexes: [
        {
          key: 'products',
          indexName: indexes.products,
          params: {
            resultsPerPage: 5,
          },
        },
        {
          key: 'brands',
          indexName: indexes.brands,
          params: {
            resultsPerPage: 5,
          },
        },
        {
          key: 'categories',
          indexName: indexes.categories,
          params: {
            resultsPerPage: 5,
          },
        },
      ],
    }),
  }

  /**
   * Installation Guides
   */
  if ($sitewideConfig.config.installGuideSearchEnabled) {
    searchContexts.searchInstallationGuides = new SearchProvider(client, {
      id: 'searchInstallationGuides',
      indexes: [
        {
          key: 'products',
          indexName: indexes.products,
          params: {
            resultsPerPage: 3,
            filters: SearchProvider.formatFilterOrRefinement('isAccessory', 'false'),
            restrictSearchableAttributes: ['productLineName'],
          },
        },
        {
          key: 'sku',
          indexName: indexes.products,
          params: {
            resultsPerPage: 3,
            typoTolerance: false,
            filters: SearchProvider.formatFilterOrRefinement('isAccessory', 'false'),
            restrictSearchableAttributes: ['mpn', 'sku'],
          },
        },
      ],
    })
  }

  /**
   * Dealer Locator
   */
  if ($sitewideConfig.config.dealerLocatorEnabled) {
    // This provider is only for getting the brands list from algolia.
    // When getting the brands from the dealerLocator provider causes issues because
    searchContexts.dealerLocatorBrands = new SearchProvider(client, {
      indexName: indexes.dealers,
      id: 'dealerLocatorBrands',
      params: {
        facets: ['brands.brand'],
        filters: SearchProvider.formatFilterOrRefinement('isInternational', 'false'),
        getRankingInfo: true,
        resultsPerPage: 500,
      },
    })

    searchContexts.dealerLocator = new SearchProvider(client, {
      id: 'dealerLocator',
      indexes: [
        {
          key: 'main',
          indexName: indexes.dealers,
          params: {
            facets: ['brands.brand'],
            filters: SearchProvider.formatFilterOrRefinement('isInternational', 'false'),
            getRankingInfo: true,
            resultsPerPage: 500,
          },
        },
        {
          key: 'brand',
          indexName: indexes.dealers,
          params: {
            facets: ['brands.brand'],
            filters: SearchProvider.formatFilterOrRefinement('isInternational', 'false'),
            getRankingInfo: true,
            resultsPerPage: 500,
          },
        },
      ],
    })

    if ($sitewideConfig.config.dealerLocatorInternationalEnabled) {
      searchContexts.dealerLocatorInternational = new SearchProvider(client, {
        indexName: indexes.dealers,
        id: 'dealerLocatorInternational',
        params: {
          facets: ['country'],
          filters: SearchProvider.formatFilterOrRefinement('isInternational', 'true'),
          resultsPerPage: 500,
        },
      })
    }
  }

  if ($sitewideConfig.config.salesRepsSearchEnabled) {
    searchContexts.salesReps = new SearchProvider(client, {
      indexName: indexes.salesReps,
      id: 'salesReps',
      params: {
        facets: ['territory.state'],
      },
    })
  }

  function addDynamicContentContext(id: string) {
    return new SearchProvider(client, {
      indexName: indexes.content,
      id,
    })
  }
  function addDynamicProductContext(id: string) {
    if (searchContexts[id]) return searchContexts[id]
    searchContexts[id] = new SearchProvider(client, {
      indexName: indexes.products,
      id,
      params: {
        attributes: [
          'productLineName',
          'productLineSlug',
          'image',
          'badgeText',
          'hasDiscount',
          'isPreOrder',
          'isNew',
          'sameDayShipping',
          'salePrice',
          'originalPrice',
          'averageRating',
          'totalReviews',
          'productId',
          'brand', // for Schema markup :(
          'brandSlug', // for GA :(
          'skuSlug', // for GA :(
          'categories', // for GA :(
        ],
        ignorePlurals: ['en'],
        scanDistinctHitsUntilPaginationLimitedTo: true,
      },
    })
    return searchContexts[id]
  }

  return {
    provide: {
      algolia: {
        searchContexts,
        addDynamicContentContext,
        addDynamicProductContext,
      },
    },
  }
})
