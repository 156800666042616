<template lang="pug">
div(v-if='$storyblok.isEditing.value' class='flex justify-center items-center h-64 bg-gray-light')
  p Global Content Placeholder ( {{ data.references.length }} )
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

interface GlobalContentWidget extends Widget {
  references: string[]
}

const { data } = defineProps<{
  data: GlobalContentWidget
}>()
</script>
