export default function () {
  const rootStore = useRootStore()
  const urls = useUrls()
  const receiptUrl = urls.getApiUrl('receipt')

  async function getReceipt(orderId: string) {
    return await $fetch<Receipt>(`${receiptUrl}${orderId}?permId=${rootStore.originalPermId}`)
  }

  return { getReceipt }
}

export interface Receipt {
  orderId: string
  summary: {
    cartSubtotal: number
    giftCardSubtotal: number
    grandTotal: number
    installationSubtotal: number
    shippingSubtotal: number
    taxSubtotal: number
    warrantySubtotal: number
  }
  shippingContact: Contact
  billingContact: Contact
  payment: {
    brand: string
    lastFour: string
    paymentProvider: string
    paymentType: string
  }
  insideSale: boolean
  items: ReceiptItem[]
}

export interface ReceiptItem {
  discount?: {
    text: string
  }
  fitmentData?: {
    appNote?: string
    bedName?: string
    bodyName?: string
    engineName?: string
    makeName?: string
    modelName?: string
    year?: string
  }
  hasInstallationGuide: boolean
  hasInstallationVideo: boolean
  image: {
    filename: string
    key: string
  }
  info?: Record<string, string>[]
  installation?: any
  isMadeToOrder: boolean
  itemSubtotal: number
  meta?: Record<string, string>[]
  mpn: string
  originalPrice: number
  originalSubtotal: number
  productLineSlug: string
  productName: string
  productSlug: string
  promo?: {
    text: string
  }
  qty: number
  salePrice: number
  saleSubtotal: number
  shipping: {
    estimatedDeliveryDate?: {
      min: string
      max: string
    }
    expected: any
    isEligibleFreeShipping: boolean
    isFreight: boolean
    sameDay: boolean
  }
  sku: string
  warranty?: ReceiptItemWarranty
  guaranteedFit: 'PHONE' | 'SITE'
}

interface ReceiptItemWarranty {
  id: string
  imageUrl: string
  price: number
  subTotal: number
  termLength: number
  url: string
}
