export default function wireCartManagerListeners() {
  const segment = useSegmentHelper()
  const { $uiEvents } = useNuxtApp()

  interface PaylinkSentEvent {
    cartId: string
    email: string
  }

  $uiEvents.$on('paylinkSent', ({ cartId, email }: PaylinkSentEvent) => {
    segment.track('Paylink Sent', { userCartId: cartId, userEmail: email })
  })
}
