<template lang="pug">
div(v-if='hideRules')
  template(v-for='widget in data.widgets')
    slot(:data='widget')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const fitmentDisplayStore = useFitmentDisplayStore()

interface VisibilityWidget extends Widgets {
  displayOptions: 'FITMENT' | 'PARTIAL_FITMENT' | 'NO_FITMENT'
}

const { data } = defineProps<{
  data: VisibilityWidget
}>()

const hideRules = computed(() => {
  // Always show when editing
  if ($storyblok.isEditing.value) return true

  // When not editing - Define the v-if rule
  switch (data.displayOptions) {
    case 'FITMENT': // show if fitment has YMM (or more)
      return fitmentDisplayStore.hasYmmFullFitment
    case 'PARTIAL_FITMENT': // show if Has Partial Fitment
      return fitmentDisplayStore.hasPartialFitment
    case 'NO_FITMENT': // show if No Fitment Selected
      return fitmentDisplayStore.hasNoFitment
    default:
      return true
  }
})
</script>
