<template lang="pug">
div(class='flex flex-wrap' :class='[alignmentClasses, spacingClasses]')
  template(v-for='button in data.buttons' :key='button._uid')
    CmsWidgetButtonItem(
      :data='button'
      :mobile-is-full-width='data.mobileIsFullWidth'
      :tablet-is-full-width='data.tabletIsFullWidth'
      :desktop-is-full-width='data.desktopIsFullWidth'
    )
</template>

<script setup lang="ts">
declare global {
  interface ButtonItem {
    fillColor: ButtonFillColor
    icon: ButtonIcon
    link: string
    linkTarget: string
    style: ButtonStyle
    text: string
    textColor: string
  }
}

interface ButtonWidget extends WidgetAlignment {
  buttons: ButtonItem[]
  mobileIsFullWidth: 'INHERIT' | StringBoolean
  tabletIsFullWidth: 'INHERIT' | StringBoolean
  desktopIsFullWidth: 'INHERIT' | StringBoolean
  mobileSpacing: GapStrings
  tabletSpacing: GapStrings
  desktopSpacing: GapStrings
}

const { data } = defineProps<{
  data: ButtonWidget
}>()

const alignmentClasses = computed(() => {
  return generateFlexAlignmentClasses(data.mobileAlignment, data.tabletAlignment, data.desktopAlignment)
})

const spacingClasses = computed(() => {
  return generateGapClasses(data.mobileSpacing, data.tabletSpacing, data.desktopSpacing)
})

type ButtonStyle = 'SOLID' | 'OUTLINE'
type ButtonIcon = 'PADLOCK' | 'SAVE' | 'ARROW' | 'NONE'
type ButtonFillColor =
  | 'ACTION'
  | 'INFO'
  | 'PRIMARY'
  | 'PRIMARY_DARK'
  | 'SECONDARY'
  | 'SUCCESS'
  | 'WARNING'
  | 'DANGER'
  | 'GRAY_LIGHT'
  | 'GRAY'
  | 'GRAY_DARKEST'
  | 'WHITE'
  | 'BLACK'
</script>
