export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration installernet')
  const rootStore = useRootStore()
  const cartStore = useCartStore()
  const { getApiUrl } = useUrls()

  const cartUrl = getApiUrl('cart')

  interface State {
    quotes: Record<string, string>
  }
  const state = ref<State>({
    quotes: {},
  })

  function clearQuotes() {
    state.value.quotes = {}
  }

  function setQuote(sku: string, quoteId?: string | null) {
    // We will recreate the Quotes object each time
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [sku]: id, ...quotes } = state.value.quotes
    // We are adding the changed/new record if it exists
    state.value.quotes = {
      ...quotes,
      ...(quoteId && { [sku]: quoteId }),
    }
  }

  // Add To Cart will need to fetch a quote by sku
  function getQuoteIdBySku(sku: string) {
    return state.value.quotes[sku]
  }

  async function addInstallation(itemId: number, quoteId: string, city: string, state: string) {
    // Add to a Line Item
    const url = `${cartUrl}${rootStore.permId}/items/${itemId}/installation`
    await $fetch(url, {
      method: 'POST',
      body: {
        quoteId,
        city,
        state,
      },
      headers: {
        'x-site': $sitewideConfig.sitePrefix,
      },
    })
    await cartStore.getCart()
  }

  async function removeInstallation(itemId: number) {
    // Add to a Line Item
    const url = `${cartUrl}${rootStore.permId}/items/${itemId}/installation`
    await $fetch(url, {
      method: 'DELETE',
      headers: {
        'x-site': $sitewideConfig.sitePrefix,
      },
    })
    await cartStore.getCart()
  }

  async function updateInstallation(itemId: number, city: string, state: string, zip: string) {
    // Add to a Line Item
    const url = `${cartUrl}${rootStore.permId}/items/${itemId}/installation`
    await $fetch(url, {
      method: 'PUT',
      body: {
        city,
        state,
        zip,
      },
      headers: {
        'x-site': $sitewideConfig.sitePrefix,
      },
    })

    await cartStore.getCart()
  }

  return {
    provide: {
      installernet: {
        setQuote,
        getQuoteIdBySku,
        addInstallation,
        removeInstallation,
        updateInstallation,
        clearQuotes,
        state,
      },
    },
  }
})
