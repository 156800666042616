<template lang="pug">
//- A wrapper div is required to use the Observer for amplitude tracking
div(v-if='isEnabled')
  template(v-if='forceShow || shouldRender')
    slot
</template>

<script>
export default defineNuxtComponent({
  setup() {
    const { observe } = useRunOnVisible()
    const { isIntegrationEnabled } = useUtils()
    const fitmentDisplayStore = useFitmentDisplayStore()

    return {
      observe,
      isAamplitudeEnabled: isIntegrationEnabled('amplitude'),
      isSegmentEnabled: isIntegrationEnabled('segment'),
      fitmentDisplayStore,
    }
  },
  props: {
    productLineSlug: {
      type: String,
      default: null,
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
    shouldTrack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isInTestGroup: false,
      validFitment: [
        {
          startYear: 2021,
          endYear: 2023,
          make: 'ford',
          model: 'f150',
          beds: ['5-7-bed', '6-7-bed'],
          bodies: ['super-crew'],
        },
        {
          startYear: 2021,
          endYear: 2023,
          make: 'ford',
          model: 'f150',
          beds: ['6-7-bed', '8-bed'],
          bodies: ['super-cab'],
        },
        {
          startYear: 2021,
          endYear: 2023,
          make: 'ford',
          model: 'f150',
          beds: ['6-6-bed', '8-bed'],
          bodies: ['regular-cab'],
        },
      ],
    }
  },
  computed: {
    isEnabled() {
      return this.$sitewideConfig.config.protexEnabled && this.isAamplitudeEnabled && this.isSegmentEnabled
    },
    shouldRender() {
      return this.isEnabled && this.productLineSlug
        ? this.isInTestGroup && this.checkFitment && this.checkProduct
        : this.isInTestGroup && this.checkFitment
    },
    // The Product Page also needs to verify we are on a whitelisted product
    checkProduct() {
      const validSlugs = [
        'a-r-e-q-series-truck-cap', // Future Product Launch
        'bakflip-mx4-tonneau-cover',
        'bak-revolver-x4s-tonneau-cover',
        'extang-trifecta-2-0-tonneau-cover',
        'retraxpro-mx-tonneau-cover',
        'retrax-eq-tonneau-cover',
        'undercover-elite-lx-tonneau-cover',
        'bedrug-full-bedliner',
        'bedrug-xlt-bed-mat',
        'husky-liners-heavy-duty-truck-bed-mat',
        'grid-x-rack', // Future Product Launch
        'realtruck-gorack',
        'undercover-swing-case-truck-bed-toolbox',
        'bushwacker-pocket-style-fender-flares',
        'husky-liners-x-act-contour-floor-liners',
        'husky-liners-weatherbeater-floor-liners',
        'go-rhino-br6-front-winch-bumper',
        'husky-liners-custom-molded-mud-flaps',
        'go-rhino-br20-5-rear-bumper',
        'go-rhino-ceros-low-profile-roof-rack',
        'go-rhino-rb20-running-boards',
        'amp-research-power-step',
        'avs-lo-profile-vent-visors',
        'rugged-rear-wheel-well-liners',
      ]

      return validSlugs.includes(this.productLineSlug)
    },
    checkFitment() {
      if (!this.fitmentDisplayStore.hasFullFitment) return false
      let fitmentOptions = [...this.validFitment]

      // We know they have selected all required fields
      // So we are matching those required fields against our list of valid fitment options
      // and filtering out any fitement options that don't match
      return this.fitmentDisplayStore.requiredSelectTypes.every(({ type, currentValue }) => {
        switch (type) {
          case 'year':
            fitmentOptions = fitmentOptions.filter(({ startYear, endYear }) => {
              return startYear <= currentValue && endYear >= currentValue
            })
            break
          case 'make':
            fitmentOptions = fitmentOptions.filter(({ make }) => {
              return make === currentValue
            })
            break
          case 'model':
            fitmentOptions = fitmentOptions.filter(({ model }) => {
              return model === currentValue
            })
            break
          case 'bed':
            fitmentOptions = fitmentOptions.filter(({ beds }) => {
              return beds.includes(currentValue)
            })
            break
          case 'body':
            fitmentOptions = fitmentOptions.filter(({ bodies }) => {
              return bodies.includes(currentValue)
            })
            break
        }

        // If we still have 1 or more valid fitment options
        // then we are good to show the Protex link
        return fitmentOptions.length > 0
      })
    },
  },
  watch: {
    shouldRender(newVal) {
      if (!newVal || !this.shouldTrack) return

      this.observe(this.$el, () => this.$amplitude.track('protex-poc-test'))
    },
  },
  async mounted() {
    if (!this.isEnabled) return

    const variant = await this.$amplitude.getVariant('protex-poc-test')
    this.isInTestGroup = variant?.value === 'treatment'

    if (this.shouldTrack && variant?.value === 'control') this.$amplitude.track('protex-poc-test')
  },
})
</script>
