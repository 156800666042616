export default function (options?: IntersectionObserverInit) {
  let enterCallback: (() => void) | null = null
  let exitCallback: (() => void) | null = null
  let runOnce = false
  let observer: IntersectionObserver | null = null
  let hasIntersected = false

  /**
   * Watches an element and fires enter/exit callbacks whenever it comes in and out of the viewport.
   * @param target The DOM element to observe
   * @param enterCallback The function to run when the element is within the viewport.
   * @param exitCallback The function to run when the element is no longer in the viewport.
   * @param runOnce Only run the callbacks once
   */
  function observe(target: Element, enterCb: () => void, exitCb?: () => void, once = true) {
    enterCallback = enterCb

    if (exitCb) exitCallback = exitCb

    runOnce = once

    if (!window.IntersectionObserver) {
      _callEnterCallback()
      return
    }

    observer = new IntersectionObserver(_handleIntersect, options)
    observer.observe(target)
  }

  function _callEnterCallback() {
    if (enterCallback) enterCallback()
    if (runOnce && !exitCallback) _disconnect()
  }

  function _callExitCallback() {
    if (exitCallback) exitCallback()
    if (runOnce) _disconnect()
  }

  function _handleIntersect(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        hasIntersected = true
        _callEnterCallback()
      } else {
        // an event fires when you begin observing with hasIntersecting = false, make sure we've at least intersected first
        if (hasIntersected) _callExitCallback()
      }
    })
  }

  function _disconnect() {
    observer?.disconnect()
  }

  onBeforeUnmount(() => {
    _disconnect()
  })

  return {
    observe,
  }
}
