<template lang="pug">
Overlay(data-test='MobileMenu' unique-key='mobileMenu' :unmount='false' panel-width='HALF' @click='captureLinkClick')
  template(#header)
    UniversalLink(v-if='isIntegrationEnabled("liveperson")' link='CHAT' @click='$overlay.close()')
      ChatBubble(class='text-action fill-current' style='width: 28px; height: 24px')

    div
      a(
        :href='"tel:" + $sitewideConfig.config.phoneGeneral'
        data-track='phone-number'
        class='phone-link text-xl text-black font-bold'
      ) {{ $sitewideConfig.config.phoneGeneral }}

  template(#noPadding)
    div(class='bg-black text-white')
      GeolocationDisplay(v-if='isIntegrationEnabled("geolocation")' class='bg-black px-3 py-4')
    template(v-if='navbarData')
      ul(class='px-4 pt-5 pb-36 space-y-6 text-sm text-black' data-testid='mobileNav')
        template(v-for='navBarItem in navbarData.items' :key='navBarItem._uid')
          template(v-if='$storyblok.isVisible(navBarItem)')
            li
              CmsWidgetAbTest(v-if='navBarItem.component === "ab-test-widget"' :data='navBarItem')
              HeaderNavbarMobileTextLink(
                v-if='navBarItem.component === "text-menu-item"'
                data-testid='navigationBarLink-text-menu-item'
                :data='navBarItem'
              )
              HeaderNavbarMobileCmsMenu(
                v-else-if='navBarItem.component === "cms-menu"'
                data-testid='navigationBarLink-cms-menu'
                :data='navBarItem'
              )
              HeaderNavbarMobileTreeMenu(
                v-else-if='navBarItem.component === "tree-menu" || navBarItem.component === "link-menu"'
                data-testid='navigationBarLink-tree-menu'
                :data='navBarItem'
                :root-text='navBarItem.text'
              )
</template>

<script setup lang="ts">
import ChatBubble from '@/assets/chatBubble.svg?component'

const { $sitewideConfig, $storyblok, $uiEvents, $overlay } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const previewData = ref()
const getUniversalLinkTarget: any = inject('getUniversalLinkTarget')

if ($storyblok.isPreview.value) {
  const { data } = await useAsyncData('navbar-data', async () => {
    if ($storyblok.contentType.value !== 'navbar') return null
    const preview = await $storyblok.getPreviewStory<NavbarStory>()
    return preview?.story.content ?? null
  })

  if (data.value) previewData.value = data.value
}

const navbarData = computed(() => {
  return previewData.value || $sitewideConfig.navbar.value?.content
})

function captureLinkClick() {
  if ($storyblok.isEditing.value && $storyblok.isPreview.value) return

  // Make sure this event is a Universal Link
  const target = getUniversalLinkTarget(event)
  if (!target) return

  // We know its a universal link, but it may not have an href
  // Ex: ACCOUNT triggers
  const link = target.href ? { link: target.href } : { text: target.innerText }

  // Get the RootText and Level properties
  const { level, rootText } = target.dataset

  // Emit the event
  $uiEvents.$emit('navigationClicked', {
    ...link,
    ...(level && { level }),
    ...(rootText && { rootText }),
  })
}
</script>
