<template lang="pug">
component(:is='wrapperOptions.is' v-bind='wrapperOptions.attrs')
  | {{ formattedContent }}
</template>

<script setup lang="ts">
import { UniversalLink } from '#components'

const { $algolia, $storyblok } = useNuxtApp()
const { variableExtractor, translateTemplate } = useTemplateTranslator()

const { data, linkColor = '' } = defineProps<{
  data: {
    text: string
    marks?: Mark[]
  }
  linkColor?: string
}>()

onMounted(() => parsePriceVariables())

interface WrapperOptions {
  is: string | typeof UniversalLink
  attrs: {
    class: string[]
    link?: string
    openNewWindow?: boolean
  }
}

interface Mark {
  type: string
  attrs: {
    class: string
    href: string
    linktype: string
    anchor: string
    target: string
  }
}

const wrapperOptions = computed(() => {
  const options: WrapperOptions = {
    is: 'span',
    attrs: {
      class: [],
    },
  }

  if (data.marks) {
    data.marks.forEach((mark: Mark) => {
      if (mark.type === 'styled') options.attrs.class.push(generateFontSizeClass(mark.attrs.class))
      else if (mark.type === 'bold') options.attrs.class.push('font-bold')
      else if (mark.type === 'italic') options.attrs.class.push('italic')
      else if (mark.type === 'strike') options.attrs.class.push('line-through')
      else if (mark.type === 'link') {
        options.is = UniversalLink
        // Storyblok appends real path to the beginning of Internal Links.
        // In staging real path is undefined so this will not work unless a real path
        // is hardcoded or undefined is replaced i.e. mark.attrs.href.replace('undefined', '')
        options.attrs.link = mark.attrs.href
        if (mark.attrs.linktype === 'story' && mark.attrs.anchor)
          options.attrs.link = `${options.attrs.link}#${mark.attrs.anchor}`
        options.attrs.openNewWindow = mark.attrs?.target === '_blank'
        if (mark.attrs.linktype === 'email') options.attrs.link = `mailto:${options.attrs.link}`

        if (linkColor === '') options.attrs.class.push('text-inherit underline')
        else if (linkColor === null) options.attrs.class.push('text-action-dark')
        else options.attrs.class.push(linkColor)
      }
    })
  }

  return options
})

// Get price tokens
const priceTokens = computed(() => {
  return tokens.value
    .filter((token) => token.startsWith('PRICE|', 1) || token.startsWith('LIST_PRICE|', 1))
    .map((token) => token.slice(1, -1))
})

// Creates obj with the price tokens and their corresponding value
const priceReplacementValues = computed(() => {
  return priceTokens.value.reduce((acc, token) => {
    const [type, productLineSlug] = token.split('|')

    const productRecord = $algolia.searchContexts.products.state.value.results.find(
      (record) => productLineSlug === record.productLineSlug
    )

    let price = '$###.##'

    if (productRecord) {
      switch (type) {
        case 'LIST_PRICE':
          price = formatCents(productRecord.originalPrice)
          break

        case 'PRICE':
        default:
          price = formatCents(productRecord.minimumPrice)
          break
      }
    }

    acc[token] = price

    return acc
  }, {})
})

const replacementValues = computed(() => {
  return {
    ...priceReplacementValues.value,
  }
})

const tokens = computed(() => {
  return variableExtractor(data.text)
})

const formattedContent = computed(() => {
  if ($storyblok.isEditing.value) return data.text
  return translateTemplate(data.text, replacementValues.value)
})

function parsePriceVariables() {
  if (priceTokens.value.length === 0) return

  priceTokens.value.forEach((priceToken) => {
    const [, productLineSlug] = priceToken.split('|')

    // If none, ignore
    if (!productLineSlug) return

    // Attempt to trigger the search
    addToSearchQueue(productLineSlug)
  })
}

async function addToSearchQueue(productLineSlug: string) {
  // Add to search filters
  $algolia.searchContexts.products.addFilters('productLineSlug', productLineSlug)

  // Search for new products
  await $algolia.searchContexts.products.setResultsPerPage(100).setQuery('').search()
  $algolia.searchContexts.products.clearFilters(null)
}

watch(tokens, () => {
  parsePriceVariables()
})
</script>
