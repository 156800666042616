<template lang="pug">
div(:key='video.id' ref='videoEl' class='wistia_responsive_padding' style='padding: 56.25% 0 0 0; position: relative')
  div(class='wistia_responsive_wrapper' style='height: 100%; left: 0; position: absolute; top: 0; width: 100%')
    div(
      :class='`wistia_embed seo=false wistia_async_${video.id}`'
      videoFoam='true'
      style='height: 100%; position: relative; width: 100%'
    )
      div(
        class='wistia_swatch'
        style='height: 100%; left: 0; opacity: 0; overflow: hidden; position: absolute; top: 0; transition: opacity 200ms; width: 100%'
      )
        img(
          ref='wistiaImage'
          :src='`https://fast.wistia.com/embed/medias/${video.id}/swatch`'
          style='filter: blur(5px); height: 100%; object-fit: contain; width: 100%'
          alt=''
          loading='lazy'
        )
</template>

<script setup lang="ts">
const { video } = defineProps<{ video: Video }>()
useVideoSchema(video)
</script>
