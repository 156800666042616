<script>
// https://github.com/vuejs/core/issues/6435
// Has issues using v-html with nuxt-link :(
// https://realtruck.com/p/carr-180-176-light-mount/ (Degree)
// https://realtruck.com/p/avs-aeroskin-lightshield/ (R)
import { h } from 'vue'
export default {
  props: {
    text: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    return () =>
      h('span', {
        innerHTML: props.text,
      })
  },
}
</script>
