export default defineNuxtPlugin(() => {
  const router = useRouter()
  const route = useRoute()
  const { $device } = useNuxtApp()
  interface Overlay {
    isPersistent: boolean // Do not auto-close on route change. Ex: Builds Menu
  }

  const activeOverlay = ref('') // unique name of the overlay we are showing
  const isActive = ref(false) // True when the overlay is visible to the user
  const overlays: { [key: string]: Overlay | undefined } = {}

  // Mod must be a string or undefined but could technically be an array
  const cleanQueryMod = (mod: any): string | undefined => {
    if (!mod) return
    return Array.isArray(mod) ? mod[0] : `${mod}`
  }

  const overlayKey = cleanQueryMod(route.query.mod)
  if (overlayKey) _setActiveOverlay(overlayKey)

  // Watch for route changes and update accordingly
  router.afterEach((to, from) => {
    const mod = cleanQueryMod(to.query.mod)
    const currentOverlay = activeOverlay.value
    if (
      from.path === to.path &&
      currentOverlay &&
      overlays[currentOverlay]?.isPersistent &&
      (!mod || currentOverlay === mod)
    ) {
      // isPersistant: Early Out -- (Logic kept separate from above for ease of reading)
      // If we are navigating within the same Path
      // AND we have a Current Overlay being displayed
      // AND that overlay is persistent
      // AND IF we either don't have a Mod in the url OR if it is the same Mod
      // We need to early out and keep it open
      return
    } else if (mod) {
      // A new mod request, open it up!
      _setActiveOverlay(mod)
      return
    } else {
      _clearActiveOverlay()
      return
    }
  })

  // Called by Overlay.vue based on the Transition event
  // This way we can take action based on when the overlay
  // is truly Open or Closed
  const isOpen = (bool: boolean) => {
    isActive.value = bool
  }

  const open = async (uniqueKey: string) => {
    // If this overlay does not use ?mod (is not mobile) just set the active overlay directly
    if ($device.value.isDesktop) {
      _setActiveOverlay(uniqueKey)
      return
    }

    // If we are on mobile, update the URL and let the router guard handle it
    const query = Object.assign({}, route.query)
    query.mod = uniqueKey
    await navigateTo({ query })
  }

  // Registers an Overlay and tracks if it should be persistent
  const initOverlay = (uniqueKey: string, isPersistent: boolean) => {
    overlays[uniqueKey] = { isPersistent }
  }

  const close = async () => {
    // If we don't have an activeOverlay string then early out
    if (!activeOverlay.value) return

    const query = Object.assign({}, route.query)
    // If query.mod exists we need to remove it and allow the router.after each to clear the modal
    if (query.mod) {
      delete query.mod
      await navigateTo({ query })
    }

    // Should we still have an activeOverlay after the router push, clear the variable manually
    // Reason:
    // A persistant modal will remain open after the ?mod is removed so that it stays open as a user navigates
    // but close() is only called by a direct user interaction so we know the overlay needs to be inactive
    if (activeOverlay.value) {
      _clearActiveOverlay()
    }
  }

  // Set activeOverlay to a registered uniqueKey
  // after the currently active overlay is fully closed.
  function _setActiveOverlay(uniqueKey: string) {
    // If activeOverlay has a value we are transitioning between modals
    // Close the current modal before opening the next
    if (activeOverlay.value) _clearActiveOverlay()

    // Watch for the overlay to be closed before changing the active overlay
    // Ex: Close the Mobile Menu before opening My Account
    const unwatch = watchEffect(() => {
      if (!isActive.value) {
        activeOverlay.value = uniqueKey
        // Execute this watcher only once
        // Avoid error: Cannot access 'unwatch' before initialization
        setTimeout(() => unwatch())
      }
    })
  }

  // Set activeOverlay to empty string
  function _clearActiveOverlay() {
    activeOverlay.value = ''
  }

  return {
    provide: {
      overlay: {
        activeOverlay: readonly(activeOverlay),
        initOverlay,
        isOpen,
        open,
        close,
      },
    },
  }
})
