<template lang="pug">
div
  slot(:results='results' :result-count='resultCount')
    template(v-if='resultCount === 0')
      slot(name='empty')

    template(v-else)
      slot(name='results' :results='results' :result-count='resultCount')
        template(v-for='(result, index) in results')
          slot(name='result' :result='result' :result-count='resultCount' :index='index')
</template>

<script>
import SearchComponent from './Component'

export default defineNuxtComponent({
  extends: SearchComponent,
  computed: {
    results() {
      return this.$searchContext.state.value.results
    },
    resultCount() {
      return this.$searchContext.state.value.resultCount ?? 0
    },
  },
})
</script>
