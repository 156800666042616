<template lang="pug">
div(class='flex flex-wrap items-center gap-y-2' :class='wrapperClasses')
  template(v-for='widget in data.widgets')
    slot(:data='widget' :space-left='spaceLeft')
</template>

<script setup lang="ts">
type LevelWidget = Widgets & WidgetAlignment & WidgetGutter
const { data, spaceLeft } = defineProps<{
  data: LevelWidget
  spaceLeft?: SpaceLeft
}>()

const wrapperClasses = computed(() => {
  return [
    generateFlexAlignmentClasses(data.mobileAlignment, data.tabletAlignment, data.desktopAlignment),
    generateGapClasses(data.mobileGutter, data.tabletGutter, data.desktopGutter),
  ]
})
</script>
