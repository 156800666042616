<template lang="pug">
div(v-if='sections' :class='{ "relative z-[61]": $storyblok.isEditing.value }')
  template(v-for='widget in sections' :key='widget._uid')
    CmsWidget(:data='widget')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

defineProps<{
  sections: Widget[] // We just pass in the actual section data we want to render. Not the entire story.
  forceLazyLoad?: boolean
}>()
</script>
