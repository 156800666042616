<template lang="pug">
div(v-if='person?.name' class='flex items-center gap-x-3')
  img(
    v-if='person.profilePicture && person.profilePicture.filename'
    :src='$storyblok.formatSrc(person.profilePicture.filename)'
    width='40'
    height='40'
    class='w-10 h-10 overflow-hidden rounded-full'
  )
  img(v-else src='/images/avatar.svg' width='40' height='40' alt='' class='w-10 h-10')
  div(class='flex whitespace-nowrap')
    span(v-if='mode == "author"') Written by
    span(v-else-if='mode == "editor"') Edited by
    component(:is='getWrapperComp.component' v-bind='getWrapperComp.props') &nbsp;{{ person.name }}
</template>

<script setup lang="ts">
import { UniversalLink } from '#components'

const { $storyblok } = useNuxtApp()

const props = withDefaults(
  defineProps<{
    person: Author
    mode?: 'author' | 'editor'
  }>(),
  {
    mode: 'author',
  }
)

const getWrapperComp = computed(() => {
  const widgetOptions: Record<string, any> = {
    component: 'div',
    props: {},
  }

  if (props.person.profileUrl?.cached_url) {
    widgetOptions.component = UniversalLink
    widgetOptions.props.link = props.person.profileUrl.cached_url
  }
  return widgetOptions
})
</script>
