<template lang="pug">
div
  div(:id='`container-${instanceId}`' class='relative w-full h-0' style='padding-bottom: 56.25%')
    div(:id='instanceId' class='absolute top-0 left-0 w-full h-full')
</template>
<script setup lang="ts">
// In Nuxt 2, this has a video title and duration, we really shouldn't be using that for this anymore, the experience was crappy.  We should be using either video players
// or video thumbs that spawn popup video players, not inline video players with titles.
// main video = youtube /p/truxedo-sentry-ct-tonneau-cover/
const { video, autoPlay = false } = defineProps<{
  video: Video
  autoPlay?: boolean
}>()
useVideoSchema(video)

const instanceId = ref('')

let videoPlayer: YT.Player | null = null
let playerReady = false

onMounted(() => {
  runOnce('youtube-loader', () => {
    const script = document.createElement('script')
    script.src = 'https://www.youtube.com/iframe_api'
    document.head.appendChild(script)
  })

  instanceId.value = 'y' + video.id + Math.random()
  const { observe } = useRunOnVisible()

  // let the DOM update so we can get the element
  nextTick(() => {
    const el = document.getElementById(`container-${instanceId.value}`)
    if (!el) return

    observe(el, loadVideo, pauseVideo, false)
  })
})

async function loadVideo() {
  if (playerReady) return

  await waitUntilTrue(() => window?.YT?.Player !== undefined)

  videoPlayer = new window.YT.Player(instanceId.value, {
    videoId: video.id,
    //origin: window.location.origin, -- not a thing anymore it would seem
    playerVars: {
      autoplay: autoPlay ? 1 : 0,
      rel: 0,
    },
    events: {
      onReady() {
        playerReady = true
      },
    },
  })
}

function pauseVideo() {
  if (!videoPlayer || !playerReady || typeof videoPlayer?.pauseVideo !== 'function') return

  videoPlayer.pauseVideo()
}
</script>
