<template lang="pug">
Overlay(title='Order Status' unique-key='orderlogin' modal-width='SMALL' @closed='resetValues')
  BaseForm(id='orderStatusForm' @submit='orderLogin')
    template(v-if='errors')
      div(class='text-center text-sm text-white bg-danger rounded py-3 px-1 mb-3') {{ errors }}
    BaseFormInput(
      v-model='formData.orderNumber'
      placeholder='Order Number'
      name='orderNumber'
      validation='required'
      validation-lable='Order Number'
      :validation-messages='{ required: "Please enter an order number" }'
    )
    BaseFormInputEmail(
      v-model='formData.email'
      placeholder='Billing Email'
      name='email'
      validation='required'
      validation-label='Billing Email'
      :validation-messages='{ required: "Please enter a valid email" }'
    )
  div(class='text-xs text-center text') Enter your order number and billing email address to see the status of your order.
    template(v-if='$sitewideConfig.config.returnsUrl?.cached_url') &nbsp;For FAQs and returns, see our #[UniversalLink(:has-color-override='true' :link='$sitewideConfig.config.returnsUrl.cached_url') Return Policy]
  template(#footer)
    BaseButton(class='w-full' form='orderStatusForm' type='submit' :is-waiting='loading') Check Status
    template(v-if='hasExtraButton')
      div(class='mt-4 text-lg font-bold text-black') {{ $sitewideConfig.config.extraButtonHeading }}
      BaseButton(
        type='button'
        button-style='OUTLINE'
        color='BLACK'
        class='w-full mt-4'
        :to='$sitewideConfig.config.extraButtonLink.cached_url'
      ) {{ $sitewideConfig.config.extraButtonText }}
</template>

<script setup lang="ts">
const orderStore = useOrderStore()
const { $sitewideConfig, $overlay } = useNuxtApp()

const formData = ref({
  orderNumber: '',
  email: '',
})
const errors: Ref<string | null> = ref(null)
const loading = ref(false)

const hasExtraButton = computed(() => {
  return $sitewideConfig.config.extraButtonLink?.cached_url
})

async function orderLogin() {
  if (errors.value) errors.value = null
  loading.value = true
  try {
    const resp = await orderStore.orderLogin(formData.value.orderNumber, formData.value.email)
    await navigateTo({ name: 'order-status-orderId', params: { orderId: resp.id }, query: { token: resp.token } })
    $overlay.close()
  } catch (error) {
    loading.value = false
    errors.value = 'Sorry, we could not find your order. Please check your Order Number and Email.'
  }
}

function resetValues() {
  // Delay reset by 500ms to prevent some jank when the modal closes
  setTimeout(() => {
    if (errors.value) errors.value = null
    if (loading.value) loading.value = false
    if (formData.value.email) formData.value.email = ''
    if (formData.value.orderNumber) formData.value.orderNumber = ''
  }, 500)
}
</script>
