export function getMaxWidth(mobile: string, tablet: string, desktop: string) {
  const mobileMaxWidth = parseInt(mobile) || 0
  const tabletMaxWidth = parseInt(tablet) || mobileMaxWidth
  const desktopMaxWidth = parseInt(desktop) || tabletMaxWidth

  return {
    mobile: mobileMaxWidth,
    tablet: tabletMaxWidth,
    desktop: desktopMaxWidth,
  }
}
