<template lang="pug">
div(:key='video.id' :class='wistiaClass') &nbsp;
  div(
    class='wistia_swatch'
    style='height: 100%; left: 0; overflow: hidden; position: absolute; top: 0; transition: opacity 200ms; width: 100%'
  )
    img(
      ref='wistiaImage'
      :src='`https://fast.wistia.com/embed/medias/${video.id}/swatch`'
      alt=''
      style='filter: blur(5px); height: 100%; object-fit: contain; width: 100%; transform: scale(1.05)'
    )
</template>

<script setup lang="ts">
const {
  video,
  imageUrl = '',
  disableAutoPause = false,
} = defineProps<{
  video: Video
  imageUrl?: string
  disableAutoPause?: boolean
}>()

useWistia().loadVideo(video.id, disableAutoPause)
useVideoSchema(video)

const wistiaClass = computed(() => {
  return [
    'wistia_embed',
    `wistia_async_${video.id}`,
    'videoFoam=true',
    'autoPlay=true',
    'muted=true',
    'silentAutoPlay=true',
    'controlsVisibleOnLoad=false',
    'settingsControl=false',
    'playbar=false',
    'playButton=false',
    'smallPlayButton=false',
    'volumeControl=false',
    'fullscreenButton=false',
    'endVideoBehavior=loop',
    ...(imageUrl ? [`stillUrl=${imageUrl}`] : []),
  ].join(' ')
})
</script>
