<template lang="pug">
iframe(class='m-auto' :src='urlSrc' :style='style')
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()

interface IframeWidget extends Widget {
  url: string
  mobileWidth: string
  tabletWidth: string
  desktopWidth: string
  mobileHeight: string
  tabletHeight: string
  desktopHeight: string
}

const { data } = defineProps<{
  data: IframeWidget
}>()

const urlSrc = computed(() => {
  return data.url
})

const style = computed(() => {
  // Fallback width / height to previous device if not set in Storyblok
  const mobileWidth = data.mobileWidth
  const tabletWidth = data.tabletWidth || mobileWidth
  const desktopWidth = data.desktopWidth || tabletWidth

  const mobileHeight = data.mobileHeight
  const tabletHeight = data.tabletHeight || mobileHeight
  const desktopHeight = data.desktopHeight || tabletHeight

  let width = null
  let height = null

  // Set width / height based on device
  if ($device.value.isMobile) {
    width = mobileWidth
    height = mobileHeight
  } else if ($device.value.isDesktop) {
    width = desktopWidth
    height = desktopHeight
  } else {
    width = tabletWidth
    height = tabletHeight
  }

  return {
    width,
    height,
  }
})
</script>
