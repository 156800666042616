import type { RouteLocationRaw } from '#vue-router'

export default function () {
  const fitmentStore = useFitmentStore()
  const router = useRouter()

  function formatRoute(path: RouteLocationRaw, preserveFitment = false) {
    const resolvedRoute = router.resolve(path)
    const { params, query, meta, hash } = resolvedRoute
    let { name } = resolvedRoute

    // incoming url has fitment, deal with it
    if (params.makeSlug) {
      if (preserveFitment) return path
      // really this is just for going from M/MM pages to full MMY pages since pretty much all other incoming urls shouldn't have fitment
      name = name?.toString().replace('-mmy', '')
      delete params.makeSlug
      delete params.modelSlug
      delete params.year
    }

    const route = {
      name: name as string,
      params,
      query,
      hash: hash as string,
    }

    // If route is not fitable or if we don't have a make slug then don't add fitment
    if (meta.isFitable && fitmentStore.makeSlug) {
      if (!route.name.endsWith('mmy')) route.name += '-mmy'

      route.params.makeSlug = fitmentStore.makeSlug
      route.params.modelSlug = (route.params.makeSlug && fitmentStore.modelSlug) || ''
      route.params.year = (route.params.modelSlug && fitmentStore.year) || ''
    }

    return route
  }

  return {
    formatRoute,
  }
}
