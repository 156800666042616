<template lang="pug">
transition-group(
  tag='div'
  class='fixed inset-0 flex flex-col justify-end items-center px-3 py-3 pointer-events-none z-third-party md:p-6 md:justify-start md:items-end'
  enter-active-class='transition-opacity ease-out duration-200'
  enter-from-class='opacity-0'
  leave-active-class='transition-opacity ease-in duration-300'
  leave-to-class='opacity-0'
)
  template(v-for='notification in notifications' :key='notification.id')
    NotificationsSimple(:notification='notification' class='mb-1' @close='closeNotification(notification.id)')
</template>

<script setup lang="ts">
const { notifications, removeNotification } = useNotifications()

function closeNotification(id: number) {
  removeNotification(id)
}
</script>
