<template lang="pug">
picture
  source(
    v-if='desktopSrc'
    :srcset='desktopSrc'
    :width='desktopWidth'
    :height='desktopHeight'
    media='(min-width: 1024px)'
  )
  source(v-if='tabletSrc' :srcset='tabletSrc' :width='tabletWidth' :height='tabletHeight' media='(min-width: 768px)')
  //- Mobile source for: https://caniuse.com/mdn-api_htmlsourceelement_height issue in FF
  source(v-if='mobileSrc' :srcset='mobileSrc' :width='mobileWidth' :height='mobileHeight' media='(min-width: 0px)')
  img(
    v-bind='$attrs'
    :src='placeholderSrc'
    :loading='isLazy && !isImportant ? "lazy" : "eager"'
    :fetchpriority='isImportant ? "high" : "auto"'
  )
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

defineProps<{
  mobileSrc?: string
  mobileHeight?: string | number
  mobileWidth?: string | number
  tabletSrc?: string
  tabletHeight?: string | number
  tabletWidth?: string | number
  desktopSrc?: string
  desktopHeight?: string | number
  desktopWidth?: string | number
  isLazy?: Boolean
  isImportant?: Boolean
}>()

const placeholderSrc = "data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='0'%20height='0'/%3E"
</script>
