<template lang="pug">
div(v-if='isRealtruckBrand(brand)' class='flex items-center overflow-hidden')
  img(src='/images/the-r.svg' alt='' width='28' height='26')
</template>

<script setup lang="ts">
const { isRealtruckBrand } = useUtils()
const { brand } = defineProps<{
  brand: string
}>()
</script>
