export default function () {
  const { $sitewideConfig } = useNuxtApp()

  const brandFilters = useState('brandFilters', () => getBrandFilters())

  function getBrandFilters() {
    // Early out if we don't have any brands to filter by
    if (
      !$sitewideConfig.config.madeByRealTruckBrands?.data.length &&
      !$sitewideConfig.config.madeByRealTruckOnlineOnlyBrands?.data.length
    )
      return []

    return [
      ...new Set([
        ...$sitewideConfig.config.madeByRealTruckBrands.data,
        ...$sitewideConfig.config.madeByRealTruckOnlineOnlyBrands.data,
      ]),
    ]
  }

  function addFilter(searchCtx: any, filter: string = 'brand') {
    if (brandFilters.value.length === 0) return
    searchCtx.addFilters(filter, brandFilters.value)
  }

  return {
    addFilter,
  }
}
