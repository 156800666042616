<template lang="pug">
div(class='absolute top-full bg-white')
  ul(data-testid='linkMenu' class='bg-white rounded-sm shadow text-sm py-2 px-4')
    template(v-for='item in data.items')
      li(v-if='$storyblok.isVisible(item)' :key='item._uid' v-bind='$storyblok.bindStoryblokBridgeData(item)' class='py-1')
        UniversalLink(v-if='item.link' :link='item.link.url' class='text-gray-darkest font-normal whitespace-nowrap') {{ item.text }}
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

defineProps<{
  data: any
}>()
</script>
