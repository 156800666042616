export default function wireSaveCartListeners() {
  const segment = useSegmentHelper()
  const wishlist = useWishlist()
  const { $uiEvents } = useNuxtApp()

  $uiEvents.$on('saveForLaterAdded', emitSaveForLaterAdded)
  $uiEvents.$on('saveForLaterMoved', emitSaveForLaterMoved)
  $uiEvents.$on('saveForLaterDeleted', emitSaveForLaterDeleted)

  async function emitSaveForLaterAdded(item: any) {
    const obj = await getSavedCartData(item)
    segment.track('Save For Later Added', obj)
  }

  async function emitSaveForLaterMoved(item: any) {
    const obj = await getSavedCartData(item)
    segment.track('Save For Later Moved', obj)
  }

  async function emitSaveForLaterDeleted(item: any) {
    const obj = await getSavedCartData(item)
    segment.track('Save For Later Deleted', obj)
  }

  async function getSavedCartData(item: any) {
    const wishlistID = await wishlist.getWishlistId()
    return {
      wishlistID,
      item: formatWishlistItem(item),
    }
  }

  // item should always be populated but in the case it isn't for whatever reason, we'll default to an empty obj
  function formatWishlistItem(item: any = {}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { image, ...formattedItem } = item

    return formattedItem
  }
}
