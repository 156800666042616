export function generateBackgroundColorClass(backgroundColor: BackgroundColor) {
  /**
   * Tailwind Background Color Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * bg-white
   * bg-black
   * bg-primary
   * bg-primary-light
   * bg-secondary
   * bg-secondary-dark
   * bg-gray-light
   * bg-gray-lighter
   * bg-gray-lightest
   * bg-gray-dark
   * bg-gray-darker
   * bg-gray-darkest
   * bg-success
   * bg-success-light
   * bg-info
   * bg-info-dark
   * bg-warning-light
   * hover:bg-white
   * hover:bg-black
   * hover:bg-primary
   * hover:bg-primary-light
   * hover:bg-secondary
   * hover:bg-secondary-dark
   * hover:bg-gray-light
   * hover:bg-gray-lighter
   * hover:bg-gray-lightest
   * hover:bg-gray-dark
   * hover:bg-gray-darker
   * hover:bg-gray-darkest
   * hover:bg-success
   * hover:bg-success-light
   * hover:bg-info
   * hover:bg-info-dark
   * hover:bg-warning-light
   */
  if (!backgroundColor || backgroundColor === 'NONE') return ''
  return `bg-${backgroundColor.replace('_', '-').toLowerCase()}`
}

export function generateBorderColor(borderColor: string) {
  /**
   * Tailwind Border Color Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * border-black
   * border-gray
   * border-gray-light
   * hover:border-black
   * hover:border-gray
   * hover:border-gray-light
   */
  if (!borderColor || borderColor === 'NONE') return ''
  return `border-${borderColor.replace('_', '-').toLowerCase()}`
}

export function generateFontColorClass(fontColor: string) {
  /**
   * Tailwind Text Color Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * text-black
   * text-white
   * text-primary
   * text-action
   * text-action-dark
   * text-success
   * text-gray
   *
   * hover:text-black
   * hover:text-white
   * hover:text-primary
   * hover:text-action
   * hover:text-action-dark
   * hover:text-success
   * hover:text-gray
   */
  if (!fontColor || fontColor === 'INHERIT') return ''
  return `text-${fontColor.replace('_', '-').toLowerCase()}`
}

declare global {
  type BackgroundColor =
    | 'NONE'
    | 'WHITE'
    | 'BLACK'
    | 'PRIMARY_LIGHT'
    | 'WARNING_LIGHT'
    | 'SECONDARY_DARK'
    | 'SECONDARY'
    | 'GRAY_DARKER'
    | 'GRAY_LIGHTEST'
    | 'GRAY_LIGHT'
    | 'GRAY_LIGHTER'
    | 'SUCCESS'
    | 'SUCCESS_LIGHT'
    | 'GRAY_DARKEST'
    | 'INFO'
    | 'INFO_DARK'
    | 'PRIMARY'
    | 'GRAY_DARK'
}
