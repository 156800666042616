// For integrations/whatever to get the right value of the title of the page, we have to delay their call until an arbitrary amount of time has passed AFTER nav.. Yes, lame.

export default function () {
  const router = useRouter()

  function onRouteChanged(callback: () => void) {
    router.afterEach((to, from) => {
      // initial hit or query changed
      if (to?.path == from?.path) return

      runAfterDelay(callback, 1000)
    })
  }

  return {
    onRouteChanged,
  }
}
