<template lang="pug">
div(ref='target' :class='{ relative: !isFullWidth }')
  div(v-on='tooltipMode')
    slot
  div(class='bottom-[calc(100%+10px)] absolute' :class='[cssClassesWrapper, { "w-full": isFullWidth }]')
    div(
      v-if='isVisible'
      class='after:w-[15px] after:h-[15px] after:block after:bg-white after:border after:border-solid after:border-gray-light after:border-t after:border-r after:border-b-0 after:border-l-0 after:rotate-[135deg] after:absolute after:-bottom-[8px] after:m-auto after:left-0 after:right-0 relative text-left text-gray-darker py-3 px-3 leading-snug bg-white border border-solid border-gray-light rounded shadow font-normal normal-case z-50'
      :class='[cssClassesTooltip, { "w-64": !isFullWidth }]'
    )
      slot(name='text')
        InlineHtml(:text='text')
</template>

<script setup lang="ts">
const isVisible = ref(false)
const target = ref(null)
let timeoutId: ReturnType<typeof setTimeout> | undefined
onClickOutside(target, () => hideTooltip())

const {
  text = '',
  mode = 'click',
  hoverDelay = 0,
  isFullWidth = false,
  align = 'center',
} = defineProps<{
  text?: string
  mode?: 'click' | 'hover'
  hoverDelay?: number
  isFullWidth?: boolean
  align?: 'center' | 'left' | 'right'
}>()

const tooltipMode = computed(() => {
  if (mode === 'hover') return { mouseenter: showTooltip, mouseleave: hideTooltip }
  return { click: toggleTooltip }
})

const cssClassesWrapper = computed(() => {
  const classes = []

  if (align === 'right') classes.push('right-0')
  else if (align === 'center') classes.push('left-[50%]')

  return classes
})

const cssClassesTooltip = computed(() => {
  const classes = []

  if (align === 'right') classes.push('after:right-[15%] after:left-auto')
  else if (align === 'left') classes.push('-left-[15%] after:left-[15%] after:right-auto')
  else if (align === 'center') classes.push('-left-[50%]')

  return classes
})

function showTooltip() {
  timeoutId = setTimeout(() => {
    isVisible.value = true
  }, hoverDelay)
}

function hideTooltip() {
  clearTimeout(timeoutId)
  isVisible.value = false
}

function toggleTooltip() {
  isVisible.value = !isVisible.value
}
</script>
