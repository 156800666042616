<template lang="pug">
div(v-bind='columnEditable' :class='columnOrder')
  template(v-for='widget in data.widgets')
    slot(:widget='widget' :column-space-left='columnSpaceLeft')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const { columnIndex, data, layouts, mobileColumnLayout, tabletColumnLayout, desktopColumnLayout, spaceLeft } =
  defineProps<{
    columnIndex: number
    data: Widgets
    layouts: ColumnLayouts
    mobileColumnLayout: string
    tabletColumnLayout: string
    desktopColumnLayout: string
    spaceLeft: SpaceLeft
  }>()

const columnEditable = computed(() => {
  return $storyblok.bindStoryblokBridgeData(data)
})

const columnOrder = computed(() => {
  return getScreenClassPrefix(
    generateColumnOrder(mobileColumnLayout),
    generateColumnOrder(tabletColumnLayout),
    generateColumnOrder(desktopColumnLayout)
  )
})

const columnSpaceLeft = computed(() => {
  // Figure out what the value of each breakpoint is.
  const mobileLayout = mobileColumnLayout
  const tabletLayout = tabletColumnLayout !== 'INHERIT' ? tabletColumnLayout : mobileLayout
  const desktopLayout = desktopColumnLayout !== 'INHERIT' ? desktopColumnLayout : tabletLayout

  return {
    mobile: calculateSpaceLeft(spaceLeft.mobile, mobileLayout),
    tablet: calculateSpaceLeft(spaceLeft.tablet, tabletLayout),
    desktop: calculateSpaceLeft(spaceLeft.desktop, desktopLayout),
  }
})

function generateColumnOrder(layout: string) {
  /**
   * Tailwind Order Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * order-none md:order-none lg:order-none
   * order-first md:order-first lg:order-first
   *
   */

  if (layout === 'INHERIT') return ''
  if (layout === '2_COL_REVERSE' && columnIndex > 0) return 'order-first'
  return 'order-none'
}

function calculateSpaceLeft(spaceLeft: number, layout: string) {
  let columnPercent = 0

  // We need to cast this as a number or number[],
  // otherwise Array.isArray won't evaluate the constant array values as arrays for some reason?
  const selectedLayout = layouts[layout as keyof ColumnLayouts]?.percent as number | number[]

  if (!selectedLayout) return spaceLeft

  if (Array.isArray(selectedLayout)) {
    const columnValue = columnIndex % selectedLayout.length
    columnPercent = selectedLayout[columnValue]
  } else {
    columnPercent = selectedLayout
  }

  return Math.round(spaceLeft * (columnPercent / 100))
}
</script>
