<template lang="pug">
div(v-show='!fitmentDisplayStore.hasFullFitment' :class='{ relative: $storyblok.isEditing.value }')
  FitmentInline

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const fitmentDisplayStore = useFitmentDisplayStore()
</script>
