<template lang="pug">
div
  UniversalLink(
    :link='data.link'
    :open-new-window='data.linkTarget === "NEW_WINDOW"'
    tracking-event-type='ImageBullet'
    class='text-inherit'
  )
    div(class='flex' :class='displayClasses')
      div
        //- This should only ever be 1 image but it still comes in an array
        template(v-for='imageWidget in data.image')
          slot(:data='imageWidget')

      div(class='flex-1')
        template(v-for='textWidget in data.text')
          slot(:data='textWidget')
</template>

<script setup lang="ts">
interface ImageBulletWidget extends Widget {
  image: Widget[]
  link: string
  linkTarget: string
  text: Widget[]
  mobileImagePosition: string
  tabletImagePosition: string
  desktopImagePosition: string
  isVerticalAligned: string
}

const { data } = defineProps<{
  data: ImageBulletWidget
}>()

const displayClasses = computed(() => {
  return [
    getScreenClassPrefix(
      getPositionClass(data.mobileImagePosition),
      getPositionClass(data.tabletImagePosition),
      getPositionClass(data.desktopImagePosition)
    ),
    { 'items-center': data.isVerticalAligned === 'true' },
  ]
})

function getPositionClass(alignment: string) {
  /**
   * Tailwind Flex Display Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * flex-row md:flex-row lg:flex-row
   * flex-col md:flex-col lg:flex-col
   *
   */

  switch (alignment) {
    case 'LEFT':
      return 'flex-row'

    case 'TOP':
      return 'flex-col'

    default:
      return ''
  }
}
</script>
