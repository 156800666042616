export default function (video: Video) {
  const { getImageUrl } = useUrls()

  if (!video) return

  const obj: any = {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
  }
  if (video.duration) obj.duration = durationToISO8601(video.duration)
  if (video.uploadDate) obj.uploadDate = video.uploadDate
  if (video.title) obj.name = video.title

  // TODOLATER: Think about moving getImageUrl to receive an obj.  Here we're using trash params just so we can tell it to add a full url with the original image
  if (video?.thumbnail?.key)
    obj.thumbnailUrl = getImageUrl(video.thumbnail.key, video.thumbnail.filename, 0, 0, 0, 'c', true)

  // doesn't really seem avail for wistia, so this for now
  obj.description = 'a product video'

  if (video.type)
    obj.embedUrl =
      video.type.toLowerCase() === 'wistia'
        ? `https://fast.wistia.net/embed/iframe/${video.id}`
        : `https://www.youtube.com/embed/${video.id}`
  else obj.embedUrl = `https://fast.wistia.net/embed/iframe/${video.id}`

  useServerHead({
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(obj),
        tagPosition: 'bodyClose',
      },
    ],
  })
}
