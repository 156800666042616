<template lang="pug">
component(
  :is='displayComponent.is'
  v-if='renderComponent'
  ref='rootElement'
  data-element-type='universalLink'
  :class='dangerClass'
  v-bind='displayComponent.attributes'
  v-on='displayComponent.events'
  @click='trackLinkEvent'
)
  slot
</template>

<script setup lang="ts">
import { NuxtLink, AffirmSiteModal } from '#components'

const props = withDefaults(
  defineProps<{
    link: string
    element?: string
    trackingEventType?: string
    openNewWindow?: boolean
  }>(),
  {
    link: '',
    element: 'button',
    trackingEventType: '',
  }
)

const { $overlay, $sitewideConfig, $storyblok, $invoca, $scrollTo, $cognito, $chat, $uiEvents } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const routeBuilder = useRouteBuilder()
const fitmentDisplayStore = useFitmentDisplayStore()
const fitmentStore = useFitmentStore()
const { isIntegrationEnabled } = useUtils()
const { headerOffset } = useStickyOffset()

const rootElement: Ref<HTMLElement | ComponentPublicInstance | null> = ref(null)

// We provide the props to itself to check if nested parent UniversalLinks already have links
// This maintains reactivity in Storyblok when we provide the whole props object
provide('parent', props)
const parent = inject('parent', { link: '' })

// Show a red border around UniversalLinks that have parent UniversalLinks with a link, while in Storyblok
const dangerClass = computed(() => {
  if (parent.link && props.link && $storyblok.isEditing.value) return 'border-4 border-danger border-dashed'
})

// Do not render UniversalLinks if parent UniversalLinks already have a link, while not in Storyblok
// Will stop hydration errors from breaking the site
const renderComponent = computed(() => {
  if (parent.link && props.link && !$storyblok.isEditing.value) return false
  return true
})

const displayComponent = computed(() => {
  const rootDisplayObj: RootDisplayObject = {
    is: props.element,
    events: {},
    attributes: {},
  }

  if (!props.link) {
    // if there is no link then we want to render as a div
    rootDisplayObj.is = 'div'
    return rootDisplayObj
  }

  rootDisplayObj.attributes.class = ['cursor-pointer']

  // if the link type needs args then we have to split them here.
  // currently HIDE/SHOW are the only ones with args which is why this only looks for #
  const [linkType, linkArgs] = props.link.split('#')

  switch (linkType) {
    case 'AFFIRM':
      if (isIntegrationEnabled('affirm') && !$storyblok.isPreview.value) rootDisplayObj.is = AffirmSiteModal
      break

    case 'CHAT':
      rootDisplayObj.events.click = openChat
      break

    case 'FITMENT':
      rootDisplayObj.events.click = openFitment
      break

    case 'ORDER':
      rootDisplayObj.events.click = openOrderLogin
      break

    case 'ACCOUNT':
      rootDisplayObj.events.click = openAccountLogin
      break

    case 'MOBILE_MENU':
      rootDisplayObj.attributes['data-testId'] = 'mobileMenuLink'
      rootDisplayObj.events.click = openMobileMenu
      break

    case 'ISSD':
      rootDisplayObj.events.click = openISSD
      break

    case 'PHONE':
      rootDisplayObj.is = 'a'
      rootDisplayObj.attributes.href = `tel:${$sitewideConfig.config.phoneGeneral}`
      break
    case 'PROTEX':
      rootDisplayObj.is = 'a'
      rootDisplayObj.attributes.href = '/protex/configurator/?disableIntegration=liveperson'
      rootDisplayObj.attributes.target = '_blank' // New Window
      rootDisplayObj.attributes.rel = 'noopener, nofollow, noreferrer'
      break
    case 'HIDE':
      rootDisplayObj.events.click = () => hideSelector(linkArgs)
      break

    case 'SHOW':
      rootDisplayObj.events.click = () => showSelector(linkArgs)
      break

    default: {
      // normalize link
      const link = props.link.replace(`https://${$sitewideConfig.domain}`, '')

      // Links that open in new window should always open in new window
      if (props.openNewWindow) rootDisplayObj.attributes.target = '_blank'

      if (link.includes('#')) {
        rootDisplayObj.is = 'a'

        const [path, id] = link.split('#')

        // hash is on this page
        if (link.startsWith('#') || route.path.includes(path)) {
          rootDisplayObj.events.click = () => {
            $scrollTo(`#${id || path}`, 500, { offset: -headerOffset.value })
          }
        } else {
          // hash is our site
          if (!link.startsWith('http')) {
            const fittedRoute = routeBuilder.formatRoute(link, true)
            rootDisplayObj.is = NuxtLink
            rootDisplayObj.attributes.to = router.resolve(fittedRoute)
          } else rootDisplayObj.attributes.href = link
        }
      } else if (link.startsWith('tel') || link.startsWith('mailto')) {
        rootDisplayObj.is = 'a'
        rootDisplayObj.attributes.href = link
      } else {
        rootDisplayObj.is = NuxtLink

        // Check if link is an outbound link
        if (link.startsWith('http')) {
          rootDisplayObj.is = 'a'
          rootDisplayObj.attributes.target = '_blank'
          rootDisplayObj.attributes.rel = 'noopener, nofollow, noreferrer'
          rootDisplayObj.attributes.href = link
        } else {
          rootDisplayObj.attributes.to = routeBuilder.formatRoute(link, true)
        }

        // Vue3 Router-Link does not support the 'event' prop anymore, so to prevent navigation in Storyblok
        // we need to set the 'to' prop to nothing in editing mode
        // https://router.vuejs.org/guide/migration/#removal-of-event-and-tag-props-in-router-link
        if ($storyblok.isEditing.value) rootDisplayObj.attributes.to = ''
      }

      break
    }
  }

  // If we are editing, remove all click events from the event.
  // NOTE: we do this differently for nuxt-links, check out the nuxt-link case above.

  if ($storyblok.isEditing.value) rootDisplayObj.events = {}

  return rootDisplayObj
})

function hideSelector(selector: string) {
  $storyblok.hide(selector)
}

function showSelector(selector: string) {
  $storyblok.show(selector)
}

function openMobileMenu() {
  $overlay.open('mobileMenu')
}

function openOrderLogin() {
  $overlay.open('orderlogin')
}

function openAccountLogin() {
  if (!isIntegrationEnabled('cognito')) return
  $cognito.openModal(() => router.push({ name: 'account' }))
}

function openChat() {
  if (!isIntegrationEnabled('liveperson')) return
  $chat.open()
}

function openFitment() {
  if (fitmentDisplayStore.hasFullFitment) {
    navigateTo({
      name: 'mmy',
      params: {
        makeSlug: fitmentStore.$state.makeSlug,
        modelSlug: fitmentStore.$state.modelSlug,
        year: fitmentStore.$state.year,
      },
    })
    return
  }

  fitmentDisplayStore.showFitmentModal({ isMMYMode: true })
}

function openISSD() {
  if (fitmentDisplayStore.hasFullFitment) {
    navigateTo({
      name: 'mmy',
      params: {
        makeSlug: fitmentStore.$state.makeSlug,
        modelSlug: fitmentStore.$state.modelSlug,
        year: fitmentStore.$state.year,
      },
      query: { sameDayShipping: 'true' }, // TypeScript will yell at you if you use booleans in query strings
    })
    return
  }

  fitmentDisplayStore.showFitmentModal({ isSameDayShippingMode: true, isMMYMode: true })
}

// TODOLATER: We may want to re-think how we are sending trackingEvent data altogether
function trackLinkEvent() {
  // if we don't have a rootElement ref, link, or are in preview mode we should not send the track event
  if (!rootElement.value || !props.link || $storyblok.isPreview.value) return

  let linkText: string = ''
  let image: HTMLImageElement | null = null
  let button: HTMLButtonElement | null = null

  // We need to check if the rootElement ref returns an actual HTMLElement before trying to get
  // any values from it because a ref to a component (i.e. NuxtLink) does not return an HTMLElement
  if (rootElement.value instanceof HTMLElement) {
    linkText = rootElement.value.innerText.replace('\n', ' ')
    image = rootElement.value.getElementsByTagName('img')[0]
    button = rootElement.value.getElementsByTagName('button')[0]
  } else {
    // If its not an HTMLElement we can access the element using $el on the component
    linkText = rootElement.value.$el.innerText.replace('\n', ' ')
    image = rootElement.value.$el.getElementsByTagName('img')[0]
    button = rootElement.value.$el.getElementsByTagName('button')[0]
  }

  const eventData = {
    clickType: 'Link',
    clickUrl: props.link,
    linkText: linkText,
    imageUrl: '',
  }

  // If a button exists then change the click type
  if (button) {
    // TODOLATER: We may want to change how we do this now since the default UniversalLink tag will be <button>
    // there should never be a nested button inside it.
    eventData.clickType = 'Button'
  }

  // If an image exists then get the src
  if (image) {
    eventData.clickType = 'Image'
    eventData.imageUrl = image.src
  }

  // The trackingEvent that gets passed should overwrite any found type
  if (props.trackingEventType) eventData.clickType = props.trackingEventType

  $uiEvents.$emit('cmsLinkClicked', eventData)
}

onMounted(() => {
  if (isIntegrationEnabled('invoca') && props.link && props.link.startsWith('tel')) $invoca.changePhone()
})

interface RootDisplayObject {
  is: string | typeof NuxtLink | typeof AffirmSiteModal
  events: {
    click?: Function
  }
  attributes: {
    to?: string | Object
    event?: string
    class?: string[]
    href?: string
    target?: string
    rel?: string
    'data-testId'?: string
  }
}
</script>
