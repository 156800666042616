<template lang="pug">
div
  div(
    ref='videoEl'
    class='wistia_responsive_padding relative'
    :class='[{ "!p-0 relative": $slots.default }, $slots.default && cssWistia()]'
    style='padding-top: 56.25%'
  )
    div(:class='{ relative: $slots.default }' class='wistia_responsive_wrapper absolute left-0 top-0 h-full w-full')
      span(:class='[cssClass, { "popoverContent=Link": $slots.default }]')
        slot &nbsp;
  div(v-if='video.title && !$slots.default' class='mt-3 text-sm md:text-sm')
    div(class='font-bold')
      InlineHtml(:text='video.title')
    div(v-if='video.duration' class='text-gray-dark')
      | {{ durationToClock(video.duration) }}
</template>
<script setup lang="ts">
const { video } = defineProps<{ video: Video }>()

useWistia().loadVideo(video.id)
useVideoSchema(video)

// Tailwind Wistia Whitelist
// [&_.wistia\_click\_to\_play]:!w-full
// [&_.wistia\_click\_to\_play]:!h-full
function cssWistia() {
  return '[&_.wistia\\_click\\_to\\_play]:!w-full [&_.wistia\\_click\\_to\\_play]:!h-full'
}

const cssClass = computed(() => {
  return `wistia_embed wistia_async_${video.id} seo=false popover=true popoverAnimateThumbnail=true videoFoam=true relative inline-block h-full w-full`
})
</script>
