<template lang="pug">
div(:style='minHeight')
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()

const { data } = defineProps<{
  data: WidgetMinHeight
}>()

const minHeight = computed(() => {
  const minHeight = getMinHeight(data.mobileMinHeight, data.tabletMinHeight, data.desktopMinHeight, $device)
  const obj: Record<string, string> = {}

  if (minHeight) obj.minHeight = `${minHeight}px`
  return obj
})
</script>
