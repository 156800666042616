<template lang="pug">
div(class='mx-auto' :style='style')
  template(v-for='widget in data.widgets')
    slot(:data='widget' :space-left='constrainSpaceLeft')
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()

type ConstrainWidget = Widgets & WidgetMaxWidth
const props = defineProps<{
  data: ConstrainWidget
  spaceLeft: SpaceLeft
}>()

function calculateSpaceLeft(spaceLeft: number, maxWidth: number) {
  if (!maxWidth) return spaceLeft
  return spaceLeft * (maxWidth * 0.01)
}

const maxWidth = computed(() => {
  return getMaxWidth(props.data.mobileMaxWidth, props.data.tabletMaxWidth, props.data.desktopMaxWidth)
})

const constrainSpaceLeft = computed(() => {
  return {
    mobile: calculateSpaceLeft(props.spaceLeft.mobile, maxWidth.value.mobile),
    tablet: calculateSpaceLeft(props.spaceLeft.tablet, maxWidth.value.tablet),
    desktop: calculateSpaceLeft(props.spaceLeft.desktop, maxWidth.value.desktop),
  }
})

const style = computed(() => {
  let max = null

  // Choose maxWidth value based upon device
  if ($device.value.isMobile) max = maxWidth.value.mobile
  else if ($device.value.isDesktop) max = maxWidth.value.desktop
  else max = maxWidth.value.tablet

  // Early Out
  if (!max) return {}
  // Clamp max width value between 0 and 100
  max = `${Math.min(Math.max(max, 0), 100)}%`

  return {
    'max-width': max,
  }
})
</script>
