/**
 *
 * @param {object} shippingData
 * @param {boolean} includeDate - Used by Segment but front end needs text and date separated
 * @returns
 */
export default function (shippingData, includeDate = false) {
  if (!shippingData.estimatedDeliveryDate) return

  let text = 'Arrives'
  if (includeDate) text = `${text} ${getEddDate(shippingData)}`
  return text
}
