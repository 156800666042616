import validate from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/validate.js";
import route_45slashes_45global from "/home/circleci/project/middleware/routeSlashes.global.ts";
import set_45fitment_45from_45url_45global from "/home/circleci/project/middleware/setFitmentFromUrl.global.ts";
import setup_45fitment_45global from "/home/circleci/project/middleware/setupFitment.global.ts";
import manifest_45route_45rule from "/home/circleci/project/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  route_45slashes_45global,
  set_45fitment_45from_45url_45global,
  setup_45fitment_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}