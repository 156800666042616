<template lang="pug">
div(data-page-type='banner' class='affirm-site-modal')
  slot
</template>

<script setup lang="ts">
const { $affirm } = useNuxtApp()

onMounted(() => {
  $affirm.load()
})
</script>
