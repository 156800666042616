<template lang="pug">
div(class='flex' :class='alignmentClasses')
  UniversalLink(:link='linkUrl' element='div' :open-new-window='data.linkTarget === "NEW_WINDOW"')
    PictureWrapper(v-bind='pictureBindings' v-on='pictureEvents')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const $lightbox = inject($storyblok.sectionLightboxProvide)
let lightboxIndex: number | undefined

interface ImageWidget extends Widget, WidgetMaxWidth, WidgetAlignment {
  linkUrl: string
  linkTarget: string
  svgImage: Record<string, any>
  fillSize?: {
    width: string
    height: string
  }
  svgSize: {
    width: number | null
    height: number | null
  }
  mobileImage: StoryblokAsset
  tabletImage: StoryblokAsset
  desktopImage: StoryblokAsset
  isInLightbox: 'false' | 'true'
}

const props = defineProps<{
  data: ImageWidget
  spaceLeft: SpaceLeft
  forceLazyLoad?: boolean
  isImportant?: boolean
}>()

onMounted(() => {
  if (props.data.isInLightbox === 'true') {
    // Find the image src we want
    let caption: string | undefined = undefined
    let file = null
    if (props.data.desktopImage?.filename) {
      caption = props.data.desktopImage.alt
      file = props.data.desktopImage.filename
    } else if (props.data.tabletImage?.filename) {
      caption = props.data.desktopImage.alt
      file = props.data.tabletImage.filename
    } else if (props.data.mobileImage?.filename) {
      caption = props.data.desktopImage.alt
      file = props.data.mobileImage.filename
    }
    if (!file) return {}

    // Get the image properties
    const { src, width, height } = $storyblok.getNormalizedAsset(file, 1000)
    if (!src || !width || !height) return {}

    if ($lightbox) lightboxIndex = $lightbox.add(src, width, height, caption)
  }
})

const maxWidth = computed(() => {
  return getMaxWidth(props.data.mobileMaxWidth, props.data.tabletMaxWidth, props.data.desktopMaxWidth)
})

const linkUrl = computed(() => {
  if (props.data.isInLightbox === 'true') return // Disable Links if Lightbox
  if (props.data.linkUrl) return props.data.linkUrl
  return
})

const pictureEvents = computed(() => {
  // Add a click event if this image is in a lightbox
  if (props.data.isInLightbox === 'true' && $lightbox) {
    return {
      click: () => {
        if (lightboxIndex === undefined) return
        $lightbox.open(lightboxIndex)
      },
    }
  }
  return {}
})

// Check once when created
const isLazy = $storyblok.shouldLazyLoad(props.forceLazyLoad)
const pictureBindings = computed(() => {
  // getSvgData() is Deprecated but remains for backward compatibility
  const imageData = props.data.svgImage?.filename ? getSvgData() : getFileData()

  return {
    isLazy,
    isImportant: props.isImportant,
    class: { 'cursor-pointer': props.data.isInLightbox === 'true' },
    ...imageData,
  }
})

const alignmentClasses = computed(() => {
  return generateFlexAlignmentClasses(
    props.data.mobileAlignment,
    props.data.tabletAlignment,
    props.data.desktopAlignment
  )
})

// Normalize data returned by getSvgData and getFileData
interface imageData {
  alt?: string
  mobileSrc?: string
  mobileWidth?: number
  mobileHeight?: number

  tabletSrc?: string
  tabletWidth?: number
  tabletHeight?: number

  desktopSrc?: string
  desktopWidth?: number
  desktopHeight?: number
}

/**
 * TODOLATER: Can we ditch this moving to vue3??
 * Deprecated
 * We no longer support SVG's in imageWidget
 * However we had to remain backwards compatible.
 * For current SVG code See: integrations/storyblok/_components/widgets/svgWidget.vue
 */
function getSvgData(): imageData | {} {
  // Early out if we do not have a width/height
  if (!props.data.svgSize.width || !props.data.svgSize.height) return {}

  const svgOptions = {
    width: props.data.svgSize.width,
    height: props.data.svgSize.height,
  }

  const { width: mobileWidth, height: mobileHeight } = $storyblok.getNormalizedAsset(
    { src: props.data.svgImage.filename, ...svgOptions },
    maxWidth.value.mobile || props.spaceLeft.mobile
  )

  const { width: tabletWidth, height: tabletHeight } = $storyblok.getNormalizedAsset(
    { src: props.data.svgImage.filename, ...svgOptions },
    maxWidth.value.tablet || props.spaceLeft.tablet
  )

  const { width: desktopWidth, height: desktopHeight } = $storyblok.getNormalizedAsset(
    { src: props.data.svgImage.filename, ...svgOptions },
    maxWidth.value.desktop || props.spaceLeft.desktop
  )
  // The Source does not change, so we will always have all 3 versions to return.
  return {
    mobileSrc: props.data.svgImage.filename,
    mobileWidth,
    mobileHeight,

    tabletSrc: props.data.svgImage.filename,
    tabletWidth,
    tabletHeight,

    desktopSrc: props.data.svgImage.filename,
    desktopWidth,
    desktopHeight,
  }
}

function getFileData(): imageData | {} {
  const sources: {
    altText: string
    mobile: string
    tablet: string
    desktop: string
  } = {
    altText: '',
    mobile: '',
    tablet: '',
    desktop: '',
  }

  if (props.data.mobileImage.filename) {
    sources.altText = props.data.mobileImage.alt
    sources.mobile = props.data.mobileImage.filename
    sources.tablet = props.data.mobileImage.filename
    sources.desktop = props.data.mobileImage.filename
  }

  if (props.data.tabletImage.filename) {
    sources.altText = sources.altText || props.data.tabletImage.alt
    sources.tablet = props.data.tabletImage.filename
    sources.desktop = props.data.tabletImage.filename
  }

  if (props.data.desktopImage.filename) {
    sources.altText = sources.altText || props.data.desktopImage.alt
    sources.desktop = props.data.desktopImage.filename
  }

  const assetOptions: AssetOptions = {}

  if (
    props.data.fillSize &&
    !isNaN(parseInt(props.data.fillSize.width)) &&
    !isNaN(parseInt(props.data.fillSize.height))
  ) {
    assetOptions.fillWithWhite = true
    assetOptions.width = parseInt(props.data.fillSize.width)
    assetOptions.height = parseInt(props.data.fillSize.height)
  }

  const {
    src: mobileSrc,
    width: mobileWidth,
    height: mobileHeight,
  } = $storyblok.getNormalizedAsset(sources.mobile, maxWidth.value.mobile || props.spaceLeft.mobile, assetOptions)

  const {
    src: tabletSrc,
    width: tabletWidth,
    height: tabletHeight,
  } = $storyblok.getNormalizedAsset(sources.tablet, maxWidth.value.tablet || props.spaceLeft.tablet, assetOptions)

  const {
    src: desktopSrc,
    width: desktopWidth,
    height: desktopHeight,
  } = $storyblok.getNormalizedAsset(sources.desktop, maxWidth.value.desktop || props.spaceLeft.desktop, assetOptions)

  // Only return the ones that exist
  return {
    alt: sources.altText,
    ...(mobileSrc && {
      mobileSrc,
      mobileWidth,
      mobileHeight,
    }),
    ...(tabletSrc && {
      tabletSrc,
      tabletWidth,
      tabletHeight,
    }),
    ...(desktopSrc && {
      desktopSrc,
      desktopWidth,
      desktopHeight,
    }),
  }
}
</script>
