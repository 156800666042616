// Initializes client side stuff (so error.vue/app.vue can render)
export default defineNuxtPlugin(() => {
  onNuxtReady(() => {
    // display store requires Algolia and that integration is started after plugins, so wait
    const rootStore = useRootStore()
    const displayStore = useFitmentDisplayStore()

    rootStore.setGlobalId()
    displayStore.initSelects()

    // Tell the window that nuxt is ready. Currently used in the playwright test suite
    window.dispatchEvent(new Event('nuxt:ready'))
  })
})
