<template lang="pug">
div
  component(:is='logoOptions.is' :class='logoOptions.class' class='fill-current w-full')
</template>

<script setup lang="ts">
import SameDayIcon from '@/assets/same-day-icon.svg?component'
import SameDayQuickShipLogo from '@/assets/same-day-quickship.svg?component'
import SameDayRapidShipLogo from '@/assets/same-day-rapidship.svg?component'

const { $sitewideConfig } = useNuxtApp()
const { sameDayShippingTextColor } = useSameDayShippingDisplay()
const { isIcon = false, isWhite = false } = defineProps<{
  isIcon?: boolean
  isWhite?: boolean
}>()

const logoOptions = computed(() => {
  const obj = {
    is: $sitewideConfig.config.sameDayShippingMode === 'RAPIDSHIP' ? SameDayRapidShipLogo : SameDayQuickShipLogo,
    class: [],
  } as any

  if (isWhite) obj.class.push('text-white')
  else obj.class.push(sameDayShippingTextColor)

  if (isIcon) obj.is = SameDayIcon
  isIcon ? obj.class.push('h-auto') : obj.class.push('h-[10px]')

  return obj
})
</script>
