<template lang="pug">
div
  div(
    class='[&_.playButton]:hover:cursor-pointer [&_.playButton]:hover:w-full [&_.playButton]:hover:h-full [&_.playButton]:hover:bg-black [&_.playButton]:hover:opacity-[35%] relative leading-none'
  )
    slot
      img(v-bind='videoThumbNailProps' class='cursor-pointer' :alt='video.title' loading='lazy')
    div(
      class='playButton absolute w-16 h-10 m-auto top-0 right-0 bottom-0 left-0 bg-gray opacity-75 transition-all duration-100 ease-in-out'
    )
    div(
      class='border-solid border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-l-[14px] border-l-white absolute m-auto top-0 right-0 bottom-0 left-0 w-4 h-5 border-left-4 bo'
    )
  div(v-if='video.title' class='mt-2 text-xs md:text-sm')
    div(class='text-gray-darkest font-bold')
      InlineHtml(:text='video.title')
    div(v-if='video.duration' class='text-gray-dark')
      | {{ durationToClock(video.duration) }}
</template>

<script setup lang="ts">
const { getImageUrl } = useUrls()
const { video, hiRes = false } = defineProps<{ video: Video; hiRes?: boolean }>()
useVideoSchema(video)
const thumbSize = computed(() => {
  return {
    filename: hiRes ? 'maxresdefault.jpg' : 'mqdefault.jpg',
    width: hiRes ? 1280 : 320,
    height: hiRes ? 720 : 180,
  }
})

const videoThumbNailProps = computed(() => {
  let thumbUrl = ''
  // TODOLATER: This is the YouTubeThumb, why do we need to check if the type is YOUTUBE?
  if (video.thumbnail?.key) thumbUrl = getImageUrl(video.thumbnail.key, video.thumbnail.filename, 320, 180) || ''
  else if (video.type === 'YOUTUBE') thumbUrl = `//img.youtube.com/vi/${video.id}/${thumbSize.value.filename}`
  else thumbUrl = getImagePlaceholder(320, 180)
  return {
    src: thumbUrl,
    class: `${video.type.toLowerCase()}-thumbnail`,
    width: thumbSize.value.width,
    height: thumbSize.value.height,
  }
})
</script>
