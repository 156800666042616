<template lang="pug">
Overlay(unique-key='confirmLocationPopup' modal-width='SMALL' @closed='close')
  template(#header)
    div(class='w-full flex justify-center items-center')
      div(class='title' data-testid='locationModalTitle') VERIFY YOUR LOCATION

  div(v-if='!isLocationValid' class='bg-warning-platform rounded text-gray-darkest p-6 mb-6')
    p Unfortunately, we don't currently ship to your location. Please try a different zip code.

  div(class='p-4 text-center text-gray-darkest flex flex-col items-center')
    MapLocationIcon(class='text-primary w-20 h-20 fill-current')
    h4(class='mb-1 font-bold') {{ $geolocation.overrideFormattedLocation || $geolocation.formattedLocationWithZip }}
    p Current Location

  div
    input(
      ref='zip'
      v-model='zipInput'
      class='bg-white border border-gray-light p-3 w-full rounded appearance-none text-gray-darkest'
      placeholder='Enter Zip Code'
      type='text'
      inputmode='numeric'
      maxlength='5'
      data-testid='locationModalInput'
      @keyup.enter='determineLocation'
    )

    div(v-if='errorText' class='text-danger text-xs' data-testid='locationModalError')
      | {{ errorText }}

  template(#footer)
    BaseButton(class='w-full' :is-disabled='zipInput.length < 5' data-testid='locationModalButton' @click='determineLocation') Save
</template>

<script setup lang="ts">
import MapLocationIcon from '@/assets/map-location.svg?component'

const { $geolocation } = useNuxtApp()

const errorText = ref('')
const zipInput = ref('')
const isLocationValid = ref(true)
const zip = ref<HTMLElement | null>(null)

watch(zipInput, () => {
  errorText.value = ''
})

onMounted(() => {
  if (zip.value) zip.value.focus()
})

function close() {
  // Reset valid location on closing modal
  isLocationValid.value = true
  $geolocation.closeModal()
}

async function determineLocation() {
  if (!/^\d{5}$/.test(zipInput.value)) {
    errorText.value = `Please enter a valid 5-digit zip code`
    return
  }

  try {
    await $geolocation.setGeoLocationByZip(zipInput.value)
    close()
    zipInput.value = ''
  } catch (error) {
    if (error.message === 'INVALID_ADDRESS') isLocationValid.value = false
    else errorText.value = `Please enter a valid zip code`
  }
}
</script>
