export default function (makes: any[]) {
  // Early out if we have less than 16 makes ("Select Make" needs to be accounted for as well)
  if (makes.length < 17) return

  // List of top makes IN ORDER,
  // "Select Make" should be the first value so we don't have to re-order it later
  const topMakes = ['select make', 'chevy', 'dodge', 'ford', 'gmc', 'jeep', 'nissan', 'ram', 'toyota']

  // Sort makes
  makes.sort((a, b) => {
    const aValue = a.text?.toLowerCase() // lowercase to normalize data just in case
    const bValue = b.text?.toLowerCase()

    if (topMakes.indexOf(aValue) === -1) return 1
    if (topMakes.indexOf(bValue) === -1) return -1
    return topMakes.indexOf(aValue) - topMakes.indexOf(bValue)
  })

  // Count how many top makes there were so we can get the index of the last item
  const lastIndex = makes.reduce((count, make) => {
    if (topMakes.includes(make.text.toLowerCase())) count++
    return count
  }, 0)

  // Splice in a separator based on the last item index.
  makes.splice(lastIndex, 0, {
    text: '----------------------',
    value: '-2', // Add "value" so other stuff does not blow up.
    slug: '-2', // Not sure if "slug" is needed, but safety. This covers fitment and order control options
    isDisabled: true,
    isHidden: false,
  })
}
