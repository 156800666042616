<template lang="pug">
div(class='overflow-hidden' :class='{ "relative inline-block": playOnScroll }')
  video(
    ref='thisVideo'
    v-bind='videoAttributes'
    :poster='posterSource'
    class='h-full object-cover'
    @canplay='setCanPlay(true)'
    @ended='onEnd'
  )
    source(:src='videoSource' :type='videoType')
</template>

<script setup lang="ts">
const props = defineProps<{
  videoUrl: string
  posterImageUrl: string
  isLazy: boolean
  playOnScroll?: boolean
  replayOnScroll?: boolean
}>()

const thisVideo = ref()
const isLazyLoaded = ref(!props.isLazy)
let videoEnded = false
let canPlay = true
let retried = 0

interface VideoAttributes {
  playsinline: boolean
  muted: boolean
  width: string | number
  height: string | number
  preload: string
  autoplay: boolean
  loop: boolean
}
const videoAttributes = computed(() => {
  const obj: VideoAttributes = {
    playsinline: true,
    muted: true,
    width: '100%',
    height: '100%',
    preload: '',
    autoplay: false,
    loop: false,
  }

  // Determine the W/H based on the Poster Image
  if (props.posterImageUrl) {
    const { width, height } = getAssetDimensionsFromUrl(props.posterImageUrl)
    if (width) obj.width = width
    if (height) obj.height = height
  }

  if (props.playOnScroll) {
    obj.muted = true
    obj.preload = 'metadata'
  } else {
    // Background videos autoplay on a loop
    obj.autoplay = true
    obj.loop = true
    obj.preload = 'none'
  }

  return obj
})

const videoType = computed(() => {
  if (props.videoUrl) return 'video/' + props.videoUrl.split('.').pop()
  return 'video/mp4'
})
const videoSource = computed(() => {
  if (isLazyLoaded.value) return props.videoUrl
  return undefined
})
const posterSource = computed(() => {
  if (isLazyLoaded.value) return props.posterImageUrl
  return undefined
})

const lazyload = () => {
  isLazyLoaded.value = true
}

const setCanPlay = (boolean: boolean) => {
  canPlay = boolean
}

const onEnd = () => {
  // The video has reached its natural end.
  videoEnded = true
}

const tryPlay = () => {
  // We do not want to replay on scroll if replayOnScroll is false
  // and the video has played through once already.
  if (!props.replayOnScroll && videoEnded) {
    return
  }

  // If the video is loaded and ready, play it!
  if (canPlay) {
    play()
    return
  }

  // if not ready, wait a bit and try again
  // Retry up to 20 times or give up
  if (retried >= 20) return

  setTimeout(() => {
    retried++
    tryPlay()
  }, 250)
}

const play = () => {
  thisVideo.value.play()
  videoEnded = false
}

const pause = () => {
  thisVideo.value.pause()
}

onMounted(() => {
  if (props.isLazy) {
    // When the Video is within 300px of the users scroll position
    // set this.isLazyLoaded = true which will cause the videoSource to be set
    // and trigger the Watcher to load() the video.
    const { observe } = useRunOnVisible({ rootMargin: '600px' })
    observe(thisVideo.value, lazyload, () => {}, true)
  }

  if (props.playOnScroll) {
    // When most (threshold .8) of the video is within the viewport we want to play it
    // and when it exits the viewport threshold we want to pause it.
    const { observe } = useRunOnVisible({ threshold: 0.8 })
    observe(thisVideo.value, tryPlay, pause, false)
  }
  watch(videoSource, () => {
    thisVideo.value.load()
  })
})
</script>
