export default function wireMyAccountListeners() {
  const segment = useSegmentHelper()
  const { $uiEvents } = useNuxtApp()

  $uiEvents.$on('cognitoSignUp', (userId) => {
    segment.emitIdentify({ userId })
    segment.track('Account Sign Up', { userId })
  })
  $uiEvents.$on('cognitoSignIn', () => {
    segment.emitIdentify()
    segment.track('Account Sign In', {})
  })
}
