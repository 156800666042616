export default function (truthyCallback: Function) {
  return new Promise<void>((resolve) => {
    const id = setInterval(() => {
      const result = truthyCallback()
      if (result === true) {
        clearInterval(id)
        resolve()
      }
    }, 200)
  })
}
