export function getMinHeight(mobileMinHeight: string, tabletMinHeight: string, desktopMinHeight: string, $device: any) {
  const styles: Record<string, string> = {}

  // This just covers off on when the data does not exist
  if (!mobileMinHeight && !tabletMinHeight && !desktopMinHeight) return null

  if (mobileMinHeight && mobileMinHeight !== 'INHERIT') {
    styles.mobileMinHeight = mobileMinHeight
    styles.tabletMinHeight = styles.mobileMinHeight
    styles.desktopMinHeight = styles.mobileMinHeight
  }

  if (tabletMinHeight && tabletMinHeight !== 'INHERIT') {
    styles.tabletMinHeight = tabletMinHeight
    styles.desktopMinHeight = styles.tabletMinHeight
  }

  if (desktopMinHeight && desktopMinHeight !== 'INHERIT') {
    styles.desktopMinHeight = desktopMinHeight
  }

  if ($device.value.isMobile) return styles.mobileMinHeight
  if ($device.value.isDesktop) return styles.desktopMinHeight
  return styles.tabletMinHeight
}
