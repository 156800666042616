<template lang="pug">
div(:class='{ relative: $storyblok.isEditing.value }')
  FormKit(data-cnstrc-search-form name='searchBarForm' type='form' :actions='false' @submit='submitSearch')
    div(class='relative z-30')
      div(class='flex')
        div(class='relative flex-1')
          input(
            id='searchBarInput'
            ref='searchBar'
            v-model='query'
            type='text'
            name='searchBarInput'
            :placeholder='searchPlaceholder'
            autocomplete='off'
            data-testid='searchBar'
            class='w-full max-w-full h-9 px-3 py-2 text text-sm bg-white rounded-l rounded-r-none border placeholder:text'
            data-cnstrc-search-input
            @keyup.esc='closeSearch'
          )

          button(
            v-if='query.length > 0'
            class='absolute right-0 inset-y-0 px-3.5 flex justify-center items-center pointer-events-auto'
            type='button'
            @click='closeSearch'
          )
            CloseIcon(class='w-3 h-3')

        BaseButton(
          data-cnstrc-search-submit-btn
          type='submit'
          class='h-[36px] w-10'
          color='PRIMARY'
          is-inline
          has-custom-padding
        )
          SearchIcon(class='w-4 h-4 [&_path]:fill-black')

      div(
        v-if='query && (searchSuggestionResults.length > 0 || productResults.length > 0)'
        data-cnstrc-autosuggest
        data-testid='searchResults'
        class='absolute bg-white top-full inset-x-0 py-3 space-y-3 text-black lg:min-w-[400px]'
      )
        div(v-if='searchSuggestionResults.length > 0' class='px-3')
          p(class='text-lg font-bold mb-1') Search Suggestions

          ul
            template(v-for='searchSuggestion in searchSuggestionResults' :key='searchSuggestion.value')
              li(data-cnstrc-item-section='Search Suggestions' :data-cnstrc-item-name='searchSuggestion.label')
                UniversalLink(class='block text-black' :link='searchSuggestion.url' @click='closeSearch')
                  InlineHtml(class='text-sm' :text='searchSuggestion.label')

        div(v-if='productResults.length > 0')
          p(class='text-lg font-bold mb-1 px-3') Products

          //- Remove these classes when we switch back to the new display
          ul(class='divide-y divide-gray-lighter')
            template(v-for='product in productResults' :key='product.value')
              li(
                data-cnstrc-item-section='Products'
                :data-cnstrc-item-id='product.itemId'
                :data-cnstrc-item-name='product.label'
              )
                //- New version for after ab test
                //- Make sure to uncomment the image prop in the data transformer below on line 180
                //- UniversalLink(
                //-   class='block px-3 py-2 text-black hover:bg-gray-lighter hover:no-underline focus:bg-gray-lighter focus:no-underline'
                //-   :link='product.url'
                //-   @click='closeSearch'
                //- )
                //-   div(class='flex items-center gap-x-3')
                //-     div(v-if='product.image' class='flex-none')
                //-       img(class='max-w-10' :alt='product.label' :src='product.image' width='40' height='40')

                //-     InlineHtml(class='text-sm' :text='product.label')
                UniversalLink(
                  class='block px-3 py-4 text-black hover:bg-gray-lighter hover:no-underline focus:bg-gray-lighter focus:no-underline'
                  :link='product.url'
                  @click='closeSearch'
                )
                  div(class='flex Zitems-center gap-x-4')
                    div(v-if='product.image' class='flex-none')
                      img(class='max-w-16' :alt='product.label' :src='product.image' width='64' height='48')

                    div(class='space-y-1 self-center')
                      InlineHtml(class='leading-[1.2]' :text='product.label')
                      p(v-if='!$sitewideConfig.config.nonTransactionalEnabled')
                        span(class='uppercase text-xs') From:&nbsp;
                        strong {{ product.price }}

  //- This is a transparent shade to catch the clicks off of the search bar
  div(v-if='query' class='fixed inset-0 z-20' @click='closeSearch')

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
import SearchIcon from '@/assets/search.svg?component'
import CloseIcon from '@/assets/x.svg?component'

const { searchText } = defineProps<{
  searchText: string
}>()

const { $constructor, $sitewideConfig, $uiEvents } = useNuxtApp()
const urls = useUrls()
const fitmentStore = useFitmentStore()
const constructorFitment = useConstructorFitment()

const searchBar = ref<HTMLElement | null>(null)
const query = ref('')

interface Result {
  itemId: string
  image?: string
  label: string
  url: string
}

const searchSuggestionResults = ref<Result[]>([])
const productResults = ref<Result[]>([])

const searchPlaceholder = computed(() => {
  if (!fitmentStore.fitmentFormatted) return searchText
  return `Search ${fitmentStore.fitmentFormatted} Parts`
})

// Watch query and get autocomplete results
watchDebounced(query, getAutocompleteResults, { debounce: 250, maxWait: 1500 })

async function getAutocompleteResults(query: string) {
  if (!query) return

  emitSearchEvent(query)

  const productFilters: Record<string, any> = {
    isAccessory: 'False',
  }

  // NonTransactional Needs to only show RT Products
  if ($sitewideConfig.config.nonTransactionalEnabled) {
    const brands = $sitewideConfig.config.madeByRealTruckBrands.data ?? []
    if (brands.length > 0) productFilters.brand = brands
  }

  const fitmentFilters = constructorFitment.getFitmentAttributes()
  if (fitmentFilters) {
    productFilters[fitmentFilters.name] = fitmentFilters.value
  }

  try {
    const resp = await $constructor.client.autocomplete.getAutocompleteResults(query, {
      filtersPerSection: {
        Products: productFilters,
      },
      resultsPerSection: {
        Products: 5,
        'Search Suggestions': 5,
      },
    })

    searchSuggestionResults.value = resp.sections['Search Suggestions'].map((searchSuggestion) => ({
      itemId: '',
      label: searchSuggestion.value,
      url: `/search/?q=${searchSuggestion.value}`,
    }))

    productResults.value = resp.sections.Products.map((product) => ({
      itemId: product.data?.variation_id ?? '',
      label: product.value,
      url: `/p/${product.data?.productLineSlug}/`,
      // image: urls.getImageUrl(product.data?.image.key, product.data?.image.filename, 40, 40),
      image: urls.getImageUrl(product.data?.image.key, product.data?.image.filename, 64, 48),
      price: formatCents(product.data?.salePrice),
    }))
  } catch (error) {
    console.log('Error getting autocomplete results', error)
  }
}

function closeSearch() {
  query.value = ''
  searchSuggestionResults.value = []
  productResults.value = []

  if (searchBar.value) searchBar.value.focus()
}

async function submitSearch() {
  if (!query.value) return

  emitSearchEvent(query.value)

  await navigateTo({ name: 'search', query: { q: query.value } })

  closeSearch()
}

function emitSearchEvent(query: string) {
  $uiEvents.$emit('searched', { query })
}
</script>
