export default function wireInstallernetListeners() {
  const segment = useSegmentHelper()
  const { $uiEvents } = useNuxtApp()
  const rootStore = useRootStore()
  const { formatCartProduct, formatProductData } = useSegmentProductsHelper()

  // Installernet
  $uiEvents.$on('installernetOffered', emitInstallationOffered)
  $uiEvents.$on('installationAdded', emitInstallationAdded)
  $uiEvents.$on('installationRemoved', emitInstallationRemoved)

  // Installernet Events
  function emitInstallationAdded(lastItem: any) {
    if (!lastItem) return
    // Format the product data
    const productData = formatCartProduct(lastItem)
    segment.track('Installation Added', { cart_id: rootStore.permId, ...productData })
  }

  function emitInstallationRemoved(item: any) {
    if (!item) return
    // Format the product data
    const productData = formatCartProduct(item)
    segment.track('Installation Removed', { cart_id: rootStore.permId, ...productData })
  }

  function emitInstallationOffered(product: any) {
    if (!product) return
    segment.track('Installation Offered', formatProductData(product))
  }
}
