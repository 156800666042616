export const useRootStore = defineStore('Root', () => {
  const { getApiUrl } = useUrls()
  const globalId = ref<string>()
  const permId = ref<string>('')
  const originalPermId = ref<string>('')
  const clientIp = ref<string>()

  async function setGlobalId() {
    const url = getApiUrl('globalId')
    try {
      const { globalId: id } = await $fetch<{ globalId: string }>(`${url}?permId=${permId.value}`, {
        credentials: 'include',
      })
      if (id) globalId.value = id
    } catch (error) {
      // We just don't set it if it fails.
    }
  }

  function setPermId(id: string) {
    if (!originalPermId.value) originalPermId.value = id
    permId.value = id
  }

  return {
    globalId,
    permId,
    originalPermId,
    clientIp,
    setGlobalId,
    setPermId,
  }
})
