<template lang="pug">
BaseButton(
  v-bind='buttonEditable'
  :to='data.link'
  :button-style='data.style'
  class='py-2 px-8'
  :class='buttonClass'
  :color='data.fillColor'
  :has-custom-padding='true'
  :has-custom-text-color='data.style !== "OUTLINE"'
  :icon='buttonIcon'
  :open-new-window='data.linkTarget === "NEW_WINDOW"'
) {{ data.text }}
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const { data, mobileIsFullWidth, tabletIsFullWidth, desktopIsFullWidth } = defineProps<{
  data: ButtonItem
  mobileIsFullWidth: 'INHERIT' | StringBoolean
  tabletIsFullWidth: 'INHERIT' | StringBoolean
  desktopIsFullWidth: 'INHERIT' | StringBoolean
}>()

const buttonEditable = computed(() => {
  return $storyblok.bindStoryblokBridgeData(data)
})

const buttonIcon = computed(() => {
  if (!data.icon || data.icon === 'NONE') return
  return data.icon
})

const buttonClass = computed(() => {
  const classes = []
  // if full/inherit, mobile is full
  if (mobileIsFullWidth === 'false') classes.push('w-auto')
  else classes.push('w-full')

  if (tabletIsFullWidth !== 'INHERIT') {
    if (tabletIsFullWidth === 'false') classes.push('md:w-auto')
    else classes.push('md:w-full')
  }

  if (desktopIsFullWidth !== 'INHERIT') {
    if (desktopIsFullWidth === 'false') classes.push('lg:w-auto')
    else classes.push('lg:w-full')
  }

  if (data.style !== 'OUTLINE') classes.push(generateFontColorClass(data.textColor))

  return classes
})
</script>
