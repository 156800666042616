export default function (metaConfig: MetaConfig) {
  const { $sitewideConfig } = useNuxtApp()
  const route = useRoute()

  if (!metaConfig?.isNoIndex && !$sitewideConfig.config.noIndexWholeSite) {
    useServerHead({
      link: [
        {
          rel: 'canonical',
          href: `https://${$sitewideConfig.domain}${route.path}`,
        },
      ],
    })
  } else {
    useServerSeoMeta({
      robots: 'noindex,nofollow',
    })
  }

  function decodeTitle(oldTitle: string | ComputedRef<string>) {
    if (!oldTitle) return ''
    const title = typeof oldTitle === 'string' ? oldTitle : oldTitle.value
    return title.replace(/&quot;/gi, '"').replace(/&amp;/gi, '&')
  }

  // This is the only reactive seo 'thing', everything else is one and done (server hit only)
  useHead({
    title: decodeTitle(metaConfig.title),
  })

  useServerSeoMeta({
    ogTitle: metaConfig.title,
    ogUrl: `https://${$sitewideConfig.domain}${route.fullPath}`, // we want the full path for sharing since they could have filters (but yeah could have extra junk too like glid)
    ogSiteName: $sitewideConfig.config.siteName,
    ogType: 'website',
    ogImage: prefixSocialImageUrl(metaConfig.socialImageUrl),
    ...(metaConfig.description && {
      ogDescription: metaConfig.description,
      description: metaConfig.description,
    }),
    twitterCard: 'summary_large_image',
    twitterLabel1: 'Call us',
    twitterData1: $sitewideConfig.config.phoneGeneral,
    twitterLabel2: 'Shop online',
    twitterData2: `at ${$sitewideConfig.domain}`,
    applicationName: $sitewideConfig.config.siteName,
  })

  /**
   * Ensure that the social image url is prefixed with the domain
   * unless it is already prefixed (in Dev Mode it may be prefixed with 'https://assets.thiecommerce.com')
   */
  function prefixSocialImageUrl(url?: string) {
    if (!url) return
    return url.startsWith('https') ? url : `https://${$sitewideConfig.domain}${url}`
  }
}
interface MetaConfig {
  isNoIndex?: boolean
  title: string | ComputedRef
  description?: string
  socialImageUrl?: string
}
