<template lang="pug">
//- Jake - 1/26/21
//- We still need this base class because other components target this to add margin
//- I don't know why we have an ID on this but I left it for now
//- Answer: ID is used by pages/catalog as scroll target for scrollToTopOfResults() - Jordan 2/4/21
div(v-if='!fitmentDisplayStore.hasFullFitment' id='fitment-inline' data-testId='fitmentInline' class='fitment-inline')
  div(class='lg:container')
    div(class='flex flex-wrap flex-col lg:flex-row lg:items-center')
      div(class='px-3 pt-3 pb-3' :class='[getSelectCount === 4 ? "lg:pb-0 lg:w-full xl:pb-3 xl:w-auto xl:flex-1" : "lg:flex-1"]')
        p(class='text-2xl font-bold uppercase leading-none text-center xl:text-lg')
          | Select Your
          span(class='xl:hidden') &nbsp;
          span(class='xl:block xl:text-black xl:font-extrabold xl:text-4xl') Vehicle

      div(class='px-3 lg:py-3 xl:w-2/3' :class='[getSelectCount === 4 ? "lg:w-4/5" : "lg:w-1/2"]')
        div(class='lg:flex lg:flex-wrap')
          template(v-for='select in fitmentDisplayStore.modalRequiredSelectTypes' :key='select.type')
            FitmentSelect(
              data-testId='requiredSelect'
              class='mx-auto pb-1 max-w-md lg:max-w-none lg:p-1 lg:mx-0'
              :class='[getSelectCount === 4 ? "lg:flex-1" : "lg:w-1/3"]'
              :select='select'
              :current-value='select.currentValue'
              @changed='fitmentDisplay.selectChanged'
            )

      div(class='px-3 py-3 text-center lg:flex-1')
        BaseButton(
          class='disabled:bg-gray-light disabled:text-white'
          data-testid='fitmentInlineSave'
          :is-disabled='!fitmentDisplay.allDoneSelecting.value'
          @click='fitmentDisplay.saveFitment(true)'
        ) Shop Now
</template>

<script setup lang="ts">
const fitmentDisplayStore = useFitmentDisplayStore()
const fitmentDisplay = useFitmentDisplay()

onMounted(() => {
  fitmentDisplay.layoutPosition.value = 'inline'
})

const getSelectCount = computed(() => {
  return fitmentDisplayStore.modalRequiredSelectTypes.length
})
</script>
