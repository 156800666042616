export function generatePaddingClasses(mobile: SideSize, tablet: SideSize, desktop: SideSize) {
  /**
   * Tailwind Padding Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * pt-0 pr-0 pb-0 pl-0 md:pt-0 md:pr-0 md:pb-0 md:pl-0 lg:pt-0 lg:pr-0 lg:pb-0 lg:pl-0
   * pt-2 pr-2 pb-2 pl-2 md:pt-2 md:pr-2 md:pb-2 md:pl-2 lg:pt-2 lg:pr-2 lg:pb-2 lg:pl-2
   * pt-4 pr-4 pb-4 pl-4 md:pt-4 md:pr-4 md:pb-4 md:pl-4 lg:pt-4 lg:pr-4 lg:pb-4 lg:pl-4
   * pt-8 pr-8 pb-8 pl-8 md:pt-8 md:pr-8 md:pb-8 md:pl-8 lg:pt-8 lg:pr-8 lg:pb-8 lg:pl-8
   * pt-12 pr-12 pb-12 pl-12 md:pt-12 md:pr-12 md:pb-12 md:pl-12 lg:pt-12 lg:pr-12 lg:pb-12 lg:pl-12
   */
  return getScreenClassPrefix(
    generateSideSizeClasses('p', mobile),
    generateSideSizeClasses('p', tablet),
    generateSideSizeClasses('p', desktop)
  )
}

export function generateMarginClasses(mobile: SideSize, tablet: SideSize, desktop: SideSize) {
  /**
   * Tailwind Margin Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * mt-0 mr-0 mb-0 ml-0 md:mt-0 md:mr-0 md:mb-0 md:ml-0 lg:mt-0 lg:mr-0 lg:mb-0 lg:ml-0
   * mt-2 mr-2 mb-2 ml-2 md:mt-2 md:mr-2 md:mb-2 md:ml-2 lg:mt-2 lg:mr-2 lg:mb-2 lg:ml-2
   * mt-4 mr-4 mb-4 ml-4 md:mt-4 md:mr-4 md:mb-4 md:ml-4 lg:mt-4 lg:mr-4 lg:mb-4 lg:ml-4
   * mt-8 mr-8 mb-8 ml-8 md:mt-8 md:mr-8 md:mb-8 md:ml-8 lg:mt-8 lg:mr-8 lg:mb-8 lg:ml-8
   * mt-12 mr-12 mb-12 ml-12 md:mt-12 md:mr-12 md:mb-12 md:ml-12 lg:mt-12 lg:mr-12 lg:mb-12 lg:ml-12
   */

  return getScreenClassPrefix(
    generateSideSizeClasses('m', mobile),
    generateSideSizeClasses('m', tablet),
    generateSideSizeClasses('m', desktop)
  )
}

function generateSideSizeClasses(prefix: string, sideObj: Record<string, any>) {
  return Object.entries(sideObj || {}).reduce((acc: string[], [side, size]) => {
    let className = prefix

    // Add side prefix
    switch (side) {
      case 'top':
        className += 't'
        break
      case 'right':
        className += 'r'
        break
      case 'bottom':
        className += 'b'
        break
      case 'left':
        className += 'l'
        break
      // this check will ensure that we only return classes for values that we specify
      default:
        return acc
    }

    // Add hyphen to construct the proper class
    className += '-'

    // Add size value
    switch (size) {
      case 'NONE':
        className += '0'
        break
      case 'XSMALL':
        className += '2'
        break
      case 'SMALL':
        className += '4'
        break
      case 'MEDIUM':
        className += '8'
        break
      case 'LARGE':
        className += '12'
        break
      // this check will ensure that we only return classes for values that we specify
      default:
        return acc
    }

    acc.push(className)

    return acc
  }, [])
}
