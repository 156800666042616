export default function (shippingData) {
  // Make sure we have an isEligibleFreeShipping prop
  if (!shippingData?.isEligibleFreeShipping) return

  const shippingText =
    'Free' +
    // if free then add with space before and after, otherwise just a space
    (shippingData.isFreight ? ' Freight ' : ' ') +
    'Shipping'

  return shippingText
}
