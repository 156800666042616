// https://github.com/rigor789/vue-scrollto/issues/736
import VueScrollTo from 'vue-scrollto'
import type { ScrollOptions } from 'vue-scrollto'

export default defineNuxtPlugin((nuxtApp) => {
  /**
   * Scrolls the browser to an element
   * @param id The selector to scroll to.
   * @example #footer
   */
  const scroll = (id: Element | string, duration = 200, options: ScrollOptions = { easing: [0.12, 0, 0.39, 0] }) => {
    VueScrollTo.scrollTo(id, duration, options)
  }

  nuxtApp.vueApp.directive('scroll-to', {
    mounted(el, binding) {
      const id = binding.value
      el.addEventListener('click', (e: Event) => {
        e.preventDefault()
        scroll(id)
      })
    },
  })

  return {
    provide: {
      scrollTo: scroll,
    },
  }
})
