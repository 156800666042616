/* Since middleware uses vue-router this is ran 3 times server side (whereas serverMiddleware is pure H3/Nitro which runs 2 times).
Network-wise, they are the same.  The browser receives a 301 header.
middleware/c/exterior-accessories -> /c/exterior-accessories
middleware/c/exterior-accessories -> /c/exterior-accessories/
middleware/c/exterior-accessories/ -> /c/exterior-accessories/
*/
export default defineNuxtRouteMiddleware((to) => {
  if (!to.path.endsWith('/') && !to.path.includes('.')) {
    const urlWithSlash = to.fullPath.replace(to.path, to.path + '/')
    return navigateTo(urlWithSlash, { redirectCode: 301 })
  }
})
