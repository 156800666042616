export function applyPrefixToClass(prefix: string, value: string | string[]) {
  if (!value) return
  return Array.isArray(value) ? value.map((val) => `${prefix}${val}`) : `${prefix}${value}`
}

export function getScreenClassPrefix(
  mobileClass: string | string[],
  tabletClass: string | string[],
  desktopClass: string | string[]
) {
  return [mobileClass, applyPrefixToClass('md:', tabletClass), applyPrefixToClass('lg:', desktopClass)]
}
