<template lang="pug">
UniversalLink(
  v-if='data.link'
  v-bind='$storyblok.bindStoryblokBridgeData(data)'
  :link='data.link.url'
  :data-level='level'
  :data-root-text='rootText'
  class='block text-black'
) {{ data.text }}
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

withDefaults(
  defineProps<{
    data: any
    rootText?: string
    level?: number
  }>(),
  {
    rootText: '',
    level: 0,
  }
)
</script>
