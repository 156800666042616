<template lang="pug">
div(v-if='editors || authors' class='text-sm')
  p(class='pb-1') Updated on {{ updatedDate }}
  div(v-if='$storyblok.isPreview.value')
    p Authors Placeholder ( Written by: {{ authors.length }} | Edited by: {{ editors.length }})

  div(v-else class='min-h-[40px]')
    div(v-if='authors' class='flex flex-wrap gap-x-8 gap-y-2 items-center')
      template(v-for='author in getAuthors' :key='author._uid')
        CmsWidgetAuthorNameList(:person='author')

      template(v-if='otherAuthors')
        //- Mobile Only
        div(class='flex justify-center cursor-pointer items-center text-xs' @click='showOthers = !showOthers') +{{ moreCount }} Others
          img(
            src='@/assets/arrow.svg'
            class='w-3 ml-2'
            :class='{ "transform rotate-180": !showOthers }'
            height='12'
            width='12'
            alt='Previous Page'
          )
      template(v-else)
        template(v-for='editor in editors' :key='editor._uid')
          CmsWidgetAuthorNameList(:person='editor' mode='editor')

    //- Mobile only
    div(v-if='showOthers' class='mt-2 space-y-2')
      template(v-for='author in otherAuthors' :key='author._uid')
        CmsWidgetAuthorNameList(:person='author')
      template(v-for='editor in editors' :key='editor._uid')
        CmsWidgetAuthorNameList(:person='editor' mode='editor')
</template>

<script setup lang="ts">
const { $storyblok, $device } = useNuxtApp()

const showOthers = ref(false)
const authors = computed(() => {
  const result = $storyblok.getAuthors()
  return result
})

const editors = computed(() => {
  const result = $storyblok.getEditors()
  return result
})

const getAuthors = computed(() => {
  if ($device.value.isMobile) {
    return authors.value.slice(0, 1)
  }
  return authors.value
})

const otherAuthors = computed(() => {
  if (!$device.value.isMobile) return null
  const others = JSON.parse(JSON.stringify(authors.value))
  others.shift() // Remove the first one so we can return the rest
  return others
})

const updatedDate = computed(() => {
  const published = $storyblok.getPublishedAt()
  return published ? formatDate(published) : ''
})

const moreCount = computed(() => {
  return authors.value.length + editors.value.length - 1
})

function formatDate(date) {
  return new Date(date).toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}
</script>
