<template lang="pug">
div
  template(v-if='showConstructor')
    slot(name='constructor')

  template(v-else)
    slot(name='original')
</template>

<script setup lang="ts">
const { $constructor } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()

const showConstructor = computed(() => {
  return (
    isIntegrationEnabled('constructor') && isIntegrationEnabled('constructorTest') && $constructor.test.showConstructor
  )
})
</script>
