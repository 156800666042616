/*
https://support.speedcurve.com/docs/rum-js-api#luxlabel
https://support.speedcurve.com/docs/single-page-applications
*/
export default defineNuxtPlugin((nuxtApp) => {
  const { isIntegrationEnabled, isDev } = useUtils()
  const { $geolocation, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration speedcurve')
  if (!isIntegrationEnabled('speedcurve'))
    return {
      provide: {
        speedcurve: {
          track() {},
        },
      },
    }

  const { $sitewideConfig } = useNuxtApp()
  const router = useRouter()
  const id = $sitewideConfig.config.speedcurveId
  let pageFinished = false
  let isFirstHit = true
  let label = ''

  /* eslint-disable */
  // prettier-ignore
  // @ts-ignore
  window.LUX=function(){function n(){return Date.now?Date.now():+new Date}var r,e=n(),t=window.performance||{},a=t.timing||{navigationStart:(null===(r=window.LUX)||void 0===r?void 0:r.ns)||e};function o(){return t.now?(r=t.now(),Math.floor(r)):n()-a.navigationStart;}(window.LUX=window.LUX||{}).ac=[],window.LUX.addData=function(n,r){return window.LUX.cmd(["addData",n,r])},window.LUX.cmd=function(n){return window.LUX.ac.push(n)},window.LUX.getDebug=function(){return[[e,0,[]]]},window.LUX.init=function(){return window.LUX.cmd(["init"])},window.LUX.mark=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.mark)return t.mark.apply(t,n);var e=n[0],a=n[1]||{};void 0===a.startTime&&(a.startTime=o());window.LUX.cmd(["mark",e,a])},window.LUX.markLoadTime=function(){return window.LUX.cmd(["markLoadTime",o()])},window.LUX.measure=function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.measure)return t.measure.apply(t,n);var e,a=n[0],i=n[1],u=n[2];e="object"==typeof i?n[1]:{start:i,end:u};e.duration||e.end||(e.end=o());window.LUX.cmd(["measure",a,e])},window.LUX.send=function(){return window.LUX.cmd(["send"])},window.LUX.ns=e;var i=LUX;if(window.LUX_ae=[],window.addEventListener("error",(function(n){window.LUX_ae.push(n)})),window.LUX_al=[],"function"==typeof PerformanceObserver&&"function"==typeof PerformanceLongTaskTiming){var u=new PerformanceObserver((function(n){for(var r=n.getEntries(),e=0;e<r.length;e++)window.LUX_al.push(r[e])}));try{u.observe({type:"longtask"})}catch(n){}}return i}();
  window.LUX.sendBeaconOnPageHidden = true
  window.LUX.auto = false

  setRegion()

  // let LUX handle the first hit (auto defaults to 'true')
  router.beforeEach((to, from) => {
    if (to.fullPath != from.fullPath) isFirstHit = false
  })

  nuxtApp.hook('page:loading:start', () => {
    if (!isFirstHit) window.LUX.init()

    pageFinished = false
  })

  // page:loading:end is currently called twice when going to different page types so we have to look for the 'real' one
  // https://github.com/nuxt/nuxt/issues/26535
  nuxtApp.hook('page:finish', (page) => {
    pageFinished = true
  })

  nuxtApp.hook('page:loading:end', () => {
    if (!pageFinished || !label) return
    window.LUX.label = label
    window.LUX.markLoadTime()

    // in testing this delay was a must, otherwise label and path would both be old values in the network tab?!
    setTimeout(() => {
      window.LUX.send()
    }, 1000)

    label = ''
  })

  onNuxtReady(() => {
    if (!performance || !performance.measure) return
    performance.measure('NUXT_LOADING')
  })

  const script = document.createElement('script')
  script.src = `https://cdn.speedcurve.com/js/lux.js?id=${id}`
  script.type = 'text/javascript'
  script.async = true
  script.defer = true
  script.crossOrigin = 'anonymous'
  if (isDev()) {
    script.onload = () => {
      window.LUX.forceSample()
    }
  }

  document.body.appendChild(script)

  return {
    provide: {
      speedcurve: {
        track(text: string) {
          label = text
        },
      },
    },
  }

  function setRegion() {
    if (!isIntegrationEnabled('geolocation')) return

    const west = ['WA', 'OR', 'ID', 'MT', 'WY', 'CA', 'NV', 'UT', 'CO']
    const southWest = ['AZ', 'NM', 'TX', 'OK']
    const midWest = ['ND', 'MN', 'WI', 'MI', 'SD', 'IA', 'IL', 'IN', 'OH', 'NE', 'KS', 'MO']
    const southEast = ['AR', 'LA', 'MS', 'KY', 'TN', 'AL', 'FL', 'GA', 'SC', 'NC', 'VA', 'WV', 'DC']
    const northEast = ['MD', 'PA', 'DE', 'NJ', 'NY', 'CT', 'RI', 'MA', 'VT', 'NH', 'ME']
    const region = $geolocation.region

    if (west.includes(region)) window.LUX.addData('Region', 'West')
    else if (southWest.includes(region)) window.LUX.addData('Region', 'Southwest')
    else if (midWest.includes(region)) window.LUX.addData('Region', 'Midwest')
    else if (southEast.includes(region)) window.LUX.addData('Region', 'Southeast')
    else if (northEast.includes(region)) window.LUX.addData('Region', 'Northeast')
  }
})
