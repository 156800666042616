export const useOrderStore = defineStore('Order', () => {
  const { getApiUrl } = useUrls()
  const notifications = useNotifications()
  const orderStatus: Ref<Order | null> = ref(null)
  const trackingDetails: Ref<TrackingDetails | null> = ref(null)

  async function orderLogin(orderNumber: string, email: string) {
    const url = getApiUrl('orderLookup')
    return (await $fetch(url, { params: { orderNumber, email } })) as OrderLoginResponse
  }

  async function getOrderStatusByToken(orderId: string, token: string) {
    const url = getApiUrl('orders') + orderId + `?token=${token}`
    const resp = (await $fetch(url)) as Order

    if (!resp.payment) resp.payment = { paymentProvider: '' }

    orderStatus.value = resp
  }

  async function getOrderTrackingDetails(trackingId: string) {
    const url = getApiUrl('tracking') + trackingId
    const resp = (await $fetch(url)) as TrackingDetails

    trackingDetails.value = resp
  }

  async function cancelOrder(orderId: string, cancellationDetails: CancellationDetails) {
    try {
      const url = getApiUrl('orders') + orderId
      const resp = (await $fetch(url, {
        method: 'PUT',
        body: {
          shippingStatus: 'CANCELLED',
          cancellationDetails,
        },
      })) as OrderResponse

      orderStatus.value = resp.data

      const formattedMessage = `${resp.msg.charAt(0).toUpperCase()}${resp.msg.slice(1)}.`
      notifications.addSuccess('Cancellation Submitted', formattedMessage)
    } catch (error) {
      throw error?.data
    }
  }

  async function cancelOrderLine(
    orderId: string,
    token: string,
    itemId: string | number,
    cancellationDetails: CancellationDetails
  ) {
    try {
      const url = getApiUrl('orders') + orderId + '/items/' + itemId
      const resp = (await $fetch(url, {
        method: 'PUT',
        body: {
          shippingStatus: 'CANCELLED',
          cancellationDetails,
        },
      })) as OrderResponse

      // Re-fetch the order data after getting a response
      await getOrderStatusByToken(orderId, token)

      const formattedMessage = `${resp.msg.charAt(0).toUpperCase()}${resp.msg.slice(1)}.`
      notifications.addSuccess('Cancellation Submitted', formattedMessage)
    } catch (error) {
      throw error?.data
    }
  }

  async function returnItem(orderId: string, token: string, itemId: string | number, returnDetails: ReturnDetails) {
    try {
      const url = getApiUrl('orders') + orderId + '/items/' + itemId
      const resp = (await $fetch(url, {
        method: 'PUT',
        body: {
          shippingStatus: 'RETURNED',
          returnDetails,
        },
      })) as OrderResponse

      // Re-fetch the order data after getting a response
      await getOrderStatusByToken(orderId, token)

      const formattedMessage = `${resp.msg.charAt(0).toUpperCase()}${resp.msg.slice(1)}.`
      notifications.addSuccess('Return Submitted', formattedMessage)
    } catch (error) {
      throw error?.data
    }
  }

  function resetOrderStatus() {
    orderStatus.value = null
  }

  function resetTrackingDetails() {
    trackingDetails.value = null
  }

  return {
    orderStatus,
    trackingDetails,
    orderLogin,
    getOrderStatusByToken,
    getOrderTrackingDetails,
    cancelOrder,
    cancelOrderLine,
    returnItem,
    resetOrderStatus,
    resetTrackingDetails,
  }
})

declare global {
  interface Order {
    isCancelable?: boolean
    items: OrderItem[]
    orderDetails: {
      orderDate: string
      status: OrderStatus
      tracking?: OrderTracking[]
    }
    orderId: string
    orderNumber?: string
    payment: OrderPayment
    shippingContact?: OrderContact
    summary: {
      cartSubtotal: number
      giftCardSubtotal: number
      grandTotal: number
      shippingSubtotal: number
      taxSubtotal: number
    }
  }
  interface OrderItem {
    discount?: {
      text: string
    }
    fitmentData?: {
      appNote?: string
      bedName?: string
      bodyName?: string
      engineName?: string
      makeName?: string
      modelName?: string
      year?: string
    }
    image?: {
      filename: string
      key: string
    }
    info?: OrderItemInfo[]
    installation?: any
    isCancelable?: boolean
    isReturnable?: boolean
    itemId: string | number
    itemSubtotal: number
    meta?: OrderItemMeta[]
    mpn?: string
    originalPrice: number
    originalSubtotal: number
    productName?: string
    promo?: {
      text: string
    }
    qty?: number
    returnExpires: string
    returnable?: string
    salePrice: number
    saleSubtotal: number
    shippingStatus: OrderStatus
    sku?: string
    tracking?: OrderTracking[]
    warranty?: any
    guaranteedFit: 'PHONE' | 'SITE'
  }

  interface OrderPayment {
    brand?: string
    lastFour?: string
    paymentProvider: string
  }

  type OrderStatus =
    | 'PROCESSING'
    | 'SHIPPED'
    | 'OUT_FOR_DELIVERY'
    | 'DELIVERED'
    | 'PENDING'
    | 'PENDING_FULFILLMENT'
    | 'IN_TRANSIT'
    | 'PARTIALLY_SHIPPED'
    | 'SORTING_COMPLETE'
    | 'ARRIVED_AT_POST_OFFICE'
    | 'ARRIVED_AT_USPS_FACILITY'
    | 'ARRIVED_AT_USPS_ORIGIN_FACILITY'
    | 'SHIPPING_LABEL_CREATED'
    | 'RETURN_IN_PROGRESS'
    | 'REFUND_IN_PROGRESS'
    | 'CANCELLED'
    | 'RETURNED'
    | 'PENDING_CANCELLATION'
}

interface TrackingDetails {
  courierLink?: string
  deliveryDate?: string
  estimatedDelivery?: string
  history: TrackingHistory[]
  items?: OrderItem[]
  orderDate: string
  orderNumber: string
  shippingContact: OrderContact
  status: OrderStatus
  trackingNumber: string
}

interface OrderContact {
  address1?: string
  address2?: string
  city?: string
  company?: string
  email?: string
  fullName?: string
  stateId?: string
  zipcode?: string
}

interface OrderItemInfo {
  key: string
  value: string
}

interface OrderItemMeta {
  key: string
  value: string
}

interface OrderTracking {
  arrivalDate?: string
  status: OrderStatus
  trackingId?: string
  trackingNumber?: string
}

interface TrackingHistory {
  location?: string
  message?: string
  status: OrderStatus
  timestamp: string
}

interface CancellationDetails {
  reason?: string
  comment?: string
  customerCancellationReason?: string
}

interface ReturnDetails {
  reason?: string
  comment?: string
  compensation?: {
    type?: string
    comment?: string
  }
  contact?: {
    preferredMethodOfContact?: string
    phone?: string
  }
  fitment?: {
    year?: string
    make?: string
    model?: string
  }
}

interface OrderResponse {
  code: string
  data: any
  msg: string
}

interface OrderLoginResponse {
  id: string
  token: string
}
