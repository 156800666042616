export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration invoca')
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('invoca')) return

  const networkId = $sitewideConfig.config.invocaNetworkId
  const tagId = $sitewideConfig.config.invocaTagId

  runOnNuxtReady(() => {
    /* eslint-disable */
    // prettier-ignore
    (function(i,n,v,o,c,a) { i.InvocaTagId = o; var s = n.createElement('script'); s.type = 'text/javascript';
        s.async = true; s.src = ('https:' === n.location.protocol ? 'https://' : 'http://' ) + v;
        var fs = n.getElementsByTagName('script')[0]; fs.parentNode?.insertBefore(s, fs);
        })(window, document, 'solutions.invocacdn.com/js/invoca-latest.min.js', `${networkId}/${tagId}`);
    /* eslint-enable */
  })

  return {
    provide: {
      invoca: {
        changePhone,
      },
    },
  }
})

function changePhone() {
  if (window.Invoca && typeof window.Invoca.shouldRun === 'function' && window.Invoca.shouldRun()) {
    if (window.Invoca.PNAPI && typeof window.Invoca.PNAPI.run === 'function') window.Invoca.PNAPI.run()
  }
}

declare global {
  interface Window {
    InvocaTagId: string
    Invoca: {
      shouldRun(): boolean
      PNAPI: {
        run(): void
      }
    }
  }
}
