<template lang="pug">
div(v-bind='itemEditable')
  UniversalLink(
    :link='data.link'
    :open-new-window='data.linkTarget === "NEW_WINDOW"'
    tracking-event-type='ImageCarouselItem'
    class='relative block text-inherit'
  )
    div(class='relative')
      template(v-for='image in data.image')
        slot(:widget='image')

      div(v-if='shadowSettings' class='absolute inset-0 bg-black' :style='shadowSettings')

    div(v-if='data.textWidgets && data.textWidgets.length > 0' :class='textWidgetPosition')
      template(v-for='textWidget in data.textWidgets')
        slot(:widget='textWidget')

    //- When we can remove this when all the old carousels are no longer using the old schema
    //- We only want to render the label when:
    //- we have a label and...
    //- when textWidgets is falsy or when textWidgets exists and its empty
    div(
      v-if='data.label && (!data.textWidgets || (data.textWidgets && data.textWidgets.length === 0))'
      :class='[itemLabelPositionClasses, itemTextColor]'
    )
      p(class='font-bold') {{ data.label }}
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
interface ImageCarouselItem extends Widget {
  component: 'image-carousel-item'
  textPosition: VerticalStrings | 'OUTSIDE'
  shadowOpacity: string
  shadowTopPosition: string
  shadowBottomPosition: string
  link: string
  linkTarget: string
  textWidgets: Widget[]
  image: Widget[]
  label: string
}
const {
  data,
  labelPosition = 'BOTTOM',
  textColor = '',
  textPosition = 'INHERIT',
  shadowOpacity = '0',
  shadowTopPosition = '0',
  shadowBottomPosition = '100',
} = defineProps<{
  data: ImageCarouselItem
  labelPosition?: 'INSET' | 'BOTTOM'
  textColor?: string
  textPosition?: AlignmentStrings | 'OUTSIDE'
  shadowOpacity: string
  shadowTopPosition: string
  shadowBottomPosition: string
}>()

const itemEditable = computed(() => {
  return $storyblok.bindStoryblokBridgeData(data)
})

const itemLabelPositionClasses = computed(() => {
  switch (labelPosition) {
    case 'INSET':
      return 'absolute bottom-0 w-full p-4 bg-gradient-to-b from-transparent to-[#000000] text-white'

    case 'BOTTOM':
    default:
      return 'pt-2'
  }
})
const itemTextColor = computed(() => {
  if (textColor) return generateFontColorClass(textColor)
})

const textWidgetPosition = computed(() => {
  const position = data.textPosition === 'INHERIT' ? textPosition : data.textPosition

  if (position === 'OUTSIDE') return

  return ['absolute inset-0 flex flex-col', generateFlexAlignmentClass(position)]
})

const shadowSettings = computed(() => {
  const style = {}
  const opacity = data.shadowOpacity || shadowOpacity
  const topPosition = data.shadowTopPosition || shadowTopPosition
  const bottomPosition = data.shadowBottomPosition || shadowBottomPosition

  if (shadowOpacity) {
    style.opacity = Math.min(Math.max(opacity * 0.01, 0), 1)
  }

  // Clamp position value between 0 and 100
  if (shadowTopPosition) {
    style.top = `${Math.min(Math.max(topPosition, 0), 100)}%`
  }
  if (shadowBottomPosition) {
    const shadowPos = Math.min(Math.max(bottomPosition, 0), 100)
    style.bottom = `${100 - shadowPos}%`
  }

  // If we don't have any keys then we don't need to return anything.
  if (Object.keys(style).length === 0) return null

  return style
})
</script>
