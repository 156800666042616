export default function wireConstructorListeners() {
  const segment = useSegmentHelper()
  const { getFitment, isQuickship } = useSegmentProductsHelper()
  const { $uiEvents } = useNuxtApp()

  $uiEvents.$on('constructorProductListViewed', emitProductListViewed)
  $uiEvents.$on('constructorProductListFiltered', emitProductListFiltered)
  $uiEvents.$on('constructorProductListSorted', emitProductListSorted)
  $uiEvents.$on('constructorProductClicked', emitProductClicked)

  function emitProductListViewed({ results }: any) {
    segment.track('Product List Viewed', {
      products: results.map((result) => formatConstructorProduct(result)),
    })
  }

  function emitProductListFiltered({ activeFacets, results }: any) {
    segment.track('Product List Filtered', {
      filters: activeFacets.map((activeFacet) => ({
        type: activeFacet.facet,
        value: activeFacet.value,
      })),
      products: results.map((result) => formatConstructorProduct(result)),
    })
  }

  function emitProductListSorted({ displayName, sortBy, sortOrder }: any) {
    segment.track('Product List Sorted', {
      displayName,
      sortedBy: sortBy,
      sortOrder,
    })
  }

  function emitProductClicked({ product, position }: any) {
    segment.track('Product Clicked', {
      position,
      ...formatConstructorProduct(product),
    })
  }

  function formatConstructorProduct(product: any) {
    const isQuickshipable = isQuickship(product.data)
    const primaryFitment = getFitment()

    return {
      objectID: product.data.variation_id,
      product_id: product.data.productId,
      sku: product.data.skuSlug,
      category: getConstructorCategories(product.data.groups),
      name: product.data.productLineName,
      brand: product.data.brandSlug,
      price: product.data.salePrice / 100,
      quantity: 1,
      ...(isQuickshipable !== null && { quickship: isQuickshipable }),
      ...(primaryFitment && primaryFitment),
    }
  }

  // Example Groups payload
  // {
  //   "group_id": "exterior-accessories-tonneau-covers-folding-hard-folding",
  //   "display_name": "Hard Folding",
  //   "path": "/all/exterior-accessories/exterior-accessories-tonneau-covers/exterior-accessories-tonneau-covers-folding",
  //   "path_list": [
  //     {
  //       "id": "all",
  //       "display_name": "All"
  //     },
  //     {
  //       "id": "exterior-accessories",
  //       "display_name": "Exterior Accessories"
  //     },
  //     {
  //       "id": "exterior-accessories-tonneau-covers",
  //       "display_name": "Tonneau Covers"
  //     },
  //     {
  //       "id": "exterior-accessories-tonneau-covers-folding",
  //       "display_name": "Folding"
  //     }
  //   ]
  // }
  function getConstructorCategories(groups: any[]) {
    // Make sure we have groups, they could be turned off in the UI so i want to be safe here.
    if (!groups) return

    return groups.map((group: any) => {
      // first level is 'all' and we don't want that
      let groupId = group.group_id

      const [, ...groupLevels] = group.path_list

      groupLevels.reverse().forEach(({ id }) => {
        groupId = groupId.replace(`${id}-`, `${id}/`)
      })

      return groupId
    })
  }
}
