export default function () {
  const fitmentStore = useFitmentStore()

  function update(searchCtx: any, fitmentData: any) {
    const fitment = fitmentData ? fitmentData : fitmentStore.$state

    if (fitment.makeSlug) searchCtx.addFilters('makeSlug', [fitment.makeSlug, 'none'])
    if (fitment.modelSlug) searchCtx.addFilters('modelSlug', [fitment.modelSlug, 'none'])
    if (fitment.year) {
      searchCtx.addFilters('year', '0', '=')
      searchCtx.addFilters('year', fitment.year, '=')
    }
    if (fitment.bedSlug) searchCtx.addFilters('bedSlug', [fitment.bedSlug, 'none'])
    if (fitment.bodySlug) searchCtx.addFilters('bodySlug', [fitment.bodySlug, 'none'])
    if (fitment.engineSlug) searchCtx.addFilters('engineSlug', [fitment.engineSlug, 'none'])
  }

  return {
    update,
  }
}
