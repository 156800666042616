// Parse an asset URL to get the dimensions
// Example:
// https://realtruck.com/f/148322/x/b9e79b9e08/affirm-logo.svg
// https://realtruck.com/f/148322/288x150/63e93b27fe/plan-your-escape_stack-color.svg
export default (url: string): { width?: number; height?: number } => {
  const parts = url.split('/')
  if (parts[5] === 'x') return { width: undefined, height: undefined }

  const [width, height] = parts[5].split('x')

  return { width: parseInt(width), height: parseInt(height) }
}
