<template lang="pug">
Overlay(unique-key='cognitoLoginPopup' modal-width='SMALL' @closed='reset')
  template(#header)
    div(class='w-full flex justify-center items-center')
      div(class='title' data-testid='modalTitle') Account

  div
    template(v-if='!challenge.isActive')
      BaseForm(@submit='signIn')
        p(class='text-center mb-4') A {{ $sitewideConfig.config?.siteName }} account allows you to manage your orders and save products for later.

        div(class='mb-8')
          BaseFormInput(
            v-model='login.email'
            name='email'
            label='Email address'
            type='email'
            placeholder='Enter Email'
            autocomplete='email'
            validation='required|email'
            inputmode='text'
            data-testid='modalInput'
          )

          div(v-if='login.errorText' class='text-danger text-xs' data-testid='modalError')
            | {{ login.errorText }}

        BaseButton(class='w-full' :is-waiting='login.isLoading' data-testid='modalSignIn' type='submit') Sign In

    template(v-else)
      BaseForm(@submit='completeLogin')
        p(class='text-center mb-4') A code has been sent to your email. Please enter the code to access your account.

        div(class='mb-8')
          BaseFormInput(
            v-model='challenge.code'
            name='code'
            label='Code'
            type='text'
            placeholder='Enter code'
            validation='required|number'
            data-testd='modalCodeInput'
            inputmode='numeric'
          )

          div(v-if='challenge.errorText' class='text-danger text-xs' data-testid='modalCodeError')
            | {{ challenge.errorText }}

        BaseButton(data-testid='modalSubmit' class='w-full' :is-waiting='challenge.isLoading' type='submit') Submit
</template>

<script>
export default defineNuxtComponent({
  name: 'CognitoModal',
  data() {
    return {
      login: {
        email: '',
        errorText: '',
        isLoading: false,
      },
      challenge: {
        isActive: false,
        code: '',
        errorText: '',
        isLoading: false,
      },
    }
  },
  watch: {
    'login.email'() {
      this.login.errorText = ''
    },
    'challenge.code'() {
      this.challenge.errorText = ''
    },
  },
  methods: {
    reset() {
      this.login = {
        email: '',
        errorText: '',
        isLoading: false,
      }
      this.challenge = {
        isActive: false,
        code: '',
        errorText: '',
        isLoading: false,
      }
    },
    close() {
      // Make sure that if an api call is running, don't let the modal close
      if (this.login.isLoading || this.challenge.isLoading) return

      this.$cognito.closeModal()
    },
    async signIn() {
      // Double click protection
      if (this.login.isLoading) return

      // Attempt to login
      try {
        this.login.isLoading = true

        // Make sure email doesn't have whitespace
        const email = this.login.email.trim()

        const userId = await this.$cognito.login(email)
        // Event is used by Amplitude
        if (userId) this.$uiEvents.$emit('cognitoSignUp', userId)

        this.challenge.isActive = true
      } catch (error) {
        console.error(error)
        this.login.errorText = error.message
      } finally {
        this.login.isLoading = false
      }
    },
    async completeLogin() {
      if (this.challenge.isLoading) return

      // Check if email is valid
      if (!this.challenge.code) {
        this.challenge.errorText = 'Code is required'
        return
      }

      // Attempt to send challenge code
      try {
        this.challenge.isLoading = true

        // Make sure code doesn't have whitespace
        const code = this.challenge.code.trim()

        await this.$cognito.completeLogin(code)
        this.$uiEvents.$emit('cognitoSignIn')

        this.close()
      } catch (error) {
        console.log('Error', error)
        this.challenge.errorText = 'Invalid code'
      } finally {
        this.challenge.isLoading = false
      }
    },
  },
})
</script>
