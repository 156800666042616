export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const fitmentStore = useFitmentStore()

  const translateTemplate = (template: string, variables: Record<string, string> = {}) => {
    if (!template) return ''

    const tokens = variableExtractor(template)
    return variableReplacer(template, tokens, variables)
  }

  // Returns an array of tokens in string
  // Ex: [PHONE], [PRICE|bakflip-mx4-tonneau-cover]
  const variableExtractor = (template: string) => {
    // Earlyout if the template doesn't exist or if it doesn't contain any opening brackets
    if (!template?.includes('[')) return []

    return template.match(/\[[\sA-Za-z0-9_\-|]*\]/g) || []
  }

  // Replaces all the tokens in the template with the variables passed
  const variableReplacer = (template: string, tokens: string[], variables: Record<string, string>) => {
    // Earlyout if the template doesn't exist or if it doesn't contain any opening brackets
    if (!template?.includes('[')) return template

    const vars = {
      SITE: $sitewideConfig.config.siteName,
      DOMAIN: $sitewideConfig.domain,
      TITLE_SUFFIX: $sitewideConfig.seo?.content?.titleSuffix,
      PHONE: $sitewideConfig.config.phoneGeneral,
      FITMENT: fitmentStore.fitmentFormatted,
      ...(variables || {}),
    } as Record<string, string>

    return tokens.reduce((acc, token) => {
      const formattedToken = token.slice(1, -1)
      acc = acc.replace(token, vars[formattedToken] !== undefined ? vars[formattedToken] : token)

      return acc
    }, template)
  }

  return {
    translateTemplate,
    variableExtractor,
    variableReplacer,
  }
}
