<template lang="pug">
div
  slot
</template>

<script>
export default defineNuxtComponent({
  provide() {
    return {
      $parentId: this.parentId,
      $searchContext: this.index ? this.searchContext.indexes.find((i) => i.key === this.index) : this.searchContext,
    }
  },
  props: {
    searchContext: {
      type: Object,
      required: true,
    },
    index: {
      type: String,
      default: null,
    },
    parentId: {
      type: String,
      default: '',
    },
  },
})
</script>
