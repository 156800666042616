export default function wireExtendListeners() {
  const segment = useSegmentHelper()
  const { formatCartProduct } = useSegmentProductsHelper()
  const { $uiEvents } = useNuxtApp()

  $uiEvents.$on('warrantyOfferMade', (product) => {
    segment.track('Warranty: Offer Made', formatCartProduct(product))
  })

  $uiEvents.$on('warrantyDeclined', (product) => {
    segment.track('Warranty: Declined', formatCartProduct(product))
  })

  $uiEvents.$on('addWarranty', (product) => {
    segment.track('Warranty: Added', formatCartProduct(product))
  })

  $uiEvents.$on('removeWarranty', (product) => {
    segment.track('Warranty: Removed', formatCartProduct(product))
  })
}
