export const useCheckoutStore = defineStore('checkout', () => {
  const rootStore = useRootStore()
  const cartStore = useCartStore()
  const { $sitewideConfig, $uiEvents, $forter } = useNuxtApp()
  const utils = useUtils()
  const urls = useUrls()
  const checkoutUrl = urls.getApiUrl('checkout')
  const receipt = useReceipt()

  const headers = {
    'x-site': $sitewideConfig.sitePrefix,
  }

  const isProcessing = ref(false)

  const checkout = ref<Checkout>({
    id: '',
    summary: {
      itemCount: 0,
      cartSubtotal: 0,
      shippingSubtotal: 0,
      taxSubtotal: 0,
      giftCardSubtotal: 0,
      promoSubtotal: 0,
      warrantySubtotal: 0,
      installationSubtotal: 0,
      discountSubtotal: 0,
      grandTotal: 0,
    },
    giftCards: [],
    promoCodes: [],
  })

  async function getCheckout() {
    checkout.value = await $fetch(`${checkoutUrl}${rootStore.permId}`, {
      headers,
    })
  }

  async function addGiftCard(token: string) {
    checkout.value = await $fetch(`${checkoutUrl}${rootStore.permId}/giftcards`, {
      method: 'POST',
      headers,
      body: {
        number: token,
      },
    })
  }

  async function removeGiftCard(token: string) {
    checkout.value = await $fetch(`${checkoutUrl}${rootStore.permId}/giftcards/${token}`, {
      method: 'DELETE',
      headers,
    })
  }

  async function addPromoCode(promoCodeId: string) {
    try {
      const resp = await $fetch(`${checkoutUrl}${rootStore.permId}/coupons`, {
        method: 'POST',
        headers,
        body: {
          promoCodeId,
        },
      })

      if (resp.code === 'SUCCESS') {
        $uiEvents.$emit('promoCodeApplied', promoCodeId)
      }

      await Promise.all([getCheckout(), cartStore.getCart()])

      return resp
    } catch (error) {
      $uiEvents.$emit('promoCodeDenied', promoCodeId)
      throw error
    }
  }

  async function removePromoCode(promoCode: string) {
    const resp = await $fetch(`${checkoutUrl}${rootStore.permId}/coupons/${promoCode}`, {
      method: 'DELETE',
      headers,
    })

    await Promise.all([getCheckout(), cartStore.getCart()])

    $uiEvents.$emit('promoCodeRemoved', promoCode)

    return resp
  }

  async function setShipping(shippingContact: Contact) {
    await $fetch(`${checkoutUrl}${rootStore.permId}/shipping`, {
      method: 'POST',
      headers,
      body: shippingContact,
    })
  }

  async function setBilling(billingContact: Contact) {
    await $fetch(`${checkoutUrl}${rootStore.permId}/billing`, {
      method: 'POST',
      headers,
      body: billingContact,
    })
  }

  interface PlaceOrderRequest {
    type: 'STRIPE' | 'PAYPAL' | 'AFFIRM' | 'GIFTCARD'
    raw?: any
    token?: string
  }
  async function processPayment(paymentRequest: PlaceOrderRequest): Promise<string> {
    isProcessing.value = true

    const { type, ...payload } = paymentRequest

    try {
      const resp = await $fetch<{ orderId: string }>(`${checkoutUrl}${rootStore.permId}/${type}`, {
        method: 'POST',
        headers: {
          ...headers,
          ...(utils.isIntegrationEnabled('forter') && { 'x-forter-token': $forter.getToken() }),
        },
        body: payload,
      })

      // get the receipt for the event
      const receiptData = await receipt.getReceipt(resp.orderId)

      $uiEvents.$emit('orderPlaced', { type, receipt: receiptData })

      // After we checkout, it could be a paylink cart so we need to make sure that we set the permId back to the original
      // so we can have the fresh cart state.
      rootStore.setPermId(rootStore.originalPermId)

      // Reset the cart and checkout stores
      await Promise.all([getCheckout(), cartStore.getCart()])

      // Might return full response here. need to double check whats in it so i can make a type for it
      return resp.orderId
    } finally {
      isProcessing.value = false
    }
  }

  async function processPaymentFlow(paymentRequest: PlaceOrderRequest, shipping: Contact, billing: Contact) {
    isProcessing.value = true
    try {
      await setShipping(shipping)
      await setBilling(billing)
      await getCheckout()

      const orderId = await processPayment(paymentRequest)

      return orderId
    } finally {
      isProcessing.value = false
    }
  }

  return {
    checkout,
    isProcessing,
    getCheckout,
    addGiftCard,
    removeGiftCard,
    addPromoCode,
    removePromoCode,
    setShipping,
    setBilling,
    processPayment,
    processPaymentFlow,
  }
})

interface Checkout {
  id: string
  summary: {
    itemCount: number
    cartSubtotal: number
    shippingSubtotal: number
    taxSubtotal: number
    giftCardSubtotal: number
    promoSubtotal: number
    warrantySubtotal: number
    installationSubtotal: number
    discountSubtotal: number
    grandTotal: number
  }
  shippingContact?: Contact
  billingContact?: Contact
  giftCards: GiftCard[]
  promoCodes: PromoCode[]
}

interface GiftCard {
  id: string
  price: number
  name: string
  token: string
}

interface PromoCode {
  name: string
}
declare global {
  interface Contact {
    fullName?: string
    firstName?: string
    lastName?: string
    email: string
    address1: string
    address2?: string
    city: string
    stateId: string
    zipcode: string
    country: string
    phone: string
    company?: string
    subscribe?: boolean
  }
}
