export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1.0,maximum-scale=5,user-scalable=0"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"preconnect","href":"https://uwp.thiecommerce.com","crossorigin":"anonymous"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"title":""}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"trailingSlash":"append","componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false