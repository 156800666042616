<template lang="pug">
ClientOnly
  iframe(
    v-if='formId'
    :id='`JotFormIFrame-${formId}`'
    :key='formId'
    onload='window.parent.scrollTo(0,0)'
    allowtransparency='true'
    allowfullscreen='true'
    allow='geolocation; microphone; camera'
    :src='`https://form.jotform.com/${formId}`'
    frameborder='0'
    style='min-width: 100%; height: 539px; border: none'
    scrolling='no'
  )
</template>

<script setup lang="ts">
const { formId } = defineProps<{
  formId: string
}>()

onMounted(() => {
  init(formId)
})

function init(formId: string) {
  // Cast the HTMLElement to an Iframe so we have access to properties on iframe
  const ifr = document.getElementById('JotFormIFrame-' + formId) as HTMLIFrameElement
  if (window.location.href && window.location.href.includes('?')) {
    const get = window.location.href.substring(window.location.href.indexOf('?') + 1)
    if (ifr && get.length > 0) {
      let src = ifr.src
      src = src.includes('?') ? src + '&' + get : src + '?' + get
      ifr.src = src
    }
  }

  const handleIFrameMessage = function (e: any) {
    if (typeof e.data === 'object') {
      return
    }

    let iframe = null // had to add
    const args = e.data.split(':')

    // Cast the HTMLElement to an Iframe so we have access to properties on iframe
    if (args.length > 2) {
      iframe = document.getElementById('JotFormIFrame-' + args[args.length - 1]) as HTMLIFrameElement
    } else {
      iframe = document.getElementById('JotFormIFrame') as HTMLIFrameElement
    }

    if (!iframe) {
      return
    }

    switch (args[0]) {
      case 'scrollIntoView':
        iframe.scrollIntoView()
        break
      case 'setHeight':
        iframe.style.height = args[1] + 'px'
        break
      case 'collapseErrorPage':
        if (iframe.clientHeight > window.innerHeight) {
          iframe.style.height = window.innerHeight + 'px'
        }
        break
      case 'reloadPage':
        window.location.reload()
        break
      case 'loadScript': {
        let src = args[1]
        if (args.length > 3) {
          src = args[1] + ':' + args[2]
        }
        const script = document.createElement('script')
        script.src = src
        script.type = 'text/javascript'
        document.body.appendChild(script)
        break
      }
      case 'exitFullscreen':
        if (window.document.exitFullscreen) window.document.exitFullscreen()
        else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen()
        else if (window.document.mozCancelFullscreen) window.document.mozCancelFullScreen()
        else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen()
        else if (window.document.msExitFullscreen) window.document.msExitFullscreen()
        break
    }

    const isJotForm = !!e.origin.includes('jotform')
    if (isJotForm && 'contentWindow' in iframe && iframe.contentWindow && 'postMessage' in iframe.contentWindow) {
      const urls = {
        docurl: encodeURIComponent(document.URL),
        referrer: encodeURIComponent(document.referrer),
      }
      iframe.contentWindow.postMessage(JSON.stringify({ type: 'urls', value: urls }), '*')
    }
  }

  if (window.addEventListener) {
    window.addEventListener('message', handleIFrameMessage, false)
  } else if (window.attachEvent) {
    window.attachEvent('onmessage', handleIFrameMessage)
  }
}

// We need to expand the Document and Window types to include properties to shut TypeScript up
declare global {
  interface Document {
    mozCancelFullScreen: any
    mozCancelFullscreen: any
    webkitExitFullscreen: any
    msExitFullscreen: any
  }

  interface Window {
    attachEvent: any
  }
}
</script>
