<template lang="pug">
HeaderNavbarMobileSlideMenu(:text='data.text' v-bind='$storyblok.bindStoryblokBridgeData(data)')
  template(v-if='data.items')
    div(data-testid='treeMenu' class='px-4 pt-4 space-y-4')
      template(v-if='data.link')
        UniversalLink(
          data-testid='treeMenuAllLink'
          :link='data.link.url'
          :data-root-text='rootText'
          :data-level='level'
          class='block text-black'
        ) All {{ data.text }}
      template(v-for='item in getItems' :key='item._uid')
        template(v-if='$storyblok.isVisible(item)')
          //- If we have nested items then render the menu again with the childs data
          template(v-if='item.component === "tree-menu-item" && item.items')
            HeaderNavbarMobileTreeMenu(:data='item' :root-text='rootText' :level='level + 1')
          template(v-else-if='item.component === "text-menu-item"')
            HeaderNavbarMobileTextLink(:data='item' :root-text='rootText' :level='level')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

interface MobileTreeMenu extends TreeMenuItem {
  noSideMenu: boolean
  items: MobileTreeMenu[]
}

const props = withDefaults(
  defineProps<{
    data: MobileTreeMenu
    rootText?: string
    level?: number
  }>(),
  {
    rootText: '',
    level: 0,
  }
)

const getItems = computed(() => {
  if (props.data.noSideMenu) {
    // Ditch the first level of items in order to skip a slide.
    return props.data.items.flatMap((item) => item.items)
  }

  return props.data.items
})
</script>
