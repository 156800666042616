<template lang="pug">
div(v-if='isIntegrationEnabled("paypal")' :class='{ relative: $storyblok.isEditing.value }')
  PaypalMarketingMessage(:type='data.type' :alignment='data.alignment')

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()

interface PayPalMessageWidget extends Widget {
  type: 'PAY_LATER_SHORT_TERM' | 'PAY_LATER_LONG_TERM'
  alignment: 'left' | 'center' | 'right'
}

const { data } = defineProps<{
  data: PayPalMessageWidget
}>()
</script>
